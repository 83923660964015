// Dashboard.tsx
import * as React from 'react';
import Box from '@mui/joy/Box';
import ColorSchemeToggle from "../../components/ColorSchemeToggle";
import Block01 from "./blocks/Block01";
import Block02 from "./blocks/Block02";
import {useAuth} from "../../context/GlobalContext";
import LangSelectionModal from "../../components/Lang/LangSelectionModal";


const Home = () => {
    const { globalSettings } = useAuth();

    return (
        <>
            <Box
                sx={{
                    display: 'flex', // Enable flex layout
                    flexDirection: 'column', // Arrange children vertically
                    alignItems: 'flex-end', // Align children to the right
                    position: 'fixed',
                    zIndex: 999,
                    top: '1rem',
                    right: '1rem',
                    gap: '0.5rem', // Optional: Add space between items
                }}
            >
                <Box>
                    <ColorSchemeToggle />
                </Box>
                {globalSettings?.localization && (
                    <Box>
                    <LangSelectionModal triggerType="iconbtn"/>
                    </Box>
                )}
            </Box>
            <Box
                sx={{
                    height: '100vh',
                    overflowY: 'scroll',
                    scrollSnapType: 'y mandatory',
                    '& > div': {
                        scrollSnapAlign: 'start',
                    },
                }}
            >

                <Block01/>
                <Block02/>
                <Block02/>
                <Block02/>
            </Box>
        </>
    );
};

export default Home;
