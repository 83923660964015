import React from 'react';
import Snackbar from '@mui/joy/Snackbar';
import IconButton from '@mui/joy/IconButton';
import CloseIcon from '@mui/icons-material/Close';

interface CustomSnackbarProps {
    open: boolean;
    message: React.ReactNode;
    handleClose: () => void;
    duration?: number;
    variant?: 'soft' | 'outlined' | 'solid';
    color?: 'success' | 'warning' | 'primary' | 'neutral' | 'danger';
}

const CustomSnackbar: React.FC<CustomSnackbarProps> = ({
       open,
       message,
       handleClose,
       duration = 5000,
       variant = 'soft',
       color = 'success'
   }) => {
    return (
        <Snackbar
            autoHideDuration={duration}
            open={open}
            variant={variant}
            color={color}
            onClose={handleClose}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            endDecorator={
                <IconButton onClick={handleClose} size="sm" variant="soft" color={color}>
                    <CloseIcon />
                </IconButton>
            }
        >
            {message}
        </Snackbar>
    );
};

export default CustomSnackbar;