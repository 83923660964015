/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from 'react';
import Button from '@mui/joy/Button';
import Typography from '@mui/joy/Typography';
import ArrowForward from '@mui/icons-material/ArrowForward';
import TwoSidedLayout from "../compontnts/TwoSidedLayout";
import {NavLink} from "react-router-dom";
import {Trans} from "@lingui/macro";

export default function Block01() {
    return (
        <TwoSidedLayout>
            <Typography color="primary" fontSize="lg" fontWeight="lg">
               <Trans id="home.block01.prTitle"> The power to do more</Trans>
            </Typography>
            <Typography
                level="h1"
                fontWeight="xl"
                fontSize="clamp(1.875rem, 1.3636rem + 2.1818vw, 3rem)"
            >
                <Trans id="home.block01.title">A large headlinerer about our product features & services</Trans>
            </Typography>
            <Typography fontSize="lg" textColor="text.secondary" lineHeight="lg">
                <Trans id="home.block01.content">A descriptive secondary text placeholder. Use it to explain your business
                    offer better.</Trans>
            </Typography>
            <Button size="lg" endDecorator={<ArrowForward />}>
                <Trans id="home.block01.bntGetStarted">Get Started</Trans>
            </Button>
            <Typography>
                <Trans id="home.block01.linkSingIn">Already a member? <NavLink to="/singin">Sign in</NavLink></Trans>
            </Typography>
            <Typography
                level="body-xs"
                sx={{
                    position: 'absolute',
                    top: '2rem',
                    left: '50%',
                    transform: 'translateX(-50%)',
                }}
            >
                <Trans id="home.block01.heroLeft00">HeroLeft00</Trans>
            </Typography>
        </TwoSidedLayout>
    );
}