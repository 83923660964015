// SingIn.tsx
import React,{useState,useEffect} from 'react';
import {useAuth} from "../../context/GlobalContext";
import {NavLink, useNavigate,useSearchParams} from 'react-router-dom';


import Box from '@mui/joy/Box';
import CompanyLogoBlock from "../../components/UI/CompanyLogoBlock";
import CopyrightBlock from "../../components/UI/CopyrightBlock";

import GlobalStyles from '@mui/joy/GlobalStyles';


import Button from '@mui/joy/Button';
import Checkbox from '@mui/joy/Checkbox';

import FormControl from '@mui/joy/FormControl';
import FormLabel, { formLabelClasses } from '@mui/joy/FormLabel';

import FormHelperText from '@mui/joy/FormHelperText';
import Link from '@mui/joy/Link';
import Input from '@mui/joy/Input';

import Stack from '@mui/joy/Stack';

import ColorSchemeToggle from '../../components/ColorSchemeToggle';
import InfoOutlined from '@mui/icons-material/InfoOutlined';
import Typography from "@mui/joy/Typography";
import PasswordIcon from "@mui/icons-material/Password";
import IconButton from "@mui/joy/IconButton";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from '@mui/icons-material/Visibility';
import LoadingSpinner from "../../components/UI/LoadingSpinner";

import {Trans} from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { msg } from "@lingui/macro";

interface FormElements extends HTMLFormControlsCollection {
    email: HTMLInputElement;
}
interface SignInFormElement extends HTMLFormElement {
    readonly elements: FormElements;
}


const PasswordRecoveryConfirmPage = () => {
    const { _ } = useLingui();
    type FormErrorKeys = keyof typeof formErrors;
    const {user, login, globalSettings } = useAuth();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] =useState(false);
    const [isGettingInfo, setGettingInfo] =useState(true);
    const [statusConfirm, setStatusConfirm] = useState(0);
    const [searchParams] = useSearchParams();
    const [code, setCode] = useState('');
    const [isPasswordVisible, setIsPasswordVisible] = useState(false);

    const [userInfo, setUserInfo] = useState({ email: '', login: '' });
    const [formData, setFormData] = useState({
        password: '',
        confirmPassword: ''
    });

    const [formErrors, setFormErrors] = useState({
        password: false,
        confirmPassword: false,
        general:false,
    });

    const [errorMessages, setErrorMessages] = useState({
        password: '',
        confirmPassword: '',
        general:''
    });

    const togglePasswordVisibility = () => {
        setIsPasswordVisible(!isPasswordVisible);
    };

    const getPRCodeInfo = async () => {
        try {
            setGettingInfo(true);
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/auth/forgot-password-info?code=${code}`);
            if (!response.ok) {
                throw new Error('Failed to fetch user info');
            }
            const data = await response.json();
            setUserInfo({ email: data.email, login: data.login });
            setGettingInfo(false);
            setStatusConfirm(1)
        } catch (error) {
            console.error('Error:', error);
            setGettingInfo(false);
        }
    };

    useEffect(() => {
        if (code) {
            getPRCodeInfo();
        }
    }, [code]);

    useEffect(() => {
        // Если пользователь уже авторизован, перенаправляем на /dashboard
        if (user) {
            navigate('/dashboard');
        }

    }, [user, navigate]);

    useEffect(() => {
        // Получаем параметр 'code' из URL
        const codeParam = searchParams.get('code');
        if (codeParam) {
            setCode(codeParam);
        }
    }, [searchParams]);

    const validatePassword = () => {
        // Проверка на длину, наличие цифры и спецсимвола
        const passwordRegex = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-zA-Z]).{5,}$/;
        const isPasswordValid = passwordRegex.test(formData.password);

        if (!isPasswordValid) {
            setFormErrors({ ...formErrors, password: true });
            setErrorMessages({ ...errorMessages, password: _(msg({ id: "passRecConfPage.passwMustBeAtLeastCh", message: "Password must be at least 5 characters long and include at least one digit and one special character" })) });
            return false;
        }

        // Проверка на совпадение пароля и подтверждения пароля
        const isConfirmPasswordValid = formData.password === formData.confirmPassword;
        if (!isConfirmPasswordValid) {
            setFormErrors({ ...formErrors, confirmPassword: true });
            setErrorMessages({ ...errorMessages, confirmPassword: _(msg({ id: "passRecConfPage.passwAndConfiPasswDo", message: "Password and confirm password do not match" })) });
            return false;
        }

        // Если обе проверки пройдены
        return true;
    };

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const name = event.target.name as FormErrorKeys; // Type assertion
        let value = event.target.value;


        setFormData({ ...formData, [name]: value });

        if (formErrors[name]) {
            setFormErrors({ ...formErrors, [name]: false });
            setErrorMessages({ ...errorMessages, [name]: '' });
        }
    };

    const setNewPassword = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/auth/forgot-password-confirm`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    code,
                    password: formData.password
                })
            });

            if (!response.ok) {
                throw new Error('Failed to set new password');
            }

            setStatusConfirm(2); // Успешное обновление пароля
        } catch (error) {
            console.error('Error:', error);
            setStatusConfirm(3); // Ошибка при обновлении пароля
            setFormErrors({ ...formErrors, general: true });
            setErrorMessages({ ...errorMessages, general: _(msg({ id: "passRecConfPage.anErrorOccurDurinPas", message: "An error occurred during password recovery" })) });

        }
    };

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setIsLoading(true);
        setFormErrors({ password: false, confirmPassword: false, general:false}); // Очищаем ошибки при новой попытке отправки

        let resultCheck = validatePassword();

        if (resultCheck) {
            await setNewPassword(); // Вызов функции для установки нового пароля
        }

        setIsLoading(false);
    };

    if (user) {
        return null
    }

    if (isGettingInfo) {
        return (<LoadingSpinner/>)
    }

    return (
        <>
            <GlobalStyles
                styles={{
                    ':root': {
                        '--Collapsed-breakpoint': '769px', // form will stretch when viewport is below `769px`
                        '--Cover-width': '50vw', // must be `vw` only
                        '--Form-maxWidth': '800px',
                        '--Transition-duration': '0.4s', // set to `none` to disable transition
                    },
                }}
            />
            <Box
                sx={(theme) => ({
                    width:
                        'clamp(100vw - var(--Cover-width), (var(--Collapsed-breakpoint) - 100vw) * 999, 100vw)',
                    transition: 'width var(--Transition-duration)',
                    transitionDelay: 'calc(var(--Transition-duration) + 0.1s)',
                    position: 'relative',
                    zIndex: 1,
                    display: 'flex',
                    justifyContent: 'flex-end',
                    backdropFilter: 'blur(12px)',
                    backgroundColor: 'rgba(255 255 255 / 0.2)',
                    [theme.getColorSchemeSelector('dark')]: {
                        backgroundColor: 'rgba(19 19 24 / 0.4)',
                    },
                })}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        minHeight: '100dvh',
                        width:
                            'clamp(var(--Form-maxWidth), (var(--Collapsed-breakpoint) - 100vw) * 999, 100%)',
                        maxWidth: '100%',
                        px: 2,
                    }}
                >
                    <Box
                        component="header"
                        sx={{
                            py: 3,
                            display: 'flex',
                            alignItems: 'left',
                            justifyContent: 'space-between',
                        }}
                    >
                        <div style={{ gap: 2, display: 'flex', alignItems: 'center' }}>
                            <CompanyLogoBlock />
                        </div>
                        <ColorSchemeToggle />
                    </Box>
                    <Box
                        component="main"
                        sx={{
                            my: 'auto',
                            py: 2,
                            pb: 5,
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 2,
                            width: 400,
                            maxWidth: '100%',
                            mx: 'auto',
                            borderRadius: 'sm',
                            '& form': {
                                display: 'flex',
                                flexDirection: 'column',
                                gap: 2,
                            },
                            [`& .${formLabelClasses.asterisk}`]: {
                                visibility: 'hidden',
                            },
                        }}
                    >
                        <Stack
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                height: '100%', // или конкретная высота, если вам нужно
                            }}
                        >
                            {statusConfirm === 1 && (
                            <Typography level="h2"><Trans id="passRecConfPage.newPassw">New password</Trans></Typography>
                                )}
                        </Stack>
                        {statusConfirm === 0 && (
                            <Stack gap={4} sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center', // Выравнивание по горизонтали
                                justifyContent: 'center', // Выравнивание по вертикали
                                mt:2,
                            }}>
                                {/* Display error message */}
                                <Typography color="danger" level="h4"><Trans id="passRecConfPage.error">Error</Trans></Typography>
                                <Typography
                                    color="danger"
                                    level="body-lg"
                                    sx={{
                                        textAlign: 'center',
                                        whiteSpace: 'normal',
                                    }}
                                >
                                    <Trans id="passRecConfPage.incorConfiCodeOrTheC">Incorrect confirmation code or the code has expired</Trans>
                                </Typography>
                                <Button
                                    component={NavLink}
                                    to="/singin"
                                    sx={{
                                        mt:2
                                    }}
                                >
                                    <Trans id="passRecConfPage.singIn">SING IN</Trans>
                                </Button>
                            </Stack>
                        )}
                        {statusConfirm === 1 && (
                            <Stack gap={4} sx={{ mt: 2 }}>
                                <FormControl>
                                    <Typography level="title-sm">
                                        <Trans id="passRecConfPage.email">Email</Trans>:{' '}
                                        <Typography
                                            level="title-sm"
                                            textColor="var(--joy-palette-success-plainColor)"
                                            fontFamily="monospace"
                                            sx={{ opacity: '50%' }}
                                        >
                                            {userInfo.email}
                                        </Typography>
                                    </Typography>
                                    <Typography level="title-sm">
                                        <Trans id="passRecConfPage.login">Login</Trans>:{' '}
                                        <Typography
                                            level="title-sm"
                                            textColor="var(--joy-palette-success-plainColor)"
                                            fontFamily="monospace"
                                            sx={{ opacity: '50%' }}
                                        >
                                            {userInfo.login}
                                        </Typography>
                                    </Typography>
                                </FormControl>
                                <form onSubmit={handleSubmit} >
                                    <FormControl error={formErrors.password}>
                                        <FormLabel><Trans id="passRecConfPage.passw">Password</Trans></FormLabel>
                                        <Input
                                            name="password"
                                            autoComplete="new-password"
                                            startDecorator={<PasswordIcon />}
                                            endDecorator={
                                                <IconButton onClick={togglePasswordVisibility}>
                                                    {isPasswordVisible ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                                </IconButton>
                                            }
                                            type={isPasswordVisible ? 'text' : 'password'}
                                            value={formData.password}
                                            onChange={handleInputChange}
                                        />
                                        {formErrors.password && (
                                            <FormHelperText>
                                                <InfoOutlined sx={{mr:1}}/>
                                                {formErrors.password && errorMessages.password}
                                            </FormHelperText>
                                        )}
                                    </FormControl>
                                    <FormControl error={formErrors.confirmPassword}>
                                        <FormLabel><Trans id="passRecConfPage.confiPassw">Confirm password</Trans></FormLabel>
                                        <Input
                                            name="confirmPassword"
                                            autoComplete="new-password"
                                            startDecorator={<PasswordIcon />}
                                            type={isPasswordVisible ? 'text' : 'password'}
                                            value={formData.confirmPassword}
                                            onChange={handleInputChange}
                                        />
                                        {formErrors.confirmPassword && (
                                            <FormHelperText>
                                                <InfoOutlined sx={{mr:1}}/>
                                                {formErrors.confirmPassword && errorMessages.confirmPassword}
                                            </FormHelperText>
                                        )}
                                    </FormControl>

                                    <Stack gap={4} sx={{ mt: 2 }}>
                                        <FormControl required disabled={isLoading} error={!!formErrors.general}>
                                            <Button loading={isLoading} type="submit" fullWidth><Trans id="passRecConfPage.signIn">Sign in</Trans></Button>
                                            {formErrors.general && <FormHelperText><InfoOutlined /> {formErrors.general}</FormHelperText>}
                                        </FormControl>
                                    </Stack>
                                </form>
                            </Stack>
                        )}
                        {statusConfirm === 2 && (
                            <Stack gap={4} sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center', // Выравнивание по горизонтали
                                justifyContent: 'center', // Выравнивание по вертикали
                                mt:2,
                            }}>
                                {/* Display error message */}
                                <Typography color="success" level="h4"><Trans id="passRecConfPage.succe">Success</Trans></Typography>
                                <Typography
                                    color="success"
                                    level="body-lg"
                                    sx={{
                                        textAlign: 'center',
                                        whiteSpace: 'normal',
                                    }}
                                >
                                    <Trans id="passRecConfPage.congrYourPasswHasBee">Congratulations. Your password has been changed. You can now sing in.</Trans>
                                </Typography>
                                <Button
                                    component={NavLink}
                                    to="/singin"
                                    sx={{
                                        mt:2
                                    }}
                                >
                                    <Trans id="passRecConfPage.singIn">SING IN</Trans>
                                </Button>

                            </Stack>
                        )}

                        {statusConfirm === 3 && (
                            <Stack gap={4} sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center', // Выравнивание по горизонтали
                                justifyContent: 'center', // Выравнивание по вертикали
                                mt:2,
                            }}>
                                {/* Display error message */}
                                <Typography color="danger" level="h4"><Trans id="passRecConfPage.error">Error</Trans></Typography>
                                <Typography
                                    color="danger"
                                    level="body-lg"
                                    sx={{
                                        textAlign: 'center',
                                        whiteSpace: 'normal',
                                    }}
                                >
                                    {formErrors.general}
                                </Typography>
                                <Button
                                    component={NavLink}
                                    to="/singin"
                                    sx={{
                                        mt:2
                                    }}
                                >
                                    <Trans id="passRecConfPage.singIn">SING IN</Trans>
                                </Button>
                            </Stack>
                        )}



                        {globalSettings && globalSettings.user_registration && (
                            <Stack>
                                <Box>
                                    <Typography level="title-sm">
                                        Don't have an account?{' '}
                                        <Link component={NavLink} to="/singup">
                                            <Trans id="passRecConfPage.signUp">Sign Up</Trans>
                                        </Link>
                                    </Typography>
                                </Box>
                            </Stack>
                        )}
                    </Box>
                    <Box component="footer" sx={{ py: 3 }}>
                        <CopyrightBlock />
                    </Box>
                </Box>
            </Box>
            <Box
                sx={(theme) => ({
                    height: '100%',
                    position: 'fixed',
                    right: 0,
                    top: 0,
                    bottom: 0,
                    left: 'clamp(0px, (100vw - var(--Collapsed-breakpoint)) * 999, 100vw - var(--Cover-width))',
                    transition:
                        'background-image var(--Transition-duration), left var(--Transition-duration) !important',
                    transitionDelay: 'calc(var(--Transition-duration) + 0.1s)',
                    backgroundColor: 'background.level1',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    backgroundImage:
                        'url(imgs/lightwpbc.png)',
                    [theme.getColorSchemeSelector('dark')]: {
                        backgroundImage:
                            'url(imgs/darkbcwp.png)',
                    },
                })}
            />
        </>
    );
};

export default PasswordRecoveryConfirmPage;
