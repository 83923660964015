// AccountActivationPage.tsx
import React,{useEffect,useRef,useState} from 'react';
import Box from '@mui/joy/Box';
import Card from "@mui/joy/Card";
import Typography from "@mui/joy/Typography";
import {Trans} from "@lingui/macro";
import Divider from "@mui/joy/Divider";
import Stack from "@mui/joy/Stack";
import AspectRatio from "@mui/joy/AspectRatio";
import IconButton from "@mui/joy/IconButton";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import FormLabel from "@mui/joy/FormLabel";
import FormControl from "@mui/joy/FormControl";
import Input from "@mui/joy/Input";
import EmailRoundedIcon from "@mui/icons-material/EmailRounded";
import {getRoleDisplayName} from "../../components/UI/getRoleDisplayName";
import {i18n} from "@lingui/core/dist/index";
import CardOverflow from "@mui/joy/CardOverflow";
import CardActions from "@mui/joy/CardActions";
import Button from "@mui/joy/Button";

import {LinearProgress} from "@mui/joy";
import {useAuth} from "../../context/GlobalContext";
import CircularProgress from "@mui/joy/CircularProgress";
import {NavLink} from "react-router-dom";


const AccountActivationPage = () => {
    const { activateUser } = useAuth();

    const codeLength = 6
    const [code, setCode] = useState(new Array(codeLength).fill(''));
    const [isLoading, setIsLoading] = useState(false)

    const inputRefs = useRef<(HTMLInputElement | null)[]>(new Array(codeLength).fill(null));
    const [isFinish, setIsFinish] = useState(false)
    const [isError,setIsError] = useState(false)
    const [isErrorMessage,setIsErrorMessage] = useState('')
    const [isSuccess,setIsSuccess] = useState(false)
    const [countDown, setCountDown] = useState(5)
    const [circularProgress,setCircularProgress] = useState(0)

    const [countDownReSend, setCountDownReSend] = useState<number | null>(null);

    const cleanError = () => {
        setIsError(false);
        setIsErrorMessage('');
        setIsSuccess(false);
    };


    const focusInput = (index: number) => {
        const input = inputRefs.current[index];
        input?.focus();
    };

    const focusNextInput = (index: number) => {
        if (index < codeLength - 1) {
            const nextInput = document.querySelector<HTMLInputElement>(`input[name=code-${index + 1}]`);
            nextInput?.focus();
        }else {
            // Если следующего элемента нет (мы на последнем Input), убираем фокус
            const currentInput = document.querySelector<HTMLInputElement>(`input[name=code-${index}]`);
            currentInput?.blur();
            //activate()
            setIsFinish(true)
        }
    };

    // Обработчик изменения текста в каждом поле
    const handleChange = (index: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        cleanError()
        // Разрешаем только цифры и ограничиваем длину значения до 1 символа
        if (value === '' || (/^\d$/.test(value) && value.length === 1)) {
            const newCode = [...code];
            newCode[index] = value;
            setCode(newCode);
            if (value !== '') {
                focusNextInput(index);
                if (index < codeLength - 1) {
                    newCode[index+1] = '';
                    setCode(newCode);
                }
            }

        }
    };

    const handlePaste = (event: React.ClipboardEvent<HTMLInputElement>) => {
        event.preventDefault();
        cleanError()
        const pastedData = event.clipboardData.getData('text').slice(0, codeLength);
        if (/^\d+$/.test(pastedData)) {
            const newCode = pastedData.split('').concat(new Array(codeLength - pastedData.length).fill(''));
            setCode(newCode);
            const lastInputIndex = Math.min(pastedData.length, codeLength - 1);
            focusInput(lastInputIndex); // Фокус на последний вставленный инпут или последний инпут, если вставлено больше символов

            if (lastInputIndex === codeLength - 1) {
                // Если это последний Input, выводим "FINISH" и убираем фокус
                setTimeout(() => {
                    inputRefs.current[lastInputIndex]?.blur();
                }, 0); // Используем setTimeout для убирания фокуса после завершения текущего цикла событий
                //activate()
                setIsFinish(true)
            }
        }
    };

    const handleCancel = () => {
        cleanError();
        setCode(new Array(codeLength).fill('')); // Очищаем code
        focusInput(0); // Устанавливаем фокус на первый Input
    };

    const activate = async () => {
        setIsLoading(true);
        const fullCode = code.join(''); // Объединяем элементы массива code в одну строку

        try {
            const token = localStorage.getItem('authToken'); // Получаем токен из localStorage

            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/registration/email-confirmation`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({ code: fullCode })
            });

            const data = await response.json(); // Получаем и парсим JSON ответа

            if (response.status === 200) {
                setIsSuccess(true)
            } else if (response.status === 403) {
                setIsError(true);
            } else if (response.status === 500) {
                setIsError(true);
                setIsErrorMessage(data.message)
            }
        } catch (error) {
            const errorObject = error as Error;
            setIsError(true);
            setIsErrorMessage(errorObject.message);
        } finally {
            setIsLoading(false); // Снимаем индикацию загрузки в любом случае

        }
    };

    useEffect(() => {
        const fullCode = code.join('');
        if (fullCode.length === codeLength && isFinish) {
            setIsFinish(false);
            activate();
        }
    }, [code]);

    useEffect(() => {
        if (isSuccess) {
            let startTime: number | null = null; // Объявляем startTime как number или null
            const duration = 5000; // Общее время анимации - 5 секунд
            let frame: number | null = null; // Объявляем frame как number или null

            const animateProgress = (timestamp: number) => { // Указываем тип параметра timestamp
                if (!startTime) startTime = timestamp; // Начальное время
                const timeElapsed = timestamp - startTime; // Время, прошедшее с начала анимации
                const progress = Math.min(timeElapsed / duration, 1); // Прогресс от 0 до 1

                setCircularProgress(progress * 100); // Устанавливаем прогресс от 0 до 100
                setCountDown(5 - Math.floor(timeElapsed / 1000)); // Уменьшаем счетчик

                if (timeElapsed < duration) {
                    frame = requestAnimationFrame(animateProgress); // Продолжаем анимацию
                } else {
                    activateUser(); // Завершаем анимацию и активируем пользователя
                }
            };

            frame = requestAnimationFrame(animateProgress);

            return () => {
                if (frame !== null) {
                    cancelAnimationFrame(frame); // Очистка при размонтировании компонента
                }
            };
        }
    }, [isSuccess]);



    const goActivate = () => {
        activateUser();
    }


    const checkActivationCodeTime = async () => {
        //setIsLoading(true); // Начинаем показывать индикатор загрузки
        try {
            const token = localStorage.getItem('authToken'); // Получаем токен из localStorage

            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/registration/get-last-query-email-confirmation`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });

            const data = await response.json(); // Получаем и парсим JSON ответа

            if (response.status === 200) {
                // Обновляем состояние счетчика обратного отсчета
                //setCountDown(data.secondsRemaining);
                setCountDownReSend(data.secondsRemaining);
                // Здесь можно также обновить состояние прогресса, если необходимо
                // setCircularProgress(...);
                console.log("data.secondsRemaining",data.secondsRemaining)
            } else {
                console.error('Error retrieving activation code time:', data.message);
            }
        } catch (error) {
            console.error('Error retrieving activation code time:', error);
        } finally {
            //setIsLoading(false); // Заканчиваем показывать индикатор загрузки
        }
    };

    useEffect(() => {
        checkActivationCodeTime(); // Вызываем при загрузке страницы
    }, []);

    useEffect(() => {
        let intervalId: NodeJS.Timeout | null = null; // Явно указываем тип для intervalId

        // Функция для уменьшения countDownReSend
        const decreaseCountDown = () => {
            setCountDownReSend((prevCountDown) => {
                if (prevCountDown && prevCountDown > 0) {
                    return prevCountDown - 1;
                } else {
                    // Когда достигнем 0, очищаем интервал
                    if (intervalId) clearInterval(intervalId);
                    return 0;
                }
            });
        };

        if (countDownReSend && countDownReSend > 0) {
            // Запускаем интервал только если countDownReSend больше 0
            intervalId = setInterval(decreaseCountDown, 1000);
        }

        // Очистка интервала при размонтировании компонента или когда countDownReSend достигнет 0
        return () => {
            if (intervalId) clearInterval(intervalId);
        };
    }, [countDownReSend]);

    const [isResendLoading,setIsResendLoading] = useState(false)
    const handleResendActivationCode = async () => {
        setIsLoading(true); // Включаем индикацию загрузки
        setIsResendLoading(true)
        try {
            const token = localStorage.getItem('authToken'); // Получаем токен из localStorage
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/registration/resend-activation-code`, {
                method: 'POST', // Важно: метод должен быть POST
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });

            const data = await response.json(); // Получаем и парсим JSON ответа

            if (response.status === 200) {
                console.log("Activation code resent successfully");
                setCountDownReSend(data.delay)
                // Обновите состояние, если необходимо, например, сбросить таймер повторной отправки
            } else {
                // Обработка ошибок, если статус ответа не 200
                console.error(data.message);
            }
        } catch (error) {
            const errorObject = error as Error;
            console.error("Error resending activation code:", errorObject.message);
        } finally {
            setIsLoading(false); // Выключаем индикацию загрузки
            setIsResendLoading(false)
        }
    };


    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                maxWidth: 800,
                margin: 'auto',
                gap: 2,
            }}
        >

                <Card
                    sx={{
                        minWidth: 300,
                    }}
                >
                    <Box sx={{ mb: 1 }}>
                        <Typography level="title-md">
                            {isSuccess ? (
                                    <>Account Activated</>
                                ):(
                                    <>Account Not Activated</>
                            )}
                        </Typography>
                        {!isSuccess && (
                                <Typography level="body-sm">
                                    Account inactive. Check your email for the activation link or request assistance
                                </Typography>
                            )}
                    </Box>
                    <Divider />

                    {isSuccess ? (
                        <>
                            <Box sx={{ display: 'flex', gap: 1,justifyContent: 'center' }}>
                                <Typography color="success" level="h4">Hooray</Typography>
                            </Box>
                            <Box sx={{ display: 'flex', gap: 1,justifyContent: 'center' }}>
                                <Typography color="success">Your account has been successfully activated. You can now use all the features</Typography>
                            </Box>
                            <Box sx={{ display: 'flex', gap: 1,justifyContent: 'center' }}>
                                <CircularProgress
                                    color="success"
                                    size="lg"
                                    determinate value={circularProgress}
                                    thickness={1}
                                >
                                    {countDown}
                                </CircularProgress>
                            </Box>

                        </>
                    ) : (
                        <>
                            {isError && (
                                <Box sx={{ display: 'flex', gap: 1,justifyContent: 'center' }}>
                                    <Typography color="danger" level="h4">ERROR</Typography>
                                </Box>
                            )}
                            <Stack
                                direction="row"
                                spacing={3}
                                sx={{
                                    justifyContent: 'center', // Центрируем Stack по горизонтали
                                    my: 1,
                                    width: '100%', // Stack занимает всю ширину контейнера Card
                                }}
                            >

                                <Box sx={{ display: 'flex', gap: 1 }}>
                                    {code.map((value, index) => (
                                        <Input
                                            key={index}
                                            error={isError}
                                            size="lg"
                                            name={`code-${index}`}
                                            type="tel"
                                            sx={{
                                                width: '2.5em',
                                                textAlign: 'center',
                                            }}
                                            disabled={isLoading}
                                            slotProps={{
                                                input: {
                                                    maxLength:1,
                                                    ref: (el: HTMLInputElement | null) => {
                                                        inputRefs.current[index] = el; // Сохраняем ссылку на каждый Input
                                                    }
                                                }
                                            }}
                                            value={value}
                                            onChange={handleChange(index)}
                                            onPaste={index === 0 ? handlePaste : undefined} // Обработчик вставки только для первого Input
                                        />
                                    ))}
                                </Box>
                            </Stack>
                            {isError && (
                                isErrorMessage ? (
                                    <Box sx={{ display: 'flex', gap: 1, justifyContent: 'center' }}>
                                        <Typography color="danger">{isErrorMessage}</Typography>
                                    </Box>
                                ) : (
                                    <Box sx={{ display: 'flex', gap: 1, justifyContent: 'center' }}>
                                        <Typography color="danger">Invalid or expired activation code</Typography>
                                    </Box>
                                )
                            )}
                        </>
                    )}



                    <CardOverflow sx={{ borderTop: '1px solid', borderColor: 'divider' }}>
                        {isLoading && (
                                <LinearProgress
                                    variant="plain"
                                    size="sm"
                                    sx={{
                                        width: '100%', // Ширина равна 100% родительского элемента
                                        position: 'absolute', // Абсолютное позиционирование
                                        top: 0, // Позиционируем в верхней части CardOverflow
                                        left: 0, // Позиционируем в левой части CardOverflow
                                        m: 0, // Убираем внешние отступы
                                        borderRadius: 0 // Убираем скругление
                                    }}
                                />
                            )
                        }
                        <CardActions sx={{ justifyContent: 'space-between', pt: 2, alignItems: 'center', display: 'flex' }}>
                            <Box sx={{ display: 'flex', flexGrow: 1, justifyContent: 'flex-start' }}>
                                {countDownReSend !== null && !isSuccess && (
                                    countDownReSend > 0 ? (
                                        <>Resend ({Math.floor(countDownReSend / 60)}:{('0' + countDownReSend % 60).slice(-2)})</>
                                    ):(
                                    <Button
                                        color="primary"
                                        onClick={handleResendActivationCode}
                                        variant="soft"
                                        loading={isResendLoading}
                                    >
                                        Resend
                                    </Button>
                                    )
                                )}
                            </Box>
                            <Box sx={{ display: 'flex', flexGrow: 1, justifyContent: 'flex-end' }}>
                                {isSuccess ? (
                                    <Button
                                        size="sm"
                                        color="neutral"
                                        onClick={goActivate}
                                    >
                                        GO TO DASHBOARD
                                    </Button>
                                ) : (
                                    <Button
                                        size="sm"
                                        variant="outlined"
                                        color="neutral"
                                        onClick={handleCancel}
                                        disabled={isLoading}
                                    >
                                        Cancel
                                    </Button>
                                )}
                            </Box>
                        </CardActions>
                    </CardOverflow>
                </Card>

        </Box>
    );
};

export default AccountActivationPage;
