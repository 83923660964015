// LocalizedAppWrapper.tsx
import React, { useEffect, useState } from 'react';
import { I18nProvider } from "@lingui/react";
import { i18n } from "@lingui/core";
import { sourceLocale } from "../langConfig";
import LoadingSpinner from "../components/UI/LoadingSpinner";
import ErrorLocalePage from "../pages/404/ErrorLocale";
import { useAuth } from "../context/GlobalContext";
import NotConnectDB from "../pages/404/NotConnectDB";


interface Props {
    children: React.ReactNode;
}



const LocalizedAppWrapper: React.FC<Props> = ({ children }) => {

    const { globalSettings,isAuthChecked,isGSChecked } = useAuth();
    const [isLoadLang, setIsLoadLang] = useState(false);
    const [isErrorLocale, setIsErrorLocale] = useState(false);

    useEffect(() => {
        async function loadLocale(locale: string) {
            try {
                const { messages } = await import(`../locales/${locale}/messages`);
                i18n.load(locale, messages);
                i18n.activate(locale);
                setIsLoadLang(true);
                document.documentElement.setAttribute('lang', locale);
            } catch (error) {
                console.error(`Could not load ${locale} locale`, error);
                setIsLoadLang(true);
                localStorage.removeItem('lang');
                return false;
            }
            return true;
        }

        async function loadAndActivateLocale() {

            let userLocale;

            if (globalSettings?.localization) {
                userLocale = localStorage.getItem('lang') || globalSettings?.defaultLocale || sourceLocale;
            } else {
                userLocale = globalSettings?.defaultLocale || sourceLocale;
                localStorage.removeItem('lang');
            }

            if (!(await loadLocale(userLocale))) {
                console.log(`Falling back to source locale: ${sourceLocale}`);
                if (!(await loadLocale(sourceLocale))) {
                    console.error(`Could not load source locale: ${sourceLocale}`);
                    setIsErrorLocale(true);
                }
            }
        }

        if (isGSChecked) loadAndActivateLocale();
    }, [isGSChecked]);

    if (!isAuthChecked || !isGSChecked || !isLoadLang) return (<LoadingSpinner />)

    if (isErrorLocale) return (<ErrorLocalePage />);

    if (!globalSettings) return (<NotConnectDB/>);

    return (
        <I18nProvider i18n={i18n}>
            {children}
        </I18nProvider>
    );
};

export default LocalizedAppWrapper;
