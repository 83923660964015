import React, {JSX} from 'react';

//MUI
import {
    Table,
    Avatar,
    Typography,
    Checkbox,
    Box,
    Chip,
    LinearProgress,
    IconButton,
    FormControl,
    FormLabel,
    Input,
    Button,
    Sheet, Skeleton
} from '@mui/joy';

// Icons
import PersonIcon from '@mui/icons-material/Person';
import PersonOffIcon from '@mui/icons-material/PersonOff';
import SearchIcon from '@mui/icons-material/Search';
import SearchOffIcon from '@mui/icons-material/SearchOff';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

// Functions
import {
    getRoleDisplayIcon,
    getRoleDisplayName,
    getRoleDisplayColor,
} from '../../../../../components/UI/getRoleDisplayName';  // Импорт функций для отображения ролей

import { i18n } from '@lingui/core';  // Для локализации текста
import md5 from 'md5';

import { User } from './userInterfaces';  // Интерфейс пользователя
import {iconButtonClasses} from "@mui/joy/IconButton";

import {Trans} from "@lingui/macro";
import { msg } from "@lingui/macro";
import { useLingui } from "@lingui/react";

interface UsersTableProps {
    users: User[];
    isLoading: boolean;
    i18n: typeof i18n;
    searchQuery: string;
    searchPreQuery: string;
    setSearchQuery: (query: string) => void;
    setSearchPreQuery: (query: string) => void;
    renderFilters: () => JSX.Element;
    rowMenu: (rowID: number) => JSX.Element | null;
    page:number;
    limit:number;
    totalPages:number;
    totalUsers:number;
    handlePageChange : (newPage:number)=> void;

    selectedIds: number[] | null;
    setSelectedIds: React.Dispatch<React.SetStateAction<number[] | null>>;
    renderSelectedRowMenu: () => JSX.Element | null;
}



export const UsersTable = ({
   users,
   isLoading,
   i18n,
   searchQuery,
   searchPreQuery,
   setSearchQuery,
   setSearchPreQuery,
   renderFilters,
   rowMenu,
   page,
   limit,
   totalPages,
   totalUsers,
   handlePageChange,
   selectedIds,
   setSelectedIds,
   renderSelectedRowMenu
}: UsersTableProps) => {
    const { _ } = useLingui();
    const gravatarUrl = (email: string) => {
        const trimmedEmail = email.trim().toLowerCase();
        const hash = md5(trimmedEmail).toString();
        return `https://www.gravatar.com/avatar/${hash}?d=identicon`;
    };

    const startUserIndex = (page - 1) * limit + 1;
    const endUserIndex = startUserIndex + users.length - 1;

    const handleCheckboxChange = (userId: number, isChecked: boolean) => {
        setSelectedIds((prevIds: number[] | null) => {
            if (prevIds) {
                if (isChecked) {
                    // Add userId if it's not already included
                    return prevIds.includes(userId) ? prevIds : [...prevIds, userId];
                } else {
                    // Remove userId
                    return prevIds.filter((id) => id !== userId);
                }
            }
            // If prevIds is null and isChecked is true, create a new array with userId
            return isChecked ? [userId] : null;
        });
    };

    return (
        <>
            <Box className="SearchAndFilters-tabletUp" sx={{borderRadius: 'sm', py: 2, display: { xs: 'none', sm: 'flex' }, flexWrap: 'wrap', gap: 1.5, '& > *': {minWidth: { xs: '120px', md: '160px' },},}}>
                <FormControl sx={{ flex: 1 }} size="sm">
                    <FormLabel><Trans id="UsersTable.searcForUser">Search for user</Trans></FormLabel>
                    <Input
                        size="sm"
                        placeholder={i18n._({ id: "UsersTable.searc", message: "Search" })}
                        startDecorator={<SearchIcon />}
                        onChange={(event) => {
                            const newValue = event.target.value;
                            setSearchPreQuery(newValue);
                            if (newValue === '') {
                                setSearchQuery('');
                            }
                        }}
                        value={searchPreQuery}
                        disabled={isLoading}
                        endDecorator={
                            searchPreQuery.length > 0 && (
                                <Button
                                    startDecorator={searchQuery === searchPreQuery ? <SearchOffIcon/> : <SearchIcon/>}
                                    onClick={() => {
                                        if (searchQuery === searchPreQuery) {
                                            setSearchPreQuery("");
                                            setSearchQuery("");
                                        } else {
                                            setSearchQuery(searchPreQuery);
                                        }
                                    }}
                                >
                                    {searchQuery === searchPreQuery ? _(msg({ id: "UsersTable.clear", message: "Clear" })) : _(msg({ id: "UsersTable.searc", message: "Search" }))}
                                </Button>
                            )
                        }
                    />
                </FormControl>
                {renderFilters()}
            </Box>

            <Sheet className="OrderTableContainer" variant="outlined" sx={{display: { xs: 'none', sm: 'initial' }, width: '100%', borderRadius: 'sm', flexShrink: 1, overflow: 'auto', minHeight: 0,}}>

                <Table
                    aria-labelledby="tableTitle"
                    stickyHeader
                    stickyFooter
                    hoverRow
                    sx={{
                        '--Table-headerUnderlineThickness': '1px',
                        '--TableRow-hoverBackground': 'var(--joy-palette-background-level1)',
                        '--TableCell-paddingY': '4px',
                        '--TableCell-paddingX': '8px',
                    }}
                >
                    <thead>
                    <tr>
                        <th style={{ width: "5%", textAlign: 'center', padding: '12px 6px' }}>
                            <Checkbox
                                size="sm"
                                sx={{ verticalAlign: 'text-bottom' }}
                                indeterminate={(selectedIds?.length || 0) > 0 && (selectedIds?.length || 0) < users.length}
                                checked={selectedIds ? (selectedIds.length === users.length && users.length > 0) : false}
                                onChange={(event) => {
                                    setSelectedIds(
                                        event.target.checked ? users.map((user, index) => Number(index)) : []
                                    );
                                }}
                                color={(selectedIds?.length || 0) > 0 ? 'primary' : undefined}
                                disabled={isLoading}
                            />
                        </th>
                        <th style={{ width: "35%", padding: '12px 6px' }}><Trans id="UsersTable.user">User</Trans></th>
                        <th style={{ width: "20%", padding: '12px 6px' }}><Trans id="UsersTable.role">Role</Trans></th>
                        <th style={{ width: "15%", padding: '12px 6px',textAlign:'center'}}><Trans id="UsersTable.email">Email</Trans></th>
                        <th style={{ width: "15%", padding: '12px 6px',textAlign:'center' }}><Trans id="UsersTable.block">Blocked</Trans></th>
                        <th style={{ width: "15%", padding: '12px 6px',textAlign:'center' }}><Trans id="UsersTable.actio">Action</Trans></th>
                    </tr>

                    </thead>

                    <tbody>

                    {isLoading && (
                        <tr >
                            <td>
                                <LinearProgress
                                    variant="plain"
                                    size="sm"
                                    sx={{
                                        width: '100%', // Ширина равна 100% родительского элемента
                                        position: 'static', // Абсолютное позиционирование
                                        left: 0, // Позиционируем в левой части CardOverflow
                                        m: 0, // Убираем внешние отступы
                                        borderRadius: 0, // Убираем скругление
                                        zIndex:11,
                                    }}
                                />
                            </td>
                        </tr>
                    )}

                    {users.length === 0 ? (

                        !isLoading && (
                            <tr>
                                <td colSpan={7} style={{ textAlign: 'center' }}>
                                    <Trans id="UsersTable.noResul">No results</Trans>
                                </td>
                            </tr>
                        )
                    ) : (
                        users.map((row,index) => (
                            <tr key={row.id} >

                                <td style={{ textAlign: 'center', width: 120 }}>
                                    <Checkbox
                                        size="sm"
                                        slotProps={{ checkbox: { sx: { textAlign: 'left' } } }}
                                        sx={{ verticalAlign: 'text-bottom' }}
                                        checked={selectedIds?.includes(Number(index)) || false}
                                        onChange={(event) => handleCheckboxChange(Number(index), event.target.checked)}
                                        disabled={isLoading}
                                    />
                                </td>
                                <td style={{ overflow: 'hidden'}}>
                                    <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                                        <Avatar size="sm" src={gravatarUrl(row.user_data.email)}></Avatar>
                                        <div>

                                            <Typography level="body-xs">{row.user_data.name} ({row.user_data.login})</Typography>
                                            <Typography level="body-xs">{row.user_data.email}</Typography>

                                        </div>
                                    </Box>
                                </td>
                                <td style={{ overflow: 'hidden'}}>
                                    <Chip
                                        variant="soft"
                                        size="sm"
                                        startDecorator={
                                            getRoleDisplayIcon(row.role,i18n)
                                        }
                                        color={getRoleDisplayColor(row.role,i18n)}
                                    >
                                        {getRoleDisplayName(row.role,i18n)}
                                    </Chip>
                                </td>
                                <td style={{textAlign:'center' }}>
                                    {row.email_confirmed ? (
                                        <Typography level="title-lg" color="success"><CheckCircleOutlineIcon/></Typography>
                                    ) : (
                                        <Typography level="title-lg" color="danger"><HourglassBottomIcon/></Typography>
                                    )}

                                </td>
                                <td style={{textAlign:'center' }}>
                                    {row.blocked ? (
                                        <Typography level="title-lg" color="danger"><PersonOffIcon/></Typography>
                                    ) : (
                                        <Typography level="title-lg" color="success"><PersonIcon/></Typography>
                                    )}

                                </td>

                                <td style={{textAlign:'center' }}>
                                    <Box sx={{ display: 'flex', gap: 2, alignItems: 'center', justifyContent: 'center' }}>
                                        {rowMenu(index)}
                                    </Box>
                                </td>
                            </tr>
                        ))
                    )}
                    </tbody>
                    {!isLoading && users.length > 0 && (
                        <tfoot>
                            <tr>
                                <td colSpan={3} style={{textAlign:'left' }}>{renderSelectedRowMenu()}</td>
                                <td colSpan={4} style={{textAlign:'right' }}>{i18n._({ id: "UsersTable.showiStartToEndusOfT", message: "showing {startUserIndex} to {endUserIndex} of {totalUsers}", values: { startUserIndex:startUserIndex, endUserIndex:endUserIndex, totalUsers:totalUsers } })}</td>
                            </tr>
                        </tfoot>
                    )}
                </Table>
            </Sheet>
            {users.length > 0 && (
                <Box className="Pagination-laptopUp" sx={{pt: 2, gap: 1, [`& .${iconButtonClasses.root}`]: { borderRadius: '50%' }, display: {xs: 'none', md: 'flex',},}}>
                    <Button
                        size="sm"
                        variant="outlined"
                        color="neutral"
                        startDecorator={<KeyboardArrowLeftIcon />}
                        onClick={() => handlePageChange(page - 1)}
                        disabled={page === 1 || isLoading}
                    >
                        <Trans id="UsersTable.previ">Previous</Trans>
                    </Button>

                    <Box sx={{ flex: 1 }} />
                    {[...Array(totalPages)].map((_, idx) => (
                        <IconButton
                            key={idx + 1}
                            onClick={() => handlePageChange(idx + 1)}
                            size="sm"
                            variant={idx + 1 === page ? 'plain' : 'outlined'}
                            color="neutral"
                            disabled={isLoading}
                        >
                            {idx + 1}
                        </IconButton>
                    ))}
                    <Box sx={{ flex: 1 }} />

                    <Button
                        onClick={() => handlePageChange(page + 1)}
                        disabled={page === totalPages || isLoading}
                        size="sm"
                        variant="outlined"
                        color="neutral"
                        endDecorator={<KeyboardArrowRightIcon />}

                    >
                        <Trans id="UsersTable.next">Next</Trans>
                    </Button>

                </Box>
            )}
        </>
    );
};
