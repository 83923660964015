import * as React from 'react';
import md5 from 'md5';
import { useAuth} from "../context/GlobalContext";
import {MyRoute,adminRoutes,dashboardRoutes} from "../routes";
import {i18n} from "@lingui/core";

import GlobalStyles from '@mui/joy/GlobalStyles';
import Avatar from '@mui/joy/Avatar';
import Box from '@mui/joy/Box';
import Divider from '@mui/joy/Divider';
import IconButton from '@mui/joy/IconButton';
import Input from '@mui/joy/Input';

import List from '@mui/joy/List';
import ListItem from '@mui/joy/ListItem';
import ListItemButton, { listItemButtonClasses } from '@mui/joy/ListItemButton';
import ListItemContent from '@mui/joy/ListItemContent';
import Typography from '@mui/joy/Typography';
import Sheet from '@mui/joy/Sheet';

import SearchRoundedIcon from '@mui/icons-material/SearchRounded';

import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';


import ColorSchemeToggle from './ColorSchemeToggle';
import { closeSidebar } from '../utils';
import NavLinkMui from "./UI/NavLinkMui";
import CompanyLogoBlock from "./UI/CompanyLogoBlock";
import LangSelectionModal from "./Lang/LangSelectionModal";
import {useEffect, useState} from "react";
import {getRoleDisplayName} from "./UI/getRoleDisplayName";


function Toggler({
  defaultExpanded = false,
  renderToggle,
  children,
}: {
  defaultExpanded?: boolean;
  children: React.ReactNode;
  renderToggle: (params: {
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  }) => React.ReactNode;
}) {
  const [open, setOpen] = React.useState(defaultExpanded);
  return (
    <React.Fragment>
      {renderToggle({ open, setOpen })}
      <Box
        sx={{
          display: 'grid',
          gridTemplateRows: open ? '1fr' : '0fr',
          transition: '0.2s ease',
          '& > *': {
            overflow: 'hidden',
          },
        }}
      >
        {children}
      </Box>
    </React.Fragment>
  );
}

function gravatarUrl(email : String) {
    const trimmedEmail = email.trim().toLowerCase();
    const hash = md5(trimmedEmail).toString();
    return `https://www.gravatar.com/avatar/${hash}?d=identicon`;
}


function generateMenuItem(route: MyRoute) {
    const Icon = route.menu?.icon;
    return (
        <ListItem key={route.path}>
            <ListItemButton component={NavLinkMui} to={route.path || ''}>
                {Icon && <Icon />}
                <ListItemContent>
                    <Typography level="title-sm">{route.menu?.text}</Typography>
                </ListItemContent>
            </ListItemButton>
        </ListItem>
    );
}



function getMenuItems(routes: MyRoute[], userRole: string) {
    // Фильтрация маршрутов на основе роли пользователя
    const filteredRoutes = routes.filter(route => {
        // Если у маршрута определена роль, проверяем, входит ли роль пользователя в список разрешенных ролей
        if (route.role) {
            return route.role.includes(userRole);
        }
        // Если роль не определена, маршрут доступен для всех
        return true;
    });

    // Отображение каждого маршрута
    return filteredRoutes.map((route, index) => {
        // Если у маршрута есть дочерние маршруты
        if (route.children && route.children.length > 0) {
            // Фильтруем дочерние маршруты на основе роли
            const filteredChildren = route.children.filter(childRoute => {
                if (childRoute.role) {
                    return childRoute.role.includes(userRole);
                }
                return true;
            });

            // Если после фильтрации нет дочерних маршрутов, не отображаем родительский маршрут
            if (filteredChildren.length === 0) {
                return null;
            }

            // Иначе, отображаем родительский маршрут с его дочерними маршрутами
            return (
                <ListItem nested key={index}>
                    <Toggler
                        defaultExpanded={false}
                        renderToggle={({ open, setOpen }) => (
                            <ListItemButton onClick={() => setOpen(!open)}>
                                {route.icon && <route.icon />}
                                <ListItemContent>
                                    <Typography level="title-sm">{route.title}</Typography>
                                </ListItemContent>
                                <KeyboardArrowDownIcon
                                    sx={{ transform: open ? 'rotate(180deg)' : 'none' }}
                                />
                            </ListItemButton>
                        )}
                    >
                        <List sx={{ gap: 0.5 }}>
                            {filteredChildren.map((childRoute, childIndex) => (
                                <ListItem sx={{ mt: 0.5 }} key={childIndex}>
                                    <ListItemButton component={NavLinkMui} to={childRoute.path || ''}>
                                        {childRoute.menu?.text}
                                    </ListItemButton>
                                </ListItem>
                            ))}
                        </List>
                    </Toggler>
                </ListItem>
            );
        } else {
            // Иначе отображаем обычный пункт меню
            return generateMenuItem(route);
        }
    });
}



function filterRoutesWithMenu(routes: MyRoute[]): MyRoute[] {
    return routes.filter((route: MyRoute) => {

        const hasMenu = !!route.menu;

        const filteredChildren = route.children?.filter((child: MyRoute) => !!child.menu) || [];

        if (hasMenu || filteredChildren.length > 0) {
            if (filteredChildren.length > 0) {
                route.children = filteredChildren;
            }
            return true;
        }
        return false;
    });
}


export default function Sidebar() {
    const { user, logout,globalSettings,globalLocale } = useAuth(); // Добавьте logout из useAuth
    const handleLogout = () => {
        logout(); // Вызов функции logout из контекста
        // Перенаправление пользователя на страницу входа, если нужно
    };
    const [dasbboardMenuList, setDasbboardMenuList] = useState<MyRoute[]>([]);
    const [dasbboardAdminMenuList, setDasbboardAdminMenuList] = useState<MyRoute[]>([]);


    useEffect(() => {
        // Фильтрация маршрутов, чтобы исключить те, что не содержат ключ 'menu'
        const filteredDashboardRoutes = filterRoutesWithMenu(dashboardRoutes(i18n));
        const filteredAdminRoutes = filterRoutesWithMenu(adminRoutes(i18n));

        // Установка отфильтрованных маршрутов в состояние
        setDasbboardMenuList(filteredDashboardRoutes);
        setDasbboardAdminMenuList(filteredAdminRoutes);
    }, [globalLocale]);



    // Проверка, залогинен ли пользователь
    if (!user) {
        return null; // или какой-то другой вывод, если пользователь не залогинен
    }



    return (
        <Sheet
          className="Sidebar"
          sx={{
            position: { xs: 'fixed', md: 'sticky' },
            transform: {
              xs: 'translateX(calc(100% * (var(--SideNavigation-slideIn, 0) - 1)))',
              md: 'none',
            },
            transition: 'transform 0.4s, width 0.4s',
            zIndex: 10000,
            height: '100dvh',
            width: 'var(--Sidebar-width)',
            top: 0,
            p: 2,
            flexShrink: 0,
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            borderRight: '1px solid',
            borderColor: 'divider',
          }}
        >
          <GlobalStyles
            styles={(theme) => ({
              ':root': {
                '--Sidebar-width': '220px',
                [theme.breakpoints.up('lg')]: {
                  '--Sidebar-width': '240px',
                },
              },
            })}
          />
          <Box
            className="Sidebar-overlay"
            sx={{
              position: 'fixed',
              zIndex: 9998,
              top: 0,
              left: 0,
              width: '100vw',
              height: '100vh',
              opacity: 'var(--SideNavigation-slideIn)',
              backgroundColor: 'var(--joy-palette-background-backdrop)',
              transition: 'opacity 0.4s',
              transform: {
                xs: 'translateX(calc(100% * (var(--SideNavigation-slideIn, 0) - 1) + var(--SideNavigation-slideIn, 0) * var(--Sidebar-width, 0px)))',
                lg: 'translateX(-100%)',
              },
            }}
            onClick={() => closeSidebar()}
          />
          <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
              <CompanyLogoBlock />
              <ColorSchemeToggle sx={{ ml: 'auto' }} />
          </Box>
          <Input size="sm" startDecorator={<SearchRoundedIcon />} placeholder="Search" />
          <Box
            sx={{
              minHeight: 0,
              overflow: 'hidden auto',
              flexGrow: 1,
              display: 'flex',
              flexDirection: 'column',
              [`& .${listItemButtonClasses.root}`]: {
                gap: 1.5,
              },
            }}
          >
            <List
              size="sm"
              sx={{
                gap: 1,
                '--List-nestedInsetStart': '30px',
                '--ListItem-radius': (theme) => theme.vars.radius.sm,
              }}
            >
                {getMenuItems(dasbboardMenuList, user?.role)}



            </List>


            <List
                size="sm"
                sx={{
                    mt: 'auto',
                    flexGrow: 0,
                    mb: 0,
                    gap: 1,
                    '--List-nestedInsetStart': '30px',
                    '--ListItem-radius': (theme) => theme.vars.radius.sm,
                }}
            >
                {getMenuItems(dasbboardAdminMenuList, user?.role)}


                {globalSettings?.localization && (
                    <LangSelectionModal triggerType="listItem"/>
                )}

            </List>

          </Box>
            <Divider />
            <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                <Avatar
                    variant="outlined"
                    size="sm"
                    src={gravatarUrl(user.email)}
                />
                <Box sx={{ minWidth: 0, flex: 1 }}>
                    <Typography level="title-sm">{user.name}</Typography>
                    <Typography level="body-xs">{getRoleDisplayName(user.role,i18n)}</Typography>
                </Box>
                <IconButton size="sm" variant="plain" color="neutral" onClick={handleLogout}>
                    <LogoutRoundedIcon />
                </IconButton>
            </Box>
        </Sheet>
    );
}
