// routes.ts
import React from "react";
import { GlobalSettingsInterface } from './context/GlobalContext';


import Home from "./pages/Home";
import SingInPage from "./pages/Auth/SingIn";
import Dashboard from "./pages/Dashboard";
import WalletsPage from "./pages/Dashboard/Wallets";
import InvitationsPage from "./pages/Dashboard/Admin/Users/InvitationsPage";
import MyProfilePage from "./pages/Dashboard/User/MyProfilePage";
import RolePermissions from "./pages/Dashboard/Admin/Users/RolePermissions";
import UsersListPage from "./pages/Dashboard/Admin/Users/UsersListPage";
import AuthSettingsPage from "./pages/Dashboard/Admin/Settings/AuthSettingsPage";
import SingUpPage from "./pages/Auth/SingUp";

import BadgeIcon from "@mui/icons-material/Badge";
import DashboardRoundedIcon from "@mui/icons-material/DashboardRounded";
import GroupRoundedIcon from "@mui/icons-material/GroupRounded";
import SupportRoundedIcon from "@mui/icons-material/SupportRounded";
import HandymanIcon from '@mui/icons-material/Handyman';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';

import GlobalSettingsPage from "./pages/Dashboard/Admin/Settings/GlobalSettingsPage";
import PasswordRecoveryPage from "./pages/Auth/PasswordRecovery";
import PasswordRecoveryConfirmPage from "./pages/Auth/PasswordRecoveryConfirmPage";

import { i18n as i18Type } from "@lingui/core";
import SendEmailSettingsPage from "./pages/Dashboard/Admin/Settings/SendEmailSettingsPage";
import BlockChainsPage from "./pages/Dashboard/Admin/Settings/BlockChainsPage";
import TokensPage from "./pages/Dashboard/Admin/Settings/TokensPage";
import GroupAddWalletPage from "./pages/Dashboard/Wallets/GroupAddWalletPage";
import PorfolioPage from "./pages/Dashboard/Portfolio";



export interface MyRoute {
    path?: string; // Может быть неопределенным для группы маршрутов
    element?: React.ComponentType | null;
    protected?: boolean;
    role?: string[];
    title: string;
    icon?: React.ComponentType;
    menu?: {
        text: string;
        icon?: React.ComponentType;
    };
    children?: MyRoute[]; // Добавлено для поддержки вложенных маршрутов
}



// Досьпуны всем
export const homePageRoutes = (i18n: typeof i18Type) => {

    return [
        {
            path: "/",
            element: Home,
            protected: false,
            title: i18n._({id: "route.home.title", message: "Home"}),
        },
        {
            path: "/singin",
            element: SingInPage,
            protected: false,
            title: i18n._({id: "route.singIn.title", message: "SingIn"}),
        },
    ]
};


// Дашбоард
export const dashboardRoutes = (i18n: typeof i18Type) => {

    return [
        {
            path: "/dashboard",
            element: Dashboard,
            protected: true,
            title: i18n._({id: "route.dashboard.title", message: "Dashboard"}),
            menu: {
                text: i18n._({id: "route.dashboard.menu.text", message: "Dashboard"}),
                icon: DashboardRoundedIcon
            }
        },
        {
            path: "/dashboard/wallets",
            element: WalletsPage,
            protected: true,
            title: i18n._({id: "route.dashboard.wallets.title", message: "Wallets"}),
            menu: {
                text: i18n._({id: "route.dashboard.wallets.menu.text", message: "Wallets"}),
                icon: DashboardRoundedIcon
            }
        },
        {
            path: "/dashboard/wallets/add-group",
            element: GroupAddWalletPage,
            protected: true,
            title: i18n._({id: "route.dashboard.wallets.addGroup.title", message: "Wallets"}),
        },
        {
            path: "/dashboard/portfolio-wallets",
            element: PorfolioPage,
            protected: true,
            title: i18n._({id: "route.dashboard.portfolio-wallets.title", message: "Portfolio"}),
            menu: {
                text: i18n._({id: "route.dashboard.portfolio-wallets.menu.text", message: "Portfolio"}),
                icon: DashboardRoundedIcon
            }
        },
        {
            path: "/dashboard/myprofile",
            element: MyProfilePage,
            protected: true,
            title: i18n._({id: "route.dashboard.myprofile.title", message: "My profile"}),
            menu: {
                text: i18n._({id: "route.dashboard.myprofile.menu.text", message: "My profile"}),
                icon: BadgeIcon
            }
        },
        // ADD
    ]
};


// Административные пункты меню (для управления всем проектом)
export const adminRoutes= (i18n: typeof i18Type) => {

    return [

        {
            path: "/dashboard/support",
            element: Dashboard,
            protected: true,
            role: ['admin'],
            title: i18n._({id: "route.dashboard.admin.support.title", message: "Support"}),
            menu: {
                text: i18n._({id: "route.dashboard.admin.support.menu.text", message: "Support"}),
                icon: SupportRoundedIcon
            }
        },

        {
            title:  i18n._({id: "route.dashboard.admin.BlockChains", message: "BlockChains"}),
            icon: GroupRoundedIcon,
            children: [
                {
                    path: "/admin/blockchain/list",
                    element: BlockChainsPage,
                    protected: true,
                    role: ['admin'],
                    title: i18n._({id: "route.dashboard.admin.BlockChains.BlockChainsList.title", message: "BlockChains list"}),
                    menu: {
                        text: i18n._({id: "route.dashboard.admin.BlockChains.BlockChainsList.menu.text", message: "BlockChains list"}),
                    }
                },
                {
                    path: "/admin/blockchain/list22",
                    element: BlockChainsPage,
                    protected: true,
                    role: ['admin'],
                    title: i18n._({id: "route.dashboard.admin.BlockChains.BlockChainsList.title", message: "BlockChains list"}),

                },
                {
                    path: "/admin/token/list",
                    element: TokensPage,
                    protected: true,
                    role: ['admin'],
                    title: i18n._({id: "route.dashboard.admin.Tokens.TokensList.title", message: "Tokens list"}),
                    menu: {
                        text: i18n._({id: "route.dashboard.admin.BlockChains.TokensList.menu.text", message: "Tokens list"}),
                    }
                },
                ]
        },

        {
            title:  i18n._({id: "route.dashboard.admin.users", message: "Users"}),
            icon: GroupRoundedIcon,
            children: [
                {
                    path: "/admin/users/list",
                    element: UsersListPage,
                    protected: true,
                    role: ['admin'],
                    title: i18n._({id: "route.dashboard.admin.users.usersList.title", message: "Users list"}),
                    menu: {
                        text: i18n._({id: "route.dashboard.admin.users.usersList.menu.text", message: "Users list"}),
                    }
                },
                {
                    path: "/admin/users/invitations",
                    element: InvitationsPage,
                    protected: true,
                    role: ['admin'],
                    title: i18n._({id: "route.dashboard.admin.users.invitations.title", message: "Invitations"}),
                    menu: {
                        text: i18n._({id: "route.dashboard.admin.users.invitations.menu.text", message: "Invitations"}),
                    }
                },
                {
                    path: "/admin/users/rolepermissions",
                    element: RolePermissions,
                    protected: true,
                    role: ['admin'],
                    title: i18n._({id: "route.dashboard.admin.users.rolepermissions.title", message: "Role & Permissions"}),
                    menu: {
                        text: i18n._({id: "route.dashboard.admin.users.rolepermissions.menu.text", message: "Role & Permissions"}),
                    }
                }
            ]
        },
        {
            title: i18n._({id: "route.dashboard.admin.settings", message: "Settings"}),
            icon: HandymanIcon,
            children: [
                {
                    path: "/admin/settings/global",
                    element: GlobalSettingsPage,
                    protected: true,
                    role: ['admin'],
                    title:  i18n._({id: "route.dashboard.admin.settings.global.title", message: "Global settings"}),
                    menu: {
                        text: i18n._({id: "route.dashboard.admin.settings.global.menu.text", message: "Global settings"}),
                        icon: DashboardRoundedIcon
                    }
                },
                {
                    path: "/admin/settings/auth",
                    element: AuthSettingsPage,
                    protected: true,
                    role: ['admin'],
                    title: i18n._({id: "route.dashboard.admin.settings.auth.title", message: "Auth settings"}),
                    menu: {
                        text: i18n._({id: "route.dashboard.admin.settings.auth.menu.text", message: "Auth settings"}),
                        icon: DashboardRoundedIcon
                    }
                },
                {
                    path: "/admin/settings/send-email",
                    element: SendEmailSettingsPage,
                    protected: true,
                    role: ['admin'],
                    title:  i18n._({id: "route.dashboard.admin.settings.sendEmail.title", message: "Email settings"}),
                    menu: {
                        text: i18n._({id: "route.dashboard.admin.settings.sendEmail.menu.text", message: "Email settings"}),
                        icon: AlternateEmailIcon
                    }
                }
            ]
        }
    ]
};


// Функция для рекурсивного извлечения всех маршрутов, включая дочерние
function extractRoutesWithChildren(routes: MyRoute[], role: string): MyRoute[] {
    //role на всякій случай
    return routes.reduce((acc: MyRoute[], route: MyRoute) => {
        // Добавляем текущий маршрут
        acc.push(route);

        // Если у маршрута есть дочерние, рекурсивно извлекаем их
        if (route.children && route.children.length > 0) {
            acc.push(...extractRoutesWithChildren(route.children,role));
        }

        return acc;
    }, []);
}




// Маршрут регистрации
export const singUpRoute = (i18n: typeof i18Type) => {

    return (
        {
            path: "/singup",
            element: SingUpPage,
            protected: false,
            title: i18n._({id: "route.singup.title", message: "Sing Up"}),
        }
    );
};

// Маршруты восстановления пароля
export const passwordRecoveryRoutes = (i18n: typeof i18Type) => {

    return [
        {
            path: "/forgot-password",
            element: PasswordRecoveryPage,
            protected: false,
            title: i18n._({id: "route.passwordrecovery.title", message: "Password Recovery"}),
        },
        {
            path: "/forgot-password/confirm",
            element: PasswordRecoveryConfirmPage,
            protected: false,
            title: i18n._({id: "route.passwordConfirmation.title", message: "Password Confirmation"}),
        }
    ]
};
// Функция для получения маршрутов
export const getRoutes = (settings: GlobalSettingsInterface,i18nInstance:typeof i18Type,role:string): MyRoute[] => {

    let allRoutes: MyRoute[] = [
        ...extractRoutesWithChildren(homePageRoutes(i18nInstance),role),
        ...extractRoutesWithChildren(dashboardRoutes(i18nInstance),role),
        ...extractRoutesWithChildren(adminRoutes(i18nInstance),role),
    ];

    if (settings.user_registration) {
        allRoutes.push(singUpRoute(i18nInstance));
    }

    if (settings.user_password_recovery) {
        allRoutes.push(...passwordRecoveryRoutes(i18nInstance));
    }

    return allRoutes;
};


export default getRoutes;


// path  - URL страницы/компонента
// title- title
// menu:{text:"Support",icon:SupportRoundedIcon} - формирует пункт меню, text - текст меню , icon - иконка меню
// parent:{text:"User",icon:GroupRoundedIcon}} - формирует раскрвающийся список меню, где text - текст списка, icon - иконка списка. Все пункты меню групируются по своим parent
//protected - значит достпен только авторизированным пользователям
// protected: true, role:['admin'] role - доступен не только авторизированнум пользователю, но и только пользователю с этой ролью
