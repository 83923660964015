// UsersList.tsx
import React, {JSX, useEffect} from 'react';
import {
    Box,
    Avatar,
    Chip,
    Link,
    Divider,
    IconButton,
    Typography,
    List,
    ListItem,
    ListItemContent,
    ListItemDecorator,
    ListDivider,
    Menu,
    MenuButton,
    MenuItem,
    Dropdown, LinearProgress,
} from '@mui/joy';
import PersonIcon from '@mui/icons-material/Person';
import PersonOffIcon from '@mui/icons-material/PersonOff';
import {
    getRoleDisplayIcon,
    getRoleDisplayName,
    getRoleDisplayColor
} from "../../../../../components/UI/getRoleDisplayName";
import {i18n} from "@lingui/core";
import md5 from "md5";

import MoreHorizRoundedIcon from "@mui/icons-material/MoreHorizRounded";
import Input from "@mui/joy/Input";
import SearchIcon from "@mui/icons-material/Search";
import Button from "@mui/joy/Button";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import Modal from "@mui/joy/Modal";
import ModalDialog from "@mui/joy/ModalDialog";
import ModalClose from "@mui/joy/ModalClose";
import Sheet from "@mui/joy/Sheet";
import SearchOffIcon from "@mui/icons-material/SearchOff";

import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import HourglassBottomIcon from "@mui/icons-material/HourglassBottom";
import {Trans} from "@lingui/macro";
import { msg } from "@lingui/macro";
import { useLingui } from "@lingui/react";

interface User {
    id: string;
    role: string;
    email_confirmed: boolean;
    blocked: boolean;
    user_data: UserData;
    profile_permissions: ProfilePermissions;
}

interface UserData {
    name: string;
    email: string;
    login: string;
}

interface ProfilePermissions {
    change_name: boolean;
    change_password: boolean;
    change_email: boolean;
    change_login: boolean;
}

interface UsersListProps {
    users: User[];
    isLoading: boolean;
    i18n: typeof i18n;
    searchQuery: string;
    searchPreQuery: string;
    setSearchQuery: (query: string) => void;
    setSearchPreQuery: (query: string) => void;
    renderFilters: () => JSX.Element;
    rowMenu: (rowID: number) => JSX.Element | null;
    page:number,
    totalPages:number;
    handlePageChange : (newPage:number)=> void;
}



export const UsersList = ({
      users,
      isLoading,
      i18n,
      searchQuery,
      searchPreQuery,
      setSearchQuery,
      setSearchPreQuery,
      renderFilters,
      rowMenu,
      page,
      totalPages,
      handlePageChange
}: UsersListProps) => {
    const { _ } = useLingui();
    const gravatarUrl = (email: string) => {
        const trimmedEmail = email.trim().toLowerCase();
        const hash = md5(trimmedEmail).toString();
        return `https://www.gravatar.com/avatar/${hash}?d=identicon`;
    };
    const [isModalOpen, setIsModalOpen] = React.useState(false);

    useEffect(() => {
        setIsModalOpen(false)
    }, [users]);



    return (
        <>
            <Sheet className="SearchAndFilters-mobile" sx={{display: { xs: 'flex', sm: 'none' }, my: 1, gap: 1,}}>
                <Input
                    size="sm"
                    placeholder="Search"
                    sx={{ flexGrow: 1 }}
                    startDecorator={<SearchIcon />}
                    onChange={(event) => {
                        if (event.target.value === '')
                        {
                            setSearchQuery("")
                            setSearchPreQuery("")
                        } else {
                            setSearchPreQuery(event.target.value)
                        }

                    }}
                    value={searchPreQuery}
                    disabled={isLoading}
                    endDecorator={
                        searchPreQuery.length > 0 && (
                            searchQuery === searchPreQuery ? (
                                <Button
                                    startDecorator={<SearchOffIcon/>}
                                    onClick={(event)=>{
                                        setSearchPreQuery("");
                                        setSearchQuery("");
                                    }}
                                >
                                    <Trans id="UsersList.clear">Clear</Trans>
                                </Button>
                            ) : (
                                <Button
                                    onClick={(event)=>{setSearchQuery(searchPreQuery)}}
                                    startDecorator={<SearchIcon/>}
                                >
                                    <Trans id="UsersList.searc">Search</Trans>
                                </Button>
                            )
                        )
                    }
                />
                <IconButton
                    size="sm"
                    variant="outlined"
                    color="neutral"
                    onClick={() => setIsModalOpen(true)}
                >
                    <FilterAltIcon />
                </IconButton>
                <Modal open={isModalOpen} onClose={() => setIsModalOpen(false)}>
                    <ModalDialog aria-labelledby="filter-modal" layout="fullscreen">
                        <ModalClose />
                        <Typography id="filter-modal" level="h2">
                            <Trans id="UsersList.filte">Filters</Trans>
                        </Typography>
                        <Divider sx={{ my: 2 }} />
                        <Sheet sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                            {renderFilters()}
                        </Sheet>
                    </ModalDialog>
                </Modal>
            </Sheet>
            <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
                {!isLoading && (
                    users.length < 1 ? (
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'center', // Center horizontally
                                alignItems: 'center', // Center vertically
                                height: '100%', // Ensure the container takes up the full height
                                width: '100%', // Ensure the container takes up the full width
                            }}
                        >
                            <Typography>
                                <Trans id="UsersList.noResul">No results</Trans>
                            </Typography>
                        </Box>
                    ) : (
                        users.map((row,index) => (
                        <List
                            key={row.id}
                            size="sm"
                            sx={{
                                '--ListItem-paddingX': 0,
                            }}
                        >
                            <ListItem
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'start',
                                }}
                            >
                                <ListItemContent sx={{ display: 'flex', gap: 2, alignItems: 'start' }}>
                                    <ListItemDecorator>
                                        <Avatar size="sm"  src={gravatarUrl(row.user_data.email)}/>
                                    </ListItemDecorator>
                                    <div>
                                        <Typography fontWeight={600} gutterBottom>
                                            {row.user_data.name}
                                        </Typography>
                                        <Typography level="body-xs" gutterBottom>
                                            {row.user_data.email}
                                        </Typography>
                                        <Typography level="body-xs" gutterBottom>
                                            {row.user_data.login}
                                        </Typography>
                                    </div>
                                </ListItemContent>
                                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'end' }}>
                                    <Box sx={{ display: 'flex', gap: 1, alignItems: 'center', mb: 1 }}>
                                        <Chip
                                            variant="soft"
                                            size="sm"
                                            startDecorator={getRoleDisplayIcon(row.role, i18n)}
                                            color={getRoleDisplayColor(row.role, i18n)}
                                            sx={{ mr: 1 }}
                                        >
                                            {getRoleDisplayName(row.role, i18n)}
                                        </Chip>
                                        <Chip
                                            variant="soft"
                                            size="sm"
                                            color={row.email_confirmed ? 'success' : 'danger'}
                                        >
                                            {row.email_confirmed ? <CheckCircleOutlineIcon/> : <HourglassBottomIcon/>}
                                        </Chip>
                                        <Chip
                                            variant="soft"
                                            size="sm"
                                            color={row.blocked ? 'danger' : 'success'}
                                        >
                                            {row.blocked ? <PersonOffIcon/> : <PersonIcon/>}
                                        </Chip>
                                    </Box>
                                    {rowMenu(index)}
                                </Box>


                            </ListItem>
                            <ListDivider />
                        </List>
                    ))
                    )
                )}
            </Box>
                <Box
                    className="Pagination-mobile"
                    sx={{ display: { xs: 'flex', md: 'none' }, alignItems: 'center', py: 2 }}
                >
                    <IconButton
                        aria-label="previous page"
                        variant="outlined"
                        color="neutral"
                        size="sm"
                        onClick={() => handlePageChange(page - 1)}
                        disabled={page === 1 || isLoading}
                    >
                        <KeyboardArrowLeftIcon />
                    </IconButton>
                    {isLoading ? (
                        <LinearProgress
                            variant="plain"
                            size="sm"
                            sx={{
                                width: '100%', // Ширина равна 100% родительского элемента
                                borderRadius: 0, // Убираем скругление
                                zIndex:11,
                            }}
                        />
                    ):(

                        <Typography level="body-sm" mx="auto">
                            {users.length > 0 && (i18n._({ id: "UsersList.pagePageOfTotal", message: "Page {page} of {totalPages}", values: { page:page, totalPages:totalPages } }))}
                        </Typography>

                    )}

                    <IconButton
                        aria-label="next page"
                        variant="outlined"
                        color="neutral"
                        size="sm"
                        onClick={() => handlePageChange(page + 1)}
                        disabled={page >= totalPages || isLoading}
                    >
                        <KeyboardArrowRightIcon />
                    </IconButton>
                </Box>
        </>

    );
};
