import React, {useState, useEffect, JSX} from 'react';
import Web3 from 'web3';
import Box from '@mui/joy/Box';
import Typography from '@mui/joy/Typography';
import Sheet from '@mui/joy/Sheet';
import Button from "@mui/joy/Button";
import {LinearProgress, Table} from "@mui/joy";
import { Modal, ModalDialog, ModalClose, DialogTitle, DialogContent, DialogActions, FormControl, FormLabel, Input, FormHelperText, Divider, Switch } from "@mui/joy";
import IconButton, {iconButtonClasses} from "@mui/joy/IconButton";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import CustomSnackbar from "../../../../components/UI/CustomSnackbar";
import InfoOutlined from "@mui/icons-material/InfoOutlined";
import Autocomplete from '@mui/joy/Autocomplete';
import Textarea from "@mui/joy/Textarea";
import Dropdown from "@mui/joy/Dropdown";
import MenuButton from "@mui/joy/MenuButton";
import MoreHorizRoundedIcon from "@mui/icons-material/MoreHorizRounded";
import Menu from "@mui/joy/Menu";
import MenuItem from "@mui/joy/MenuItem";
import InfoIcon from "@mui/icons-material/Info";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import LoadingSpinner from "../../../../components/UI/LoadingSpinner";
import {msg, Trans} from "@lingui/macro";
import SearchIcon from "@mui/icons-material/Search";
import SearchOffIcon from "@mui/icons-material/SearchOff";
import Select from "@mui/joy/Select";
import Option from "@mui/joy/Option";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { i18n } from '@lingui/core';  // Для локализации текста
// Предположим, что это интерфейс для токена
interface BC {
    _id:string,
    name:string
}
interface Token {
    _id: string;
    name: string;
    address: string;
    abi: string;
    decimal: number;
    blockchainID: BC; // ID связанного блокчейна
}

const TokensPage = () => {
    const [tokens, setTokens] = useState<Token[]>([]);

    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(25); // Default rows per page
    const [selectedBlockchain, setSelectedBlockchain] = useState('');
    const [searchQuery, setSearchQuery] = useState('');
    const [searchPreQuery, setSearchPreQuery] = useState('');
    const [totalPages, setTotalPages]= useState(0);
    const [totalTokens, setTotalTokens]= useState(0);

    const startTokenIndex = (page - 1) * limit + 1;
    const endTokenIndex = startTokenIndex + tokens.length - 1;

    const [isLoading, setIsLoading] = useState<boolean>(false);

    const [isLoadingQuery, setIsLoadingQuery] = useState<boolean>(false);

    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
    const [selectedToken, setSelectedToken] = useState({
        id:'',
        name: '',
        address: '',
        abi: '',
        decimal:18,
        blockchainID: ''
    });
    const [snackbarMessage, setSnackbarMessage] = useState<string>("");
    const [showSnackbar, setShowSnackbar] = useState<boolean>(false);

    const [blockchains, setBlockchains] = useState<{[key: string]: { name: string }}>({});

    const [formErrors, setFormErrors] = useState({
        name: '',
        address: '',
        abi: '',
        decimal: '',
        blockchainID: ''
    });

    const resetFormData = () => {
        setSelectedToken({
            id:'',
            name: '',
            address: '',
            abi: '',
            decimal:18,
            blockchainID: ''
        });
    };
    // Функция очистки ошибок
    const resetFormErrors = () => {
        const nullForm = {
            name: '',
            address: '',
            abi: '',
            decimal: '',
            blockchainID: ''
        }
        setFormErrors(nullForm);
        return nullForm;
    };

    const handlePageChange = (newPage:number) => {
        setPage(newPage);
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) => {
        const { name, value, type } = e.target;
        setSelectedToken({
            ...selectedToken,
            [name]: value,
        });
    };

    const fetchTokens = async () => {
        setIsLoadingQuery(true);
        setTokens([]);
        try {
            const token = localStorage.getItem('authToken');
            const bodyContent = JSON.stringify({
                page,
                limit,
                blockchainID: selectedBlockchain,
                search: searchQuery,
            });

            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/admin/token/get-all-tokens`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                body: bodyContent,
            });

            if (!response.ok) {
                throw new Error('Failed to fetch tokens');
            }

            const data = await response.json();

            setTokens(data.tokens);
            // Assuming the response includes pagination info, e.g., totalPages
            setTotalPages(data.totalPages);
            setTotalTokens(data.totalTokens)
        } catch (error) {
            console.error(error);
            setSnackbarMessage("Error fetching tokens");
            setShowSnackbar(true);
        } finally {
            setIsLoadingQuery(false);
        }
    };


    // Update useEffect to refetch tokens when page, limit, selectedBlockchain, or searchQuery changes
    useEffect(() => {
        fetchTokens();
    }, [page, limit, selectedBlockchain, searchQuery]);



    // Функция для загрузки блокчейнов
    const fetchBlockchains = async () => {
        setIsLoading(true);
        try {
            const token = localStorage.getItem('authToken'); // Предполагается, что токен сохранен в localStorage
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/admin/bcs/get-all-blockchains`, { // Убедитесь, что URL соответствует вашей конфигурации
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });
            if (!response.ok) {
                throw new Error('Failed to fetch blockchains');
            }
            const data = await response.json();
            // Преобразуем массив блокчейнов в объект для быстрого доступа

            const blockchainsMap = data.blockChains.reduce((acc: {[key: string]: { name: string }}, blockchain: { id: string; name: string }) => {
                acc[blockchain.id] = { name: blockchain.name };
                return acc;
            }, {});
            setBlockchains(blockchainsMap);

        } catch (error) {
            console.error(error);
            setSnackbarMessage("Error fetching blockchains");
            setShowSnackbar(true);
        } finally {
            setIsLoading(false);
        }
    };

    // Функции для открытия и закрытия модальных окон
    const handleOpenAddModal = () => {
        //setSelectedToken(null); // Сброс выбранного токена при добавлении нового
        resetFormErrors()
        resetFormData()
        setIsModalOpen(true);
    };



    const handleOpenDeleteModal = (rowID: number) => {
        const token = tokens[rowID]
        setSelectedToken({
            id: token._id,
            name: token.name,
            address: token.address,
            abi: token.abi,
            decimal: token.decimal,
            blockchainID: token.blockchainID._id,
        })
        setIsDeleteModalOpen(true);
    };


    const validateFormData = () => {
        let isValid = true;
        const errors = {
            name: '',
            address: '',
            abi: '',
            decimal: '',
            blockchainID: '',
        };

        // Проверка имени
        if (!selectedToken.name || selectedToken.name.length < 3) {
            errors.name = 'Name must be at least 3 characters long.';
            isValid = false;
        }

        // Создаем экземпляр Web3
        const web3 = new Web3();
        // Проверка адреса с использованием web3
        if (!selectedToken.address || !web3.utils.isAddress(selectedToken.address)) {
            errors.address = 'Address must be a valid Ethereum address.';
            isValid = false;
        }

        // Проверка ABI (проверка, что строка преобразуется в JSON)
        try {
            JSON.parse(selectedToken.abi);
        } catch (e) {
            errors.abi = 'ABI must be a valid JSON string.';
            isValid = false;
        }

        // Проверка decimal
        if (!(selectedToken.decimal >= 0)) {
            errors.decimal = 'Decimal must be a number greater than or equal to 0.';
            isValid = false;
        }

        // Проверка blockchainID
        if (!selectedToken.blockchainID) {
            errors.blockchainID = 'Blockchain ID is required.';
            isValid = false;
        }

        setFormErrors(errors);
        return isValid;
    };


    const handleAddToken = async () => {
        if (!validateFormData()) {
            console.error("Validation failed.");
            return;
        }

        const endpoint = selectedToken.id==='' ? `/api/admin/token/add-token` : `/api/admin/token/edit-token`;

        setIsLoadingQuery(true)
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}${endpoint}`, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`, // Предполагается, что токен сохранен в localStorage
                },
                body: JSON.stringify(selectedToken),
            });

            if (!response.ok) {
                throw new Error('Failed to process token');
            }

            const responseData = await response.json();

            setSnackbarMessage(`Token ${selectedToken.id ? "updated" : "added"} successfully`);
            setShowSnackbar(true);
            fetchTokens(); // Обновление списка токенов после добавления/редактирования
            setIsModalOpen(false); // Закрыть модальное окно
            resetFormData(); // Сброс формы
        } catch (error) {
            console.error("Error processing token:", error);
            setSnackbarMessage("Error processing token");
            setShowSnackbar(true);
            setIsLoadingQuery(false)
        } finally {
            setIsModalOpen(false);
        }
    };


    // Функция для удаления токена
    const handleDeleteToken = async () => {
        if (!selectedToken.id) return; // Убедитесь, что ID токена доступен
        setIsLoadingQuery(true);
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/admin/token/delete-token`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
                },
                body: JSON.stringify({ id: selectedToken.id }),
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Failed to delete token');
            }

            const data = await response.json();
            setSnackbarMessage(data.message || "Token deleted successfully");
            setShowSnackbar(true);
            setIsDeleteModalOpen(false);
            fetchTokens(); // Обновление списка токенов после удаления
        } catch (error) {
            console.error(error);
            setIsDeleteModalOpen(false);
            setIsLoadingQuery(false);
        } finally {

            resetFormData(); // Сброс выбранного токена после попытки удаления
        }
    };

    useEffect(() => {
        fetchTokens();
        fetchBlockchains();
    }, []);

    const blockchainOptions = Object.entries(blockchains).map(([id, { name }]) => ({
        id,
        label: name,
    }));


    const handleEditToken = (rowID: number) => {
        const token = tokens[rowID]
        setSelectedToken({
            id: token._id,
            name: token.name,
            address: token.address,
            abi: token.abi,
            decimal: token.decimal,
            blockchainID: token.blockchainID._id,
        })
        setIsModalOpen(true)
    }


    const rowMenu = (rowID: number): JSX.Element | null => {
        if (rowID < 0) return null
        return (
            <React.Fragment>
                <Dropdown>
                    <MenuButton
                        slots={{ root: IconButton }}
                        slotProps={{ root: { variant: 'plain', color: 'neutral', size: 'sm' } }}
                        disabled={isLoading}
                    >
                        <MoreHorizRoundedIcon />
                    </MenuButton>
                    <Menu size="sm" sx={{ minWidth: 140 }}>
                        <MenuItem
                            color="primary"

                        >
                            <InfoIcon/><Typography>Info</Typography>
                        </MenuItem>
                        <MenuItem
                            color="success"
                            onClick={()=>{handleEditToken(rowID)}}
                        >
                            <EditIcon/><Typography>Edit</Typography>
                        </MenuItem>

                        <Divider />
                        <MenuItem
                            color="danger"
                            onClick={()=>{
                                handleOpenDeleteModal(rowID);
                            }}
                        >
                            <DeleteForeverIcon/><Typography>Delete</Typography>
                        </MenuItem>
                    </Menu>
                </Dropdown>
            </React.Fragment>
        );
    }

    useEffect(() => {
        if (searchPreQuery.length <=0) {
            setSearchQuery(searchPreQuery)
        }
    }, [searchPreQuery]);

    if (isLoading){
        return <LoadingSpinner/>
    }

    return (
        <>
            <Box sx={{display: 'flex', mb: 1, gap: 1, flexDirection: { xs: 'column', sm: 'row' }, alignItems: { xs: 'start', sm: 'center' }, flexWrap: 'wrap', justifyContent: 'space-between',}}>
                <Typography level="h2" component="h1">
                    Tokens List
                </Typography>

                <Box>
                    <Button
                        color="primary"
                        size="sm"
                        startDecorator={<AddIcon />}
                        onClick={handleOpenAddModal}
                    >
                        Add new token
                    </Button>


                </Box>
            </Box>

            <Box className="SearchAndFilters-tabletUp" sx={{borderRadius: 'sm', py: 2, display: { sm: 'flex' }, flexWrap: 'wrap', gap: 1.5, '& > *': {minWidth: { xs: '120px', md: '160px' },},}}>
                <FormControl sx={{ flex: 1 }} size="sm">
                    <FormLabel>Search for tokens</FormLabel>
                    <Input
                        size="sm"
                        placeholder=""
                        startDecorator={<SearchIcon />}
                        disabled={isLoadingQuery}
                        value={searchPreQuery}
                        endDecorator={
                            searchPreQuery.length > 0 && (
                                searchQuery === searchPreQuery ? (
                                    <Button
                                        startDecorator={<SearchOffIcon/>}
                                        onClick={()=>{
                                            setSearchPreQuery('')
                                        }}
                                    >
                                        Clean
                                    </Button>
                                    ): (
                                    <Button
                                        startDecorator={<SearchIcon/>}
                                        onClick={()=>{
                                            setSearchQuery(searchPreQuery)
                                        }}
                                    >
                                        Search
                                    </Button>

                                )

                            )
                        }
                        onChange={(event)=>{
                            setSearchPreQuery(event.target.value)
                        }}
                    />
                </FormControl>
                <FormControl size="sm">
                    <FormLabel>Blockchain</FormLabel>
                    <Select
                        size="sm"
                        disabled={isLoadingQuery}
                        value={selectedBlockchain}
                        onChange={(event,value) => {
                            if (value !== null) setSelectedBlockchain(value)
                        }}
                    >
                        <Option value="">All</Option>
                        {Object.entries(blockchains).length > 0 && Object.entries(blockchains).map(([id, { name }]) => (
                            <Option key={id} value={id}>{name}</Option>
                        ))}
                    </Select>
                </FormControl>
                <FormControl size="sm">
                    <FormLabel>Row Limit</FormLabel>
                    <Select
                        size="sm"
                        disabled={isLoadingQuery}
                        defaultValue={limit}
                        onChange={(event,value)=>{
                            if (value) setLimit(value)
                        }}
                    >

                        <Option value={25}>25</Option>
                        <Option value={50}>50</Option>
                        <Option value={100}>100</Option>
                    </Select>
                </FormControl>
            </Box>

            <Sheet className="OrderTableContainer" variant="outlined" sx={{width: '100%', borderRadius: 'sm', flexShrink: 1, overflow: 'auto', minHeight: 0,}}>

                <Table
                    aria-labelledby="BlockChainTable"
                    stickyHeader
                    stickyFooter
                    hoverRow
                    sx={{
                        '--Table-headerUnderlineThickness': '1px',
                        '--TableRow-hoverBackground': 'var(--joy-palette-background-level1)',
                        '--TableCell-paddingY': '4px',
                        '--TableCell-paddingX': '8px',
                    }}
                >
                    <thead>
                    <tr>
                        <th style={{ width: "30%", padding: '12px 6px' }}>Name</th>
                        <th style={{ width: "20%",  padding: '12px 6px' }}>Blockchain</th>
                        <th style={{ width: "30%",  padding: '12px 6px' }}>Address</th>
                        <th style={{ width: "10%",  padding: '12px 6px' }}>Decimal</th>
                        <th style={{ width: "10%",  padding: '12px 6px' }}>Action</th>
                    </tr>
                    </thead>

                    <tbody>
                    {isLoadingQuery ? (
                        <tr >
                            <td colSpan={5}>
                                <LinearProgress
                                    variant="plain"
                                    size="sm"
                                    sx={{
                                        width: '100%', // Ширина равна 100% родительского элемента
                                        position: 'static', // Абсолютное позиционирование
                                        left: 0, // Позиционируем в левой части CardOverflow
                                        m: 0, // Убираем внешние отступы
                                        borderRadius: 0, // Убираем скругление
                                        zIndex:11,
                                    }}
                                />
                            </td>
                        </tr>
                    ) : (
                    tokens.length > 0 ? (
                        tokens.map((token,index) => (
                            <tr key={token._id}>
                                <td>{token.name}</td>
                                <td>{token.blockchainID?.name || 'Unknown Blockchain'}</td>
                                <td>{token.address}</td>
                                <td>{token.decimal}</td>
                                <td>
                                    {rowMenu(index)}
                                </td>
                            </tr>
                        ))
                    ): (
                        <tr>
                            <td colSpan={5} align={'center'}>
                                No tokens available
                            </td>
                        </tr>
                    )
                    )}
                    </tbody>
                    {!isLoadingQuery && tokens.length > 0 && (
                        <tfoot>
                        <tr>
                            <td colSpan={5} style={{textAlign:'right' }}>{i18n._({ id: "TokensPage.showiStartToEndusOfT", message: "showing {startUserIndex} to {endUserIndex} of {totalUsers}", values: { startUserIndex:startTokenIndex, endUserIndex:endTokenIndex, totalUsers:totalTokens } })}</td>
                        </tr>
                        </tfoot>
                    )}
                </Table>
            </Sheet>
            {tokens.length > 0 && (
                <Box className="Pagination-laptopUp" sx={{pt: 2, gap: 1, [`& .${iconButtonClasses.root}`]: { borderRadius: '50%' }, display: {xs: 'none', md: 'flex',},}}>
                    <Button
                        size="sm"
                        variant="outlined"
                        color="neutral"
                        startDecorator={<KeyboardArrowLeftIcon />}
                        onClick={() => handlePageChange(page - 1)}
                        disabled={page === 1 || isLoadingQuery}
                    >
                        Previous
                    </Button>

                    <Box sx={{ flex: 1 }} />
                    {[...Array(totalPages)].map((_, idx) => (
                        <IconButton
                            key={idx + 1}
                            onClick={() => handlePageChange(idx + 1)}
                            size="sm"
                            variant={idx + 1 === page ? 'plain' : 'outlined'}
                            color="neutral"
                            disabled={isLoadingQuery}
                        >
                            {idx + 1}
                        </IconButton>
                    ))}
                    <Box sx={{ flex: 1 }} />

                    <Button
                        onClick={() => handlePageChange(page + 1)}
                        disabled={page === totalPages || isLoadingQuery}
                        size="sm"
                        variant="outlined"
                        color="neutral"
                        endDecorator={<KeyboardArrowRightIcon />}

                    >
                        Next
                    </Button>

                </Box>
            )}

            {/* Модальное окно для добавления/редактирования токена */}
            <Modal open={isModalOpen} onClose={() => setIsModalOpen(false)}>
                <ModalDialog>
                        <DialogTitle>{selectedToken.id === '' ? "Add Token" : "Edit Token"}</DialogTitle>
                        <DialogContent>
                            <Box component="form" sx={{ display: 'flex', flexDirection: 'column', gap: 2, minWidth:400, maxWidth:600 }}>
                                <FormControl error={Boolean(formErrors.name)}>
                                    <FormLabel>Token name</FormLabel>
                                    <Input
                                        name="name"
                                        placeholder="Token name"
                                        value={selectedToken.name}
                                        onChange={handleInputChange}
                                        disabled={isLoadingQuery}
                                    />
                                    {formErrors.name && <FormHelperText><InfoOutlined />{formErrors.name}</FormHelperText>}
                                </FormControl>
                                <FormControl>
                                    <FormLabel>Name blockchain</FormLabel>
                                    <Autocomplete
                                        options={blockchainOptions}
                                        getOptionLabel={(option) => option.label}
                                        value={blockchainOptions.find(option => option.id === selectedToken?.blockchainID) || null}
                                        onChange={(event, newValue) => {
                                            setSelectedToken({
                                                ...selectedToken,
                                                blockchainID: newValue?.id || ''
                                            });
                                        }}
                                        disabled={isLoadingQuery}
                                    />
                                </FormControl>
                                <FormControl error={Boolean(formErrors.address)}>
                                    <FormLabel>Address</FormLabel>
                                    <Input
                                        name="address"
                                        placeholder="Address"
                                        value={selectedToken.address}
                                        onChange={handleInputChange}
                                        disabled={isLoadingQuery}
                                    />
                                    {formErrors.address && <FormHelperText><InfoOutlined />{formErrors.address}</FormHelperText>}
                                </FormControl>
                                <FormControl error={Boolean(formErrors.abi)}>
                                    <FormLabel>ABI</FormLabel>

                                    <Textarea
                                        name="abi"
                                        placeholder="ABI"
                                        value={selectedToken.abi}
                                        onChange={handleInputChange}
                                        minRows={5}
                                        maxRows={7}
                                        disabled={isLoadingQuery}
                                    />
                                    {formErrors.abi && <FormHelperText><InfoOutlined />{formErrors.abi}</FormHelperText>}
                                </FormControl>
                                <FormControl error={Boolean(formErrors.decimal)}>
                                    <FormLabel>Decimal</FormLabel>
                                    <Input
                                        name="decimal"
                                        placeholder="Token Decimal"
                                        type="number"
                                        value={String(selectedToken.decimal)}
                                        onChange={handleInputChange}
                                        disabled={isLoadingQuery}
                                    />
                                    {formErrors.decimal && <FormHelperText><InfoOutlined />{formErrors.decimal}</FormHelperText>}
                                </FormControl>

                            </Box>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                type="submit"
                                onClick={handleAddToken}
                                loading={isLoadingQuery}
                            >
                                Save
                            </Button>
                            <Button
                                onClick={() => setIsModalOpen(false)}
                                disabled={isLoadingQuery}
                            >
                                Cancel
                            </Button>
                        </DialogActions>
                </ModalDialog>
            </Modal>

            {/* Модальное окно для подтверждения удаления */}
            <Modal open={isDeleteModalOpen} onClose={() => setIsDeleteModalOpen(false)}>
                <ModalDialog>
                    <DialogTitle>Delete Token</DialogTitle>
                    <DialogContent>
                        <p>Are you sure you want to delete this token?</p>
                        <p>Name : {selectedToken.name}</p>
                        <p>Address: {selectedToken.address}</p>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={handleDeleteToken}
                            loading={isLoadingQuery}
                        >
                            Delete
                        </Button>
                        <Button
                            onClick={() => setIsDeleteModalOpen(false)}
                            disabled={isLoadingQuery}
                        >
                            Cancel
                        </Button>
                    </DialogActions>
                </ModalDialog>
            </Modal>
            <CustomSnackbar
                open={showSnackbar}
                message={snackbarMessage}
                handleClose={() => setShowSnackbar(false)}
            />
        </>
    );
};

export default TokensPage;
