// Dashboard.tsx
import React, {useState, useEffect, JSX} from 'react';
import Box from '@mui/joy/Box';
import Typography from '@mui/joy/Typography';
import Sheet from '@mui/joy/Sheet';
import {Trans} from "@lingui/macro";
import Button from "@mui/joy/Button";
import {Table} from "@mui/joy";
import {Modal,  ModalDialog, ModalClose, DialogTitle, DialogContent, DialogActions } from "@mui/joy";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import Input from "@mui/joy/Input";
import Switch from "@mui/joy/Switch";
import FormHelperText from "@mui/joy/FormHelperText";
import Divider from "@mui/joy/Divider";
import Dropdown from "@mui/joy/Dropdown";
import MenuButton from "@mui/joy/MenuButton";
import IconButton from "@mui/joy/IconButton";
import MoreHorizRoundedIcon from "@mui/icons-material/MoreHorizRounded";
import Menu from "@mui/joy/Menu";
import MenuItem from "@mui/joy/MenuItem";
import InfoIcon from "@mui/icons-material/Info";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import PersonIcon from "@mui/icons-material/Person";
import PersonOffIcon from "@mui/icons-material/PersonOff";
import RoomPreferencesIcon from "@mui/icons-material/RoomPreferences";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import EditIcon from '@mui/icons-material/Edit';
import InfoOutlined from '@mui/icons-material/InfoOutlined';
import DeleteForever from '@mui/icons-material/DeleteForever';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import LoadingSpinner from "../../../../components/UI/LoadingSpinner";
import CustomSnackbar from "../../../../components/UI/CustomSnackbar";

interface BlockChain {
    id: string;
    name: string;
    endPointUrl: string;
    scannerUrl: string;
    nativeToken: string;
    nativeTokenDecimal: number;
    status: boolean;
    createdAt: string;
    updatedAt: string;
}

const BlockChainsPage = () => {

    const [blockChains, setBlockChains] = useState<BlockChain[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const [isLoadingData, setIsLoadingData] = useState<boolean>(false);

    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);

    const [isSnackbar, setIsSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage]= useState('');

    const [formData, setFormData] = useState({
        id: '',
        name: '',
        endPointUrl: '',
        scannerUrl: '',
        nativeToken: '',
        nativeTokenDecimal: 18,
        status: false,
    });
    const [formErrors, setFormErrors] = useState({
        name: '',
        endPointUrl: '',
        scannerUrl: '',
        nativeToken: '',
        nativeTokenDecimal: '',
    });

    const [deleteData, setDeleteData] = useState({
        id:'',
        name:''
    })


    const resetFormData = () => {
        setFormData({
            id: '',
            name: '',
            endPointUrl: '',
            scannerUrl: '',
            nativeToken: '',
            nativeTokenDecimal: 18,
            status: false,
        });
    };
    // Функция очистки ошибок
    const resetFormErrors = () => {
        const nullForm = {
            name: '',
            endPointUrl: '',
            scannerUrl: '',
            nativeToken: '',
            nativeTokenDecimal: '',
        }
        setFormErrors(nullForm);
        return nullForm;
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value, type, checked } = e.target;
        setFormData({
            ...formData,
            [name]: type === 'checkbox' ? checked : value,
        });
    };

    // Функция валидации данных
    const validateForm = () => {
        let isValid = true;
        let errors = resetFormErrors();

        if (formData.name.length < 3) {
            errors.name = 'Name must be at least 3 characters';
            isValid = false;
        }
        if (!/^https?:\/\/.+/i.test(formData.endPointUrl)) {
            errors.endPointUrl = 'Endpoint URL must be a valid URL';
            isValid = false;
        }
        if (!/^https?:\/\/.+/i.test(formData.scannerUrl)) {
            errors.scannerUrl = 'Scanner URL must be a valid URL';
            isValid = false;
        }
        if (formData.nativeToken.length < 2) {
            errors.nativeToken = 'Native token must be at least 2 characters';
            isValid = false;
        }
        if (formData.nativeTokenDecimal < 0 || !Number.isInteger(formData.nativeTokenDecimal)) {
            errors.nativeTokenDecimal = 'Native token decimal must be an integer >= 0';
            isValid = false;
        }

        setFormErrors(errors);
        return isValid;
    };

    const handleSubmit = async () => {
        resetFormErrors()
        if (!validateForm()) {
            return; // Прекратить выполнение, если данные невалидны
        }

        setIsLoading(true); // Начало загрузки
        try {
            const token = localStorage.getItem('authToken'); // Получение токена из localStorage
            let targetURL = null;
            if (formData.id === ''){
                targetURL = `${process.env.REACT_APP_API_URL}/api/admin/bcs/add-blockchain`;
            } else {
                targetURL = `${process.env.REACT_APP_API_URL}/api/admin/bcs/edit-blockchain`;
            }
            const response = await fetch(targetURL, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify(formData),
            });

            if (!response.ok) {
                throw new Error('Failed to add blockchain');
            }

            if (formData.id === ''){
                let data = await response.json();
                data.blockChain.id = data.blockChain._id;
                setBlockChains([...blockChains, data.blockChain]);
                setSnackbarMessage('Blockchain added')
                setIsSnackbar(true)
            } else {
                fetchBlockChains()
            }
            setIsModalOpen(false); // Закрытие модального окна после успешного добавления
        } catch (error) {
            console.error('Error adding blockchain:', error);
        } finally {
            setIsLoading(false); // Завершение загрузки
            resetFormData()
        }
    };


    const fetchBlockChains = async () => {
        setIsLoadingData(true);
        try {
            const token = localStorage.getItem('authToken'); // Предполагается, что токен сохранен в localStorage
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/admin/bcs/get-all-blockchains`, { // Убедитесь, что URL соответствует вашей конфигурации
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });

            if (!response.ok) {
                throw new Error('Failed to fetch blockchains');
            }
            console.log("data",response)
            const data = await response.json();
            setBlockChains(data.blockChains);
        } catch (error) {
            console.error('Error fetching blockchains:', error);
        } finally {
            setIsLoadingData(false);
        }
    };

    useEffect(() => {
        fetchBlockChains();
    }, []);

    const openEditModal = (rowID:number) => {
        const formData = blockChains[rowID]
        setFormData({
            id: formData.id,
            name: formData.name,
            endPointUrl: formData.endPointUrl,
            scannerUrl: formData.scannerUrl,
            nativeToken: formData.nativeToken,
            nativeTokenDecimal: formData.nativeTokenDecimal,
            status: formData.status,
        })
        setIsModalOpen(true)
    }

    const openDeleteModal = (rowID:number)=>{
        const chainData = blockChains[rowID];
        console.log("rowID",rowID)
        console.log("blockChains",blockChains)
        setDeleteData({
            id:chainData.id,
            name: chainData.name
        })
        setIsDeleteModalOpen(true);
    }

    const handleDelete = async () => {
        if (!deleteData.id) return; // Проверка на наличие ID для удаления
        setIsLoading(true);

        try {
            const token = localStorage.getItem('authToken'); // Получение токена из localStorage
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/admin/bcs/delete-blockchain`, {
                method: 'DELETE', // Использование метода DELETE
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify({ id: deleteData.id }), // Передача ID блокчейна в теле запроса
            });

            if (!response.ok) {
                throw new Error('Failed to delete blockchain');
            }

            await response.json(); // Ожидание и обработка ответа от сервера
            setIsDeleteModalOpen(false); // Закрытие модального окна после успешного удаления
            fetchBlockChains(); // Обновление списка блокчейнов после удаления
        } catch (error) {
            if (error instanceof Error) {
                console.error('Error deleting blockchain:', error.message);
            } else {
                console.error('Error deleting blockchain:', error);
            }
        } finally {
            setIsLoading(false);
        }
    };

    const rowMenu = (rowID: number): JSX.Element | null => {
        if (rowID < 0) return null
        return (
            <React.Fragment>
                <Dropdown>
                    <MenuButton
                        slots={{ root: IconButton }}
                        slotProps={{ root: { variant: 'plain', color: 'neutral', size: 'sm' } }}
                        disabled={isLoading}
                    >
                        <MoreHorizRoundedIcon />
                    </MenuButton>
                    <Menu size="sm" sx={{ minWidth: 140 }}>
                        <MenuItem
                            color="primary"

                        >
                            <InfoIcon/><Typography>Info</Typography>
                        </MenuItem>
                        <MenuItem
                            color="success"
                            onClick={()=>{openEditModal(rowID)}}
                        >
                            <EditIcon/><Typography>Edit</Typography>
                        </MenuItem>

                        <Divider />
                        <MenuItem
                            color="danger"
                            onClick={()=>{
                                openDeleteModal(rowID)
                            }}
                        >
                            <DeleteForeverIcon/><Typography>Delete</Typography>
                        </MenuItem>
                    </Menu>
                </Dropdown>
            </React.Fragment>
        );
    }

    if (isLoadingData){
        return <LoadingSpinner/>
    }
    return (
        <>
            <Box sx={{display: 'flex', mb: 1, gap: 1, flexDirection: { xs: 'column', sm: 'row' }, alignItems: { xs: 'start', sm: 'center' }, flexWrap: 'wrap', justifyContent: 'space-between',}}>
                <Typography level="h2" component="h1">
                    BlockChains List
                </Typography>

                <Box>
                    <Button
                        color="primary"
                        size="sm"
                        onClick={() => {
                            resetFormData();
                            setIsModalOpen(true);
                        }}
                    >
                        Add new blockchain
                    </Button>


                </Box>
            </Box>



            <Sheet className="OrderTableContainer" variant="outlined" sx={{width: '100%', borderRadius: 'sm', flexShrink: 1, overflow: 'auto', minHeight: 0,}}>

                <Table
                    aria-labelledby="BlockChainTable"
                    stickyHeader
                    stickyFooter
                    hoverRow
                    sx={{
                        '--Table-headerUnderlineThickness': '1px',
                        '--TableRow-hoverBackground': 'var(--joy-palette-background-level1)',
                        '--TableCell-paddingY': '4px',
                        '--TableCell-paddingX': '8px',
                    }}
                >
                    <thead>
                    <tr>
                        <th style={{ width: "50%", padding: '12px 6px' }}>Name</th>
                        <th style={{ width: "20%",  padding: '12px 6px' }}>Native token</th>
                        <th style={{ width: "20%",  padding: '12px 6px' }}>Status</th>
                        <th style={{ width: "20%",  padding: '12px 6px' }}>Action</th>
                    </tr>
                    </thead>

                    <tbody>
                    {blockChains.map((bc,index) => (
                        <tr key={bc.id}>
                            <td>{bc.name}</td>
                            <td>{bc.nativeToken}</td>
                            <td>{bc.status ? "Active" : "Inactive"}</td>
                            <td>{rowMenu(index)}</td>
                        </tr>
                    ))}
                    </tbody>
                </Table>
            </Sheet>

            {/* Modal Add Blockchain */}
            <Modal
                open={isModalOpen}
                onClose={() => setIsModalOpen(false)}
            >
                <ModalDialog size="md">
                    <ModalClose/>
                    <DialogTitle>Add new Blockchain</DialogTitle>
                    <DialogContent>
                        <Box component="form" sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                            <FormControl error={Boolean(formErrors.name)}>
                                <FormLabel>Name blockchain</FormLabel>
                                <Input name="name" placeholder="Name" value={formData.name} onChange={handleInputChange} />
                                {formErrors.name && <FormHelperText><InfoOutlined />{formErrors.name}</FormHelperText>}
                            </FormControl>

                            <FormControl error={Boolean(formErrors.endPointUrl)}>
                                <FormLabel>EndPoint url</FormLabel>
                                <Input name="endPointUrl" placeholder="Endpoint URL" value={formData.endPointUrl} onChange={handleInputChange} />
                                {formErrors.endPointUrl && <FormHelperText><InfoOutlined />{formErrors.endPointUrl}</FormHelperText>}
                            </FormControl>

                            <FormControl error={Boolean(formErrors.scannerUrl)}>
                                <FormLabel>Scanner Url</FormLabel>
                                <Input name="scannerUrl" placeholder="Scanner URL" value={formData.scannerUrl} onChange={handleInputChange} />
                                {formErrors.scannerUrl && <FormHelperText><InfoOutlined />{formErrors.scannerUrl}</FormHelperText>}
                            </FormControl>

                            <FormControl error={Boolean(formErrors.nativeToken)}>
                                <FormLabel>Native token name</FormLabel>
                                <Input name="nativeToken" placeholder="Native Token" value={formData.nativeToken} onChange={handleInputChange} />
                                {formErrors.nativeToken && <FormHelperText><InfoOutlined />{formErrors.nativeToken}</FormHelperText>}
                            </FormControl>

                            <FormControl error={Boolean(formErrors.nativeTokenDecimal)}>
                                <FormLabel>Native token Decimal</FormLabel>
                                <Input name="nativeTokenDecimal" placeholder="Native Token Decimal" type="number" value={String(formData.nativeTokenDecimal)} onChange={handleInputChange} />
                                {formErrors.nativeTokenDecimal && <FormHelperText><InfoOutlined />{formErrors.nativeTokenDecimal}</FormHelperText>}
                            </FormControl>

                            <Divider/>
                            <FormControl
                                orientation="horizontal"
                                sx={{ width: 300, justifyContent: 'space-between' }}
                            >
                                <div>
                                    <FormLabel>Status</FormLabel>
                                    <FormHelperText sx={{ mt: 0 }}>Active or not</FormHelperText>
                                </div>
                                <Switch
                                    checked={formData.status}
                                    onChange={(event) => setFormData({...formData, status: event.target.checked })}
                                />
                            </FormControl>
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={handleSubmit}
                            color="success"
                            loading={isLoading}
                        >
                            {formData.id==='' ? (
                                <>Add Blockchain</>
                            ):(
                                <>Save</>
                            )}

                        </Button>
                        <Button
                            onClick={() => {
                                resetFormErrors()
                                setIsModalOpen(false)
                            }}
                            variant="outlined"
                            disabled={isLoading}
                        >
                            Cancel
                        </Button>
                    </DialogActions>
                </ModalDialog>
            </Modal>

            {/*Delete Modal*/}
            <Modal open={isDeleteModalOpen} onClose={() => setIsDeleteModalOpen(false)}>
                <ModalDialog variant="outlined" role="alertdialog">
                    <DialogTitle>
                        <WarningRoundedIcon />
                        DELETION
                    </DialogTitle>
                    <Divider />
                    <DialogContent>
                        <p>Do you really want to delete the blockchain?</p>
                        <p>id: {deleteData.id}</p>
                        <p>name: {deleteData.name}</p>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            variant="solid"
                            color="danger"
                            onClick={() => handleDelete()}
                            loading={isLoading}
                        >
                            Delete
                        </Button>
                        <Button
                            variant="plain"
                            color="neutral"
                            onClick={() => setIsDeleteModalOpen(false)}
                            disabled={isLoading}
                        >
                            Cancel
                        </Button>
                    </DialogActions>
                </ModalDialog>
            </Modal>

            <CustomSnackbar
                open={isSnackbar}
                message={snackbarMessage}
                handleClose={()=>{setIsSnackbar(false)}}
            />
        </>
    );
};

export default BlockChainsPage;



