// src/context/GlobalContext.tsx
import React, { createContext, useContext, useState, useEffect } from 'react';

import Modal from '@mui/joy/Modal';
import ModalClose from '@mui/joy/ModalClose';
import ModalDialog from '@mui/joy/ModalDialog';
import Typography from "@mui/joy/Typography";
import DialogTitle from '@mui/joy/DialogTitle';
import DialogContent from '@mui/joy/DialogContent';

interface User {
    login: string;
    email: string;
    name: string;
    role: string;
    exp: number;
    email_confirmed: boolean;
    blocked: boolean;
    profile_permissions: ProfilePermissions;
}

interface ProfilePermissions {
    change_name: boolean;
    change_password: boolean;
    change_email: boolean;
    change_login: boolean;
}



export interface GlobalSettingsInterface {
    name: string;
    version: string;
    user_registration:boolean;
    user_login_email:boolean;
    user_login_login:boolean;
    user_registration_login:boolean;
    user_password_recovery:boolean;
    defaultLocale:string;
    localization:boolean;
}

interface GlobalContextType {
    user: User | null;
    login: (token: string, userData: User) => void;
    logout: () => void;
    changeUserInfo: (userData: User) => void;
    globalSettings: GlobalSettingsInterface | null;
    isAuthChecked: boolean;
    isGSChecked: boolean;
    globalChangeLocale: (locale: string) => void;
    globalLocale:string;
    activateUser: () => void;
}

const GlobalContext = createContext<GlobalContextType | null>(null);

export const useAuth = () => {
    const context = useContext(GlobalContext);
    if (!context) {
        throw new Error('BCSpider System Error: useAuth must be used within AuthProvider');
    }
    return context;
};

export const GlobalProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [user, setUser] = useState<User | null>(null);
    const [isAuthChecked, setIsAuthChecked] = useState(false);
    const [isGSChecked, setIsGSChecked] = useState(false);
    const [globalSettings, setGlobalSettings] = useState<GlobalSettingsInterface | null>(null);
    const [globalLocale, setGlobalLocale] = useState('')

    const [isOpenModalLogout, setIsOpenModalLogout] = useState(false)

    useEffect(() => {
        const storedToken = localStorage.getItem('authToken');

        if (storedToken) {
            fetch(`${process.env.REACT_APP_API_URL}/api/auth/verify-token`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ token: storedToken })
            })
                .then(response => response.json())
                .then(data => {
                    if (data.user) {
                        setUser(data.user);
                    }else {
                        localStorage.removeItem('authToken');
                    }
                    setIsAuthChecked(true);
                })
                .catch(error => {
                    localStorage.removeItem('authToken');
                    setIsAuthChecked(true);
                });
        } else {
            setIsAuthChecked(true);
        }

        // Загрузка глобальных настроек
        const loadGlobalSettings = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/api/global/settings/get`);
                if (!response.ok) {
                    throw new Error('Failed to fetch global settings');
                }
                const settings = await response.json();

                setGlobalSettings(settings);
                setIsGSChecked(true);
            } catch (error) {
                console.error('Error fetching global settings:', error);
                setIsGSChecked(true);
            }
        };

        loadGlobalSettings();

    }, []);


    const globalChangeLocale = (locale: string) => {
        setGlobalLocale(locale)
    };

    const login = (token: string, userData: User) => {
        localStorage.setItem('authToken', token);
        setUser(userData);
    };

    const logout = () => {
        localStorage.removeItem('authToken');
        setUser(null);
    };

    const changeUserInfo = (userData:User) => {
        setUser(userData);
        console.log("changeUserInfo",userData)
    }

    const activateUser = () => {
        if (user) {
            setUser({ ...user, email_confirmed: true });
        }
    };

    useEffect(() => {
        // ... (ваш код до этой строки)

        const checkUserExpiration = () => {
            if (user && user.exp) {
                const currentTime = Math.floor(Date.now() / 1000); // Текущее время в секундах
                if (currentTime > user.exp) {
                    clearInterval(expirationCheckInterval);
                    setIsOpenModalLogout(true);
                    logout()
                }
            }
        };

        const expirationCheckInterval = setInterval(checkUserExpiration, 1000); // Проверка каждые 10 секунд

        // ... (ваш код после этой строки)

        return () => {
            // Очищаем интервал при размонтировании компонента
            clearInterval(expirationCheckInterval);
        };
    }, [user]);

    return (
        <GlobalContext.Provider value={{ user, login, logout,changeUserInfo, isAuthChecked, globalSettings, isGSChecked, globalChangeLocale,globalLocale,activateUser }}>
            {children}

            <Modal
                open={isOpenModalLogout}
                onClose={() => setIsOpenModalLogout(false)}
            >
                <ModalDialog size="md">
                    <ModalClose/>
                    <DialogTitle>Session Expired</DialogTitle>
                    <DialogContent>
                        <Typography>Your session has expired due to inactivity</Typography>
                        <Typography>You have been logged out for security reasons</Typography>
                    </DialogContent>
                </ModalDialog>
            </Modal>
        </GlobalContext.Provider>
    );
};