// Dashboard.tsx
import React, { useState, useEffect } from 'react';
import Box from '@mui/joy/Box';
import { Trans } from "@lingui/macro";


import Button from '@mui/joy/Button';

import Typography from '@mui/joy/Typography';


import Stack from "@mui/joy/Stack";
import Card from "@mui/joy/Card";
import Divider from "@mui/joy/Divider";

import FormLabel from "@mui/joy/FormLabel";
import FormControl from "@mui/joy/FormControl";
import FormHelperText from '@mui/joy/FormHelperText';
import Switch from '@mui/joy/Switch';


import CardOverflow from "@mui/joy/CardOverflow";
import CardActions from "@mui/joy/CardActions";
import LoadingSpinner from "../../../../components/UI/LoadingSpinner";
import CustomAlertSnackbar from "../../../../components/UI/CustomAlertSnackbar";
import CustomSnackbar from "../../../../components/UI/CustomSnackbar";

import Sheet from '@mui/joy/Sheet';


interface AuthSettingsState {
    user_registration: boolean;
    user_login_email: boolean;
    user_login_login: boolean;
    user_registration_login: boolean;
    user_password_recovery: boolean;
    user_registration_email_confirmation: boolean;
}

const AuthSettingsPage = () => {
    const [settings, setSettings] = useState<AuthSettingsState>({
        user_registration: false,
        user_login_email: false,
        user_login_login: false,
        user_registration_login: false,
        user_password_recovery: false,
        user_registration_email_confirmation:false
    });
    const [initialSettings, setInitialSettings] = useState<AuthSettingsState>({
        user_registration: false,
        user_login_email: false,
        user_login_login: false,
        user_registration_login: false,
        user_password_recovery: false,
        user_registration_email_confirmation:false,
    });
    const settingsChanged = JSON.stringify(settings) !== JSON.stringify(initialSettings);


    const [isLoadingData, setIsLoadingData] = useState(true);
    const [isSavingData, setIsSavingData] = useState(false);

    const [isOpenSb, setIsOpenSb] = useState(false);


    const [isAlertOpen, setIsAlertOpen] = useState(false);

    const fetchSettings = async () => {
        try {
            const token = localStorage.getItem('authToken');
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/admin/settings/get-global-settings`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            return data;
        } catch (error) {
            console.error('Error fetching settings:', error);
        }
    };

    useEffect(() => {
        const loadData = async () => {
            setIsLoadingData(true);
            const fetchedSettings = await fetchSettings();
            setIsLoadingData(false);
            if (fetchedSettings) {
                const newSettings = {
                    user_registration: fetchedSettings.user_registration,
                    user_login_email: fetchedSettings.user_login_email,
                    user_login_login: fetchedSettings.user_login_login,
                    user_registration_login: fetchedSettings.user_registration_login,
                    user_password_recovery: fetchedSettings.user_password_recovery,
                    user_registration_email_confirmation: fetchedSettings.user_registration_email_confirmation,
                };
                setSettings(newSettings);
                setInitialSettings(newSettings);
            }
        };
        loadData();
    }, []);



    const handleUserLoginEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!settings.user_login_login && !event.target.checked) {
            setIsAlertOpen(true);
        } else {
            setSettings(prevSettings => ({...prevSettings, user_login_email: event.target.checked}));
        }
    };

    const handleUserLoginLoginChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!settings.user_login_email && !event.target.checked) {
            setIsAlertOpen(true);
        } else {
            setSettings(prevSettings => ({...prevSettings, user_login_login: event.target.checked}));
        }
    };

    const saveSettings = async () => {
        setIsSavingData(true)
        try {

            const token = localStorage.getItem('authToken');
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/admin/settings/putauthsettings`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify(settings)
            });

            if (!response.ok) {
                throw new Error('Failed to save settings');
            }

            const updatedSettings = await response.json();
            setInitialSettings(updatedSettings); // Обновляем начальные настройки после успешного сохранения
            setIsSavingData(false)
            setInitialSettings(settings);
            setIsOpenSb(true)
        } catch (error) {
            console.error('Error saving settings:', error);
            setIsSavingData(false)
        }
    };



    if (isLoadingData){
        return (<LoadingSpinner/>)
    }

    return (
        <>
            <Box
                sx={{
                    display: 'flex',
                    mb: 1,
                    gap: 1,
                    flexDirection: { xs: 'column', sm: 'row' },
                    alignItems: { xs: 'start', sm: 'center' },
                    flexWrap: 'wrap',
                    justifyContent: 'space-between',
                }}
            >
                <Typography level="h2" component="h1">
                    <Trans id="AuthSettingsPage.title">Authentication Settings</Trans>
                </Typography>

            </Box>

            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                }}
            >

                <Box
                    sx={{
                        maxWidth: '600px',

                        flexShrink: 1,
                        overflow: 'auto',
                        minHeight: 0,
                    }}
                >
                    <Card>
                        <Box sx={{ mb: 1 }}>
                            <Typography level="title-md"><Trans id="AuthSettingsPage.card.title">Authentication Settings</Trans></Typography>
                            <Typography level="body-sm">
                                <Trans id="AuthSettingsPage.card.description">Here you can configure the login and user registration settings</Trans>
                            </Typography>
                        </Box>
                        <Divider />
                        <Stack
                            direction="row"
                            spacing={3}
                            sx={{ my: 1 }}
                        >
                            <Stack spacing={2} sx={{ flexGrow: 1 }}>
                                <Stack spacing={1}>
                                    <FormControl
                                        orientation="horizontal"
                                        sx={{  justifyContent: 'space-between' }}
                                    >
                                        <div>
                                            <FormLabel><Trans id="AuthSettingsPage.point.registration.title">Registration</Trans></FormLabel>
                                            <FormHelperText sx={{ mt: 0 }}><Trans id="AuthSettingsPage.point.registration.desc">Availability of self-service user registration</Trans></FormHelperText>
                                        </div>
                                        <Switch
                                            checked={settings.user_registration}
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                                                setSettings(prevSettings => ({...prevSettings, user_registration: event.target.checked}))
                                            }
                                            color={settings.user_registration ? 'success' : 'neutral'}
                                            variant={settings.user_registration ? 'solid' : 'outlined'}
                                            endDecorator={settings.user_registration ? <Trans id="global.on">On</Trans> : <Trans id="global.off">Off</Trans>}
                                            slotProps={{
                                                endDecorator: {
                                                    sx: {
                                                        minWidth: 24,
                                                    },
                                                },
                                            }}
                                            disabled={isSavingData}
                                        />
                                    </FormControl>
                                </Stack>
                                {settings.user_registration && (
                                    <>
                                        <Stack spacing={1}>
                                            <FormControl
                                                orientation="horizontal"
                                                sx={{  justifyContent: 'space-between' }}
                                            >
                                                <div>
                                                    <FormLabel><Trans id="AuthSettingsPage.point.email_confirm.title">Email Confirmation</Trans></FormLabel>
                                                    <FormHelperText sx={{ mt: 0 }}><Trans id="AuthSettingsPage.point.email_confirm.desc">After registration, the user will receive an email with a code to activate their account</Trans></FormHelperText>
                                                </div>
                                                <Switch
                                                    checked={settings.user_registration_email_confirmation}
                                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                                                        setSettings(prevSettings => ({...prevSettings, user_registration_email_confirmation: event.target.checked}))
                                                    }
                                                    color={settings.user_registration_email_confirmation ? 'success' : 'neutral'}
                                                    variant={settings.user_registration_email_confirmation ? 'solid' : 'outlined'}
                                                    endDecorator={settings.user_registration_email_confirmation ? <Trans id="global.on">On</Trans> : <Trans id="global.off">Off</Trans>}
                                                    slotProps={{
                                                        endDecorator: {
                                                            sx: {
                                                                minWidth: 24,
                                                            },
                                                        },
                                                    }}
                                                    disabled={isSavingData}
                                                />
                                            </FormControl>
                                        </Stack>


                                        <Stack spacing={1}>
                                            <FormControl
                                                orientation="horizontal"
                                                sx={{  justifyContent: 'space-between' }}
                                            >
                                                <div>
                                                    <FormLabel><Trans id="AuthSettingsPage.point.password_recovery.title">Password Recovery</Trans></FormLabel>
                                                    <FormHelperText sx={{ mt: 0 }}><Trans id="AuthSettingsPage.point.password_recovery.desc">Allow users to reset their password (notification via email)</Trans></FormHelperText>
                                                </div>
                                                <Switch
                                                    checked={settings.user_password_recovery}
                                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                                                        setSettings(prevSettings => ({...prevSettings, user_password_recovery: event.target.checked}))
                                                    }
                                                    color={settings.user_password_recovery ? 'success' : 'neutral'}
                                                    variant={settings.user_password_recovery ? 'solid' : 'outlined'}
                                                    endDecorator={settings.user_password_recovery ? <Trans id="global.on">On</Trans> : <Trans id="global.off">Off</Trans>}
                                                    slotProps={{
                                                        endDecorator: {
                                                            sx: {
                                                                minWidth: 24,
                                                            },
                                                        },
                                                    }}
                                                    disabled={isSavingData}
                                                />
                                            </FormControl>
                                        </Stack>
                                        <Stack spacing={1}>
                                            <FormControl
                                                orientation="horizontal"
                                                sx={{  justifyContent: 'space-between' }}
                                            >
                                                <div>
                                                    <FormLabel><Trans id="AuthSettingsPage.point.singUpWithLogin.title">SingUP with Login</Trans></FormLabel>
                                                    <FormHelperText sx={{ mt: 0 }}><Trans id="AuthSettingsPage.point.regWithLogin.desc">During registration, the user must choose a login</Trans></FormHelperText>
                                                </div>
                                                <Switch
                                                    checked={settings.user_registration_login}
                                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                                                        setSettings(prevSettings => ({...prevSettings, user_registration_login: event.target.checked}))
                                                    }
                                                    color={settings.user_registration_login ? 'success' : 'neutral'}
                                                    variant={settings.user_registration_login ? 'solid' : 'outlined'}
                                                    endDecorator={settings.user_registration_login ? <Trans id="global.on">On</Trans> : <Trans id="global.off">Off</Trans>}
                                                    slotProps={{
                                                        endDecorator: {
                                                            sx: {
                                                                minWidth: 24,
                                                            },
                                                        },
                                                    }}
                                                    disabled={isSavingData}
                                                />
                                            </FormControl>
                                        </Stack>
                                        <Stack spacing={1}>
                                            <FormControl
                                                orientation="horizontal"
                                                sx={{  justifyContent: 'space-between' }}
                                            >
                                                <div>
                                                    <FormLabel><Trans id="AuthSettingsPage.point.singInWithEmail.title">SingIn with Email</Trans></FormLabel>
                                                    <FormHelperText sx={{ mt: 0 }}><Trans id="AuthSettingsPage.point.singInWithEmail.desc">For authentication, the user can use their email</Trans></FormHelperText>
                                                </div>
                                                <Switch
                                                    checked={settings.user_login_email}
                                                    onChange={handleUserLoginEmailChange}
                                                    color={settings.user_login_email ? 'success' : 'neutral'}
                                                    variant={settings.user_login_email ? 'solid' : 'outlined'}
                                                    endDecorator={settings.user_login_email ? <Trans id="global.on">On</Trans> : <Trans id="global.off">Off</Trans>}
                                                    slotProps={{
                                                        endDecorator: {
                                                            sx: {
                                                                minWidth: 24,
                                                            },
                                                        },
                                                    }}
                                                    disabled={isSavingData}
                                                />
                                            </FormControl>
                                        </Stack>
                                        <Stack spacing={1}>
                                            <FormControl
                                                orientation="horizontal"
                                                sx={{  justifyContent: 'space-between' }}
                                            >
                                                <div>
                                                    <FormLabel><Trans id="AuthSettingsPage.point.singInWithLogin.title">SingIn with Login</Trans></FormLabel>
                                                    <FormHelperText sx={{ mt: 0 }}><Trans id="AuthSettingsPage.point.singInWithLogin.desc">For authentication, the user can use their login</Trans></FormHelperText>
                                                </div>
                                                <Switch
                                                    checked={settings.user_login_login}
                                                    onChange={handleUserLoginLoginChange}
                                                    color={settings.user_login_login ? 'success' : 'neutral'}
                                                    variant={settings.user_login_login ? 'solid' : 'outlined'}
                                                    endDecorator={settings.user_login_login ? <Trans id="global.on">On</Trans> : <Trans id="global.off">Off</Trans>}
                                                    slotProps={{
                                                        endDecorator: {
                                                            sx: {
                                                                minWidth: 24,
                                                            },
                                                        },
                                                    }}
                                                    disabled={isSavingData}
                                                />
                                            </FormControl>
                                        </Stack>
                                    </>
                                )}
                            </Stack>
                        </Stack>

                        <CardOverflow sx={{ borderTop: '1px solid', borderColor: 'divider' }}>
                            <CardActions sx={{ alignSelf: 'flex-end', pt: 2 }}>
                                {settingsChanged && (
                                    <Button
                                        size="sm"
                                        variant="outlined"
                                        color="neutral"
                                        onClick={() => setSettings(initialSettings)}
                                        disabled={isSavingData}
                                    >
                                        <Trans id="global.cancel">Cancel</Trans>
                                    </Button>
                                )}
                                <Button
                                    size="sm"
                                    variant="solid"
                                    disabled={!settingsChanged}
                                    onClick={saveSettings}
                                    loading={isSavingData}
                                >
                                    <Trans id="global.save">Save</Trans>
                                </Button>
                            </CardActions>
                        </CardOverflow>
                    </Card>
                </Box>
            </Box>



            <CustomAlertSnackbar
                open={isAlertOpen}
                color="warning"
                onClose={()=>setIsAlertOpen(false)}
                title={<Trans id="AuthSettingsPage.Alert.Attention">ATTENTION</Trans>}
                message={<Trans id="AuthSettingsPage.Alert.message1">It is necessary to select at least one of the options: SingIn with Email or SingIn with Login.</Trans>}
            />
            <CustomSnackbar
                open={isOpenSb}
                message={<Trans id="AuthSettingsPage.Confirm.message1">The authentication settings have been saved</Trans>}
                handleClose={() => setIsOpenSb(false)} // Просто закрывает снэкбар
            />
        </>
    );
};

export default AuthSettingsPage;
