import React from 'react';
import Button from '@mui/joy/Button';
import { NavLink } from 'react-router-dom';

import {Trans} from "@lingui/macro";

// Определение пропсов компонента
interface EmailServiceButtonProps {
    email: string;
}

const EmailServiceButton: React.FC<EmailServiceButtonProps> = ({ email }) => {
    // Функция для определения почтового сервиса по email
    const getEmailServiceInfo = (email: string) => {
        const emailDomain = email.split('@')[1];
        const domainToServiceInfoMap: { [key: string]: { name: string; url: string } } = {
            'gmail.com': { 'name': 'Gmail', 'url': 'https://mail.google.com/' },
            'yahoo.com': { 'name': 'Yahoo Mail', 'url': 'https://mail.yahoo.com/' },
            'outlook.com': { 'name': 'Outlook', 'url': 'https://outlook.live.com/' },
            'yandex.ru': { 'name': 'Яндекс Почта', 'url': 'https://mail.yandex.ru/' },
            'mail.ru': { 'name': 'Mail.ru', 'url': 'https://mail.ru/' },
            'aol.com': { 'name': 'AOL Mail', 'url': 'https://www.aol.com/' },
            'protonmail.com': { 'name': 'ProtonMail', 'url': 'https://protonmail.com/' },
            'zoho.com': { 'name': 'Zoho Mail', 'url': 'https://zoho.com/mail/' },
            'icloud.com': { 'name': 'iCloud Mail', 'url': 'https://www.icloud.com/' },
            '163.com': { 'name': '网易邮箱 (NetEase)', 'url': 'https://mail.163.com/' },
            'qq.com': { 'name': 'Tencent QQ Mail', 'url': 'https://mail.qq.com/' },
            'sina.com': { 'name': '新浪邮箱 (Sina Mail)', 'url': 'https://mail.sina.com.cn/' },
            'naver.com': { 'name': '네이버 메일 (Naver Mail)', 'url': 'https://mail.naver.com/' },
            'rediffmail.com': { 'name': 'Rediffmail', 'url': 'https://mail.rediff.com/' },
            'hotmail.com': { 'name': 'Hotmail', 'url': 'https://outlook.live.com/' },
            'rocketmail.com': { 'name': 'RocketMail', 'url': 'https://mail.yahoo.com/' },
            'gmx.com': { 'name': 'GMX Mail', 'url': 'https://www.gmx.com/' },
            'aol.de': { 'name': 'AOL Deutschland', 'url': 'https://www.aol.de/' },
            't-online.de': { 'name': 'T-Online Mail', 'url': 'https://www.t-online.de/' },
            'seznam.cz': { 'name': 'Seznam Email', 'url': 'https://email.seznam.cz/' },
            'web.de': { 'name': 'WEB.DE Mail', 'url': 'https://web.de/' },
            'fastmail.com': { 'name': 'Fastmail', 'url': 'https://www.fastmail.com/' },
            'gmx.net': { 'name': 'GMX.net', 'url': 'https://www.gmx.net/' },
            'in.com': { 'name': 'In.com', 'url': 'https://www.in.com/' },
            'cox.net': { 'name': 'Cox Email', 'url': 'https://www.cox.com/residential/my-account.html' },
            'mail.com': { 'name': 'Mail.com', 'url': 'https://www.mail.com/' },
            'bluewin.ch': { 'name': 'Bluewin Mail', 'url': 'https://www.bluewin.ch/' },
            'gmx.at': { 'name': 'GMX Österreich', 'url': 'https://www.gmx.at/' },
            'verizon.net': { 'name': 'Verizon Mail', 'url': 'https://www.verizon.com/home/email/' },
            'terra.com.br': { 'name': 'Terra Mail', 'url': 'https://www.terra.com.br/' },
            'lycos.com': { 'name': 'Lycos Mail', 'url': 'https://www.lycos.com/' },
            'zoho.in': { 'name': 'Zoho Mail India', 'url': 'https://zoho.in/mail/' },
            'bol.com.br': { 'name': 'BOL Mail', 'url': 'https://www.bol.com.br/' },
            'mail.bg': { 'name': 'Mail.bg', 'url': 'https://mail.bg/' },
            'iol.pt': { 'name': 'IOL Mail', 'url': 'https://www.iol.pt/' },
            'laposte.net': { 'name': 'La Poste Mail', 'url': 'https://www.laposte.net/' },
            'orange.fr': { 'name': 'Orange Mail', 'url': 'https://messagerie.orange.fr/' },
            'alice.it': { 'name': 'Alice Mail', 'url': 'https://mail.alice.it/' },
            'eir.ie': { 'name': 'Eir Mail', 'url': 'https://www.eir.ie/email/' },
            'chello.at': { 'name': 'Chello Mail', 'url': 'https://www.chello.at/' },
            'tiscali.it': { 'name': 'Tiscali Mail', 'url': 'https://mail.tiscali.it/' },
            'iol.ie': { 'name': 'IOL Mail Ireland', 'url': 'https://www.iol.ie/' },
            'earthlink.net': { 'name': 'EarthLink Mail', 'url': 'https://www.earthlink.net/' },
            'zoho.eu': { 'name': 'Zoho Mail Europe', 'url': 'https://zoho.eu/mail/' },
            'vivaldi.net': { 'name': 'Vivaldi Mail', 'url': 'https://vivaldi.net/' },
            'yahoo.co.uk': { 'name': 'Yahoo Mail UK', 'url': 'https://uk.mail.yahoo.com/' },
            'tiscali.co.uk': { 'name': 'Tiscali Mail UK', 'url': 'https://www.tiscali.co.uk/' },
            'o2.pl': { 'name': 'O2 Mail', 'url': 'https://poczta.o2.pl/' },
            'gmx.ch': { 'name': 'GMX Schweiz', 'url': 'https://www.gmx.ch/' },
            'zonnet.nl': { 'name': 'Zonnet Mail', 'url': 'https://webmail.kpn.nl/' },
            'telenet.be': { 'name': 'Telenet Mail', 'url': 'https://www2.telenet.be/nl/klantenservice/internet-en-e-mail/webmail/' },
            'sympatico.ca': { 'name': 'Sympatico Mail', 'url': 'https://www.bell.ca/webmail/' },
            'iol.co.za': { 'name': 'IOL Mail South Africa', 'url': 'https://www.iol.co.za/' },
            'yahoo.com.au': { 'name': 'Yahoo Mail Australia', 'url': 'https://au.mail.yahoo.com/' },
            'mail.ua': { 'name': 'Mail.ua', 'url': 'https://mail.ua/' },
            'interia.pl': { 'name': 'Interia Mail', 'url': 'https://poczta.interia.pl/' },
            'aliceadsl.fr': { 'name': 'Alice ADSL Mail', 'url': 'https://www.aliceadsl.fr/' },
            'excite.com': { 'name': 'Excite Mail', 'url': 'https://www.excite.com/' },
            'gmx.fr': { 'name': 'GMX France', 'url': 'https://www.gmx.fr/' },
            'aol.co.uk': { 'name': 'AOL Mail UK', 'url': 'https://www.aol.co.uk/' },
            'virgilio.it': { 'name': 'Virgilio Mail', 'url': 'https://mail.virgilio.it/' },
            'yahoo.fr': { 'name': 'Yahoo Mail France', 'url': 'https://fr.mail.yahoo.com/' },
            't-online.at': { 'name': 'T-Online Mail Austria', 'url': 'https://www.t-mobile.at/' },
            'telus.net': { 'name': 'Telus Mail', 'url': 'https://webmail.telus.net/' },
            'yahoo.it': { 'name': 'Yahoo Mail Italy', 'url': 'https://it.mail.yahoo.com/' },
            'virginmedia.com': { 'name': 'Virgin Media Mail', 'url': 'https://www.virginmedia.com/' },
            'aol.fr': { 'name': 'AOL Mail France', 'url': 'https://www.aol.fr/' },
            'mail.telenet.be': { 'name': 'Telenet Mail Belgium', 'url': 'https://mail.telenet.be/' },
            'windstream.net': { 'name': 'Windstream Mail', 'url': 'https://www.windstream.net/' },
            'freenet.de': { 'name': 'Freenet Mail', 'url': 'https://www.freenet.de/' },
            'virgin.net': { 'name': 'Virgin Mail', 'url': 'https://www.virgin.net/' },
            'mail.be': { 'name': 'Mail.be', 'url': 'https://www.mail.be/' },
            'zoho.com.cn': { 'name': 'Zoho Mail China', 'url': 'https://zoho.com.cn/mail/' },
            'zoho.sg': { 'name': 'Zoho Mail Singapore', 'url': 'https://zoho.sg/mail/' },
            'zoho.com.au': { 'name': 'Zoho Mail Australia', 'url': 'https://zoho.com.au/mail/' },
            'zoho.com.hk': { 'name': 'Zoho Mail Hong Kong', 'url': 'https://zoho.com.hk/mail/' },
            'zoho.com.br': { 'name': 'Zoho Mail Brazil', 'url': 'https://zoho.com.br/mail/' },
            'zoho.com.mx': { 'name': 'Zoho Mail Mexico', 'url': 'https://zoho.com.mx/mail/' },
            'zoho.com.es': { 'name': 'Zoho Mail Spain', 'url': 'https://zoho.com.es/mail/' },
            'zoho.com.fr': { 'name': 'Zoho Mail France', 'url': 'https://zoho.com.fr/mail/' },
            'zoho.com.uk': { 'name': 'Zoho Mail UK', 'url': 'https://zoho.com.uk/mail/' },
            'zoho.com.de': { 'name': 'Zoho Mail Germany', 'url': 'https://zoho.com.de/mail/' },
            'zoho.com.it': { 'name': 'Zoho Mail Italy', 'url': 'https://zoho.com.it/mail/' },
            'zoho.com.sg': { 'name': 'Zoho Mail Singapore', 'url': 'https://zoho.com.sg/mail/' },
            'yopmail.com': { 'name': 'YOPmail', 'url': 'http://www.yopmail.com/' },
        };

        return domainToServiceInfoMap[emailDomain]; // Удален fallback на Gmail
    };

    // Информация о почтовом сервисе
    const emailServiceInfo = getEmailServiceInfo(email);

    // Обработчик нажатия на кнопку
    const handleButtonClick = () => {
        if (emailServiceInfo && emailServiceInfo.url) {
            window.open(emailServiceInfo.url, '_blank');
        }
    };

    return emailServiceInfo ? (
        <Button
            onClick={handleButtonClick}
            variant="outlined"
            sx={{
                mt: 2
            }}
        >
            <Trans id="emailServiceInfo.goTo">Go to</Trans> {emailServiceInfo.name}
        </Button>
    ) : (
        <Button
            component={NavLink}
            to="/"
            variant="outlined"
            sx={{
                mt: 2
            }}
        >
            <Trans id="emailServiceInfo.home">Go to Home</Trans>
        </Button>
    );
};

export default EmailServiceButton;