// src/langConfig.ts
interface Language {
    langCode: string;
    name: string;
    countryCode: string;
}


const languages: Language[] = [  // Установка исходного языка на основе первого элемента массива languages
    {
        langCode: 'en', //ISO 639-1
        name: 'English',
        countryCode: 'us' //ISO 3166-1 alpha-2
    },
    {
        langCode: 'ru',
        name: 'Русский',
        countryCode: 'ru'
    }
];


const sourceLocale = languages[0].langCode;
const locales = languages.map(language => language.langCode);
export { locales, sourceLocale, languages };