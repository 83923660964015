// NotFoundPage.tsx
import React from 'react';
import Box from '@mui/joy/Box';
import Typography from '@mui/joy/Typography';

import {Comment} from "react-loader-spinner";


const ErrorLocalePage = () => {
    return (
        <Box sx={{ display: 'flex', minHeight: '100dvh' }}>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    maxWidth: 300,
                    margin: 'auto',
                    gap: 2,
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        height: '100%',
                        textAlign: 'center',
                    }}
                >
                    <Comment
                        visible={true}
                        height="80"
                        width="80"
                        ariaLabel="comment-loading"
                        wrapperStyle={{}}
                        wrapperClass="comment-wrapper"
                        color="#fff"
                        backgroundColor="#F4442E"
                    />
                    <Typography level="h3">SORRY</Typography>
                    <Typography level="h4">COMMUNICATION COMPLEXITIES</Typography>
                    <Typography level="body-xs">
                        error loading language resource
                    </Typography>


                </Box>
            </Box>
        </Box>

    );
};

export default ErrorLocalePage;