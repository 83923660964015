// GlobalSettingsPage.tsx
import React, { useState, useEffect } from 'react';
import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import Typography from '@mui/joy/Typography';
import Stack from "@mui/joy/Stack";
import Card from "@mui/joy/Card";
import Divider from "@mui/joy/Divider";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import Switch from '@mui/joy/Switch';
import Input from "@mui/joy/Input";
import CardOverflow from "@mui/joy/CardOverflow";
import CardActions from "@mui/joy/CardActions";
import CustomSnackbar from "../../../../components/UI/CustomSnackbar";
import LoadingSpinner from "../../../../components/UI/LoadingSpinner";
import Select from "@mui/joy/Select";
import Option from "@mui/joy/Option";
import {languages} from "../../../../langConfig";

import FormHelperText from "@mui/joy/FormHelperText";
import {Trans} from "@lingui/macro";
import { msg } from "@lingui/macro";
import { useLingui } from "@lingui/react";

interface GlobalSettingsState {
    name: string;
    version: string;
    defaultLocale: string | null;
    localization: boolean;
    url: string;
}

const GlobalSettingsPage = () => {
    const { _ } = useLingui();
    const [settings, setSettings] = useState<GlobalSettingsState>({
        name: '',
        version: '',
        defaultLocale: '',
        localization: false,
        url: '',
    });
    const [initialSettings, setInitialSettings] = useState<GlobalSettingsState>({
        name: '',
        version: '',
        defaultLocale: '',
        localization: false,
        url: '',
    });
    const settingsChanged = JSON.stringify(settings) !== JSON.stringify(initialSettings);
    const [isLoadingData, setIsLoadingData] = useState(true);
    const [isSavingData, setIsSavingData] = useState(false);
    const [isOpenSb, setIsOpenSb] = useState(false);

    const fetchSettings = async () => {
        setIsLoadingData(true)
        try {
            const token = localStorage.getItem('authToken');
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/admin/settings/get-global-settings`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            return data;
        } catch (error) {
            console.error('Error fetching settings:', error);
        } finally {
            setIsLoadingData(false)
        }
    };

    useEffect(() => {
        const loadData = async () => {
            const fetchedSettings = await fetchSettings();
            if (fetchedSettings) {
                const newSettings = {
                    name: fetchedSettings.name,
                    version: fetchedSettings.version,
                    defaultLocale: fetchedSettings.defaultLocale,
                    localization: fetchedSettings.localization,
                    url: fetchedSettings.url,
                };
                setSettings(newSettings);
                setInitialSettings(newSettings);
            }
        };
        loadData();
    }, []);

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setSettings(prevSettings => ({ ...prevSettings, [name]: value }));
    };

    const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, checked } = event.target;
        setSettings(prevSettings => ({ ...prevSettings, [name]: checked }));
    };

    const saveSettings = async () => {
        setIsSavingData(true);
        try {
            const token = localStorage.getItem('authToken');
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/admin/settings/put-global-settings`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify(settings)
            });

            if (!response.ok) {
                throw new Error('Failed to save settings');
            }

            setIsSavingData(false);
            setInitialSettings(settings); // Обновляем начальные настройки после успешного сохранения
            setIsOpenSb(true);
        } catch (error) {
            console.error('Error saving settings:', error);
            setIsSavingData(false);
        }
    };

    if (isLoadingData){
        return (<LoadingSpinner/>)
    }

    return (
        <>
            <Box
                sx={{
                    display: 'flex',
                    mb: 1,
                    gap: 1,
                    flexDirection: { xs: 'column', sm: 'row' },
                    alignItems: { xs: 'start', sm: 'center' },
                    flexWrap: 'wrap',
                    justifyContent: 'space-between',
                }}
            >
                <Typography level="h2" component="h1">
                    <Trans id="GlobalSettingsPage.globaSetti">Global Settings</Trans>
                </Typography>
            </Box>

            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                }}
            >
                <Box
                    sx={{
                        maxWidth: '600px',
                        flexShrink: 1,
                        overflow: 'auto',
                        minHeight: 0,
                    }}
                >
                    <Card>
                        <Box sx={{ mb: 1 }}>
                            <Typography level="title-md"><Trans id="GlobalSettingsPage.globaSetti">Global Settings</Trans></Typography>
                            <Typography level="body-sm">
                                <Trans id="GlobalSettingsPage.confiGlobaSettiOfThe">Configure global settings of the system</Trans>
                            </Typography>
                        </Box>
                        <Divider />
                        <Stack
                            direction="column"
                            spacing={3}
                            sx={{ my: 1 }}
                        >
                            <FormControl>
                                <FormLabel><Trans id="GlobalSettingsPage.name">Name</Trans></FormLabel>
                                <Input
                                    name="name"
                                    value={settings.name}
                                    onChange={handleInputChange}
                                    disabled={isSavingData}
                                />
                            </FormControl>
                            <FormControl>
                                <FormLabel><Trans id="GlobalSettingsPage.versi">Version</Trans></FormLabel>
                                <Input
                                    name="version"
                                    value={settings.version}
                                    onChange={handleInputChange}
                                    disabled={isSavingData}
                                />
                            </FormControl>
                            <FormControl>
                                <FormLabel><Trans id="GlobalSettingsPage.url">URL</Trans></FormLabel>
                                <Input
                                    name="url"
                                    value={settings.url}
                                    onChange={handleInputChange}
                                    disabled={isSavingData}
                                />
                            </FormControl>
                            <FormControl
                                orientation="horizontal"
                                sx={{ justifyContent: 'space-between' }}
                            >
                                <div>
                                    <FormLabel><Trans id="GlobalSettingsPage.local">Localization</Trans></FormLabel>
                                    <FormHelperText sx={{ mt: 0 }}><Trans id="GlobalSettingsPage.enablSiteLocal">Enable site localization</Trans></FormHelperText>
                                </div>
                                <Switch
                                    checked={settings.localization}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                                        setSettings(prevSettings => ({...prevSettings, localization: event.target.checked}))
                                    }
                                    disabled={isSavingData}
                                />
                            </FormControl>
                            <FormControl>
                                <FormLabel><Trans id="GlobalSettingsPage.defauLocal">Default Locale</Trans></FormLabel>
                                <Select
                                    defaultValue={settings.defaultLocale}
                                    onChange={(event, newValue) =>
                                        setSettings(prevSettings => ({...prevSettings, defaultLocale: newValue}))
                                    }
                                    disabled={isSavingData}
                                >

                                    {languages.map((lang,index) => (
                                        <Option
                                            key={index}
                                            value={lang.langCode}
                                        >
                                            {lang.name}
                                        </Option>
                                    ))}
                                </Select>
                            </FormControl>
                        </Stack>

                        <CardOverflow sx={{ borderTop: '1px solid', borderColor: 'divider' }}>
                            <CardActions sx={{ alignSelf: 'flex-end', pt: 2 }}>
                                {settingsChanged && (
                                    <Button
                                        size="sm"
                                        variant="outlined"
                                        color="neutral"
                                        onClick={() => setSettings(initialSettings)}
                                        disabled={isSavingData}
                                    >
                                        <Trans id="GlobalSettingsPage.cance">Cancel</Trans>
                                    </Button>
                                )}
                                <Button
                                    size="sm"
                                    variant="solid"
                                    disabled={!settingsChanged}
                                    onClick={saveSettings}
                                    loading={isSavingData}
                                >
                                    <Trans id="GlobalSettingsPage.save">Save</Trans>
                                </Button>
                            </CardActions>
                        </CardOverflow>
                    </Card>
                </Box>
            </Box>

            <CustomSnackbar
                open={isOpenSb}
                message={_(msg({ id: "GlobalSettingsPage.globaSettiHaveBeenSa", message: "Global settings have been saved" }))}
                handleClose={() => setIsOpenSb(false)}
            />
        </>
    );
};

export default GlobalSettingsPage;
