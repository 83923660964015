import React, {useState, useEffect, JSX} from 'react';
import {
    Table,
    Box,
    Typography,
    Button,
    Avatar,
    Chip,
    Sheet,
    FormControl,
    FormLabel,
    Input,
    IconButton,
    LinearProgress
} from '@mui/joy';
import SearchIcon from "@mui/icons-material/Search";
import SearchOffIcon from "@mui/icons-material/SearchOff";
import {iconButtonClasses} from "@mui/joy/IconButton";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import PauseCircleOutlineIcon from '@mui/icons-material/PauseCircleOutline';
import DoneIcon from '@mui/icons-material/Done';

import {Invitation} from './userInterfaces';
import {i18n} from "@lingui/core";
import {
    getRoleDisplayColor,
    getRoleDisplayIcon,
    getRoleDisplayName
} from "../../../../../components/UI/getRoleDisplayName";
import {Trans} from "@lingui/macro";
import { msg } from "@lingui/macro";
import { useLingui } from "@lingui/react";

interface InvitationsTableListProps {
    invitations: Invitation[];
    isLoading: boolean;
    page: number;
    limit: number;
    totalPages: number;
    totalInvitations: number;
    handlePageChange: (newPage: number) => void;
    renderFilters: () => JSX.Element;
    rowMenu: (rowID: number) => JSX.Element | null;

    searchQuery: string;
    searchPreQuery: string;
    setSearchQuery: (query: string) => void;
    setSearchPreQuery: (query: string) => void;
}

const InvitationsTable: React.FC<InvitationsTableListProps> = (
    {
        invitations,
        isLoading,
        page,
        limit,
        totalPages,
        totalInvitations,
        handlePageChange,
        renderFilters,
        rowMenu,

        searchQuery,
        searchPreQuery,
        setSearchQuery,
        setSearchPreQuery,
}) => {
    const { _ } = useLingui();
    const startUserIndex = (page - 1) * limit + 1;
    const endUserIndex = startUserIndex + invitations.length - 1;

    return (
        <>
            <Box className="SearchAndFilters-tabletUp" sx={{borderRadius: 'sm', py: 2, display: { xs: 'none', sm: 'flex' }, flexWrap: 'wrap', gap: 1.5, '& > *': {minWidth: { xs: '120px', md: '160px' },},}}>
                <FormControl sx={{ flex: 1 }} size="sm">
                    <FormLabel><Trans id="InvitationsTable.searcForInvit">Search for invitation</Trans></FormLabel>
                    <Input
                        size="sm"
                        placeholder={i18n._({ id: "InvitationsTable.searc", message: "Search" })}
                        sx={{ flexGrow: 1 }}
                        startDecorator={<SearchIcon />}
                        onChange={(event) => {
                            if (event.target.value === '')
                            {
                                setSearchQuery("")
                                setSearchPreQuery("")
                            } else {
                                setSearchPreQuery(event.target.value)
                            }

                        }}
                        value={searchPreQuery}
                        disabled={isLoading}
                        endDecorator={
                            searchPreQuery.length > 0 && (
                                searchQuery === searchPreQuery ? (
                                    <Button
                                        startDecorator={<SearchOffIcon/>}
                                        onClick={(event)=>{
                                            setSearchPreQuery("");
                                            setSearchQuery("");
                                        }}
                                    >
                                        <Trans id="InvitationsTable.clear">Clear</Trans>
                                    </Button>
                                ) : (
                                    <Button
                                        onClick={(event)=>{setSearchQuery(searchPreQuery)}}
                                        startDecorator={<SearchIcon/>}
                                    >
                                        <Trans id="InvitationsTable.searc">Search</Trans>
                                    </Button>
                                )
                            )
                        }
                    />
                </FormControl>
                {renderFilters()}
            </Box>

            <Sheet className="OrderTableContainer" variant="outlined" sx={{display: { xs: 'none', sm: 'initial' }, width: '100%', borderRadius: 'sm', flexShrink: 1, overflow: 'auto', minHeight: 0,}}>

                <Table
                    aria-labelledby="tableTitle"
                    stickyHeader
                    stickyFooter
                    hoverRow
                    sx={{
                        '--Table-headerUnderlineThickness': '1px',
                        '--TableRow-hoverBackground': 'var(--joy-palette-background-level1)',
                        '--TableCell-paddingY': '4px',
                        '--TableCell-paddingX': '8px',
                    }}
                >
                    <thead>
                    <tr>
                        <th style={{ width: "20%", padding: '12px 6px' }}><Trans id="InvitationsTable.name">Name</Trans></th>
                        <th style={{ width: "30%",  padding: '12px 6px' }}><Trans id="InvitationsTable.email">Email</Trans></th>
                        <th style={{ width: "20%",  padding: '12px 6px' }}><Trans id="InvitationsTable.role">Role</Trans></th>
                        <th style={{ width: "10%", textAlign: 'center', padding: '12px 6px' }}><Trans id="InvitationsTable.accep">Accepte</Trans></th>
                        <th style={{ width: "10%", textAlign: 'center', padding: '12px 6px' }}><Trans id="InvitationsTable.lastSent">Last Sent</Trans></th>
                        <th style={{ width: "10%", textAlign: 'center', padding: '12px 6px' }}><Trans id="InvitationsTable.actio">Actions</Trans></th>
                    </tr>
                    </thead>
                    <tbody>
                    {isLoading ? (
                        <tr >
                            <td colSpan={6}>
                                <LinearProgress
                                    variant="plain"
                                    size="sm"
                                    sx={{
                                        width: '100%', // Ширина равна 100% родительского элемента
                                        position: 'static', // Абсолютное позиционирование
                                        left: 0, // Позиционируем в левой части CardOverflow
                                        m: 0, // Убираем внешние отступы
                                        borderRadius: 0, // Убираем скругление
                                        zIndex:11,
                                    }}
                                />
                            </td>
                        </tr>
                    ) : (
                        invitations.length === 0 ? (
                            <tr>
                                <td colSpan={7} style={{ textAlign: 'center' }}>
                                    <Trans id="InvitationsTable.noResul">No results</Trans>
                                </td>
                            </tr>
                        ) : (
                            invitations.map((invitation, index) => (
                            <tr key={index}>
                                <td>{invitation.name}</td>
                                <td>{invitation.email}</td>
                                <td>
                                    <Chip
                                        variant="soft"
                                        size="sm"
                                        startDecorator={
                                            getRoleDisplayIcon(invitation.role,i18n)
                                        }
                                        color={getRoleDisplayColor(invitation.role,i18n)}
                                    >
                                        {getRoleDisplayName(invitation.role,i18n)}
                                    </Chip>
                                </td>
                                <td style={{ textAlign: 'center' }}>{invitation.accepted ? <Typography level="title-lg" color="success"><DoneIcon/></Typography> : <Typography level="title-lg" color="warning"><PauseCircleOutlineIcon/></Typography>}</td>
                                <td style={{ textAlign: 'center' }}>
                                    {new Date(invitation.lastSendInvitation).toLocaleDateString(i18n.locale)}
                                </td>
                                <td style={{textAlign:'center' }}>
                                    <Box sx={{ display: 'flex', gap: 2, alignItems: 'center', justifyContent: 'center' }}>
                                        {rowMenu(index)}
                                    </Box>
                                </td>
                            </tr>
                        ))
                        )
                    )}
                    </tbody>
                    {!isLoading && invitations.length > 0 && (
                        <tfoot>
                            <tr>
                                <td colSpan={7} style={{textAlign:'right' }}>{i18n._({ id: "InvitationsTable.showiStartToEndusOfT", message: "showing {startUserIndex} to {endUserIndex} of {totalInvitations}", values: { startUserIndex:startUserIndex, endUserIndex:endUserIndex, totalInvitations:totalInvitations } })}</td>
                            </tr>
                        </tfoot>
                    )}
                </Table>
            </Sheet>
            {invitations.length > 0 && (
                <Box className="Pagination-laptopUp" sx={{pt: 2, gap: 1, [`& .${iconButtonClasses.root}`]: { borderRadius: '50%' }, display: {xs: 'none', md: 'flex',},}}>
                    <Button
                        size="sm"
                        variant="outlined"
                        color="neutral"
                        startDecorator={<KeyboardArrowLeftIcon />}
                        onClick={() => handlePageChange(page - 1)}
                        disabled={page === 1 || isLoading}
                    >
                        <Trans id="InvitationsTable.previ">Previous</Trans>
                    </Button>

                    <Box sx={{ flex: 1 }} />
                    {[...Array(totalPages)].map((_, idx) => (
                        <IconButton
                            key={idx + 1}
                            onClick={() => handlePageChange(idx + 1)}
                            size="sm"
                            variant={idx + 1 === page ? 'plain' : 'outlined'}
                            color="neutral"
                            disabled={isLoading}
                        >
                            {idx + 1}
                        </IconButton>
                    ))}
                    <Box sx={{ flex: 1 }} />

                    <Button
                        onClick={() => handlePageChange(page + 1)}
                        disabled={page === totalPages || isLoading}
                        size="sm"
                        variant="outlined"
                        color="neutral"
                        endDecorator={<KeyboardArrowRightIcon />}

                    >
                        <Trans id="InvitationsTable.next">Next</Trans>
                    </Button>

                </Box>
            )}

        </>
    );
};

export default InvitationsTable;
