// WalletsIndexPage.tsx
import React, {useState, useEffect, JSX} from 'react';
import Web3 from 'web3';
import BigNumber from 'bignumber.js';
import Box from '@mui/joy/Box';
import Typography from "@mui/joy/Typography";
import Button from "@mui/joy/Button";
import {Chip, LinearProgress, Table} from "@mui/joy";
import LoadingSpinner from "../../../components/UI/LoadingSpinner";
import AddIcon from "@mui/icons-material/Add";
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import {FormControl, FormLabel, Input} from "@mui/joy";
import SearchIcon from "@mui/icons-material/Search";
import SearchOffIcon from "@mui/icons-material/SearchOff";
import Sheet from "@mui/joy/Sheet";
import IconButton, {iconButtonClasses} from '@mui/joy/IconButton';
import ToggleButtonGroup from '@mui/joy/ToggleButtonGroup';
import FormatBoldIcon from '@mui/icons-material/FormatBold';
import FormatItalicIcon from '@mui/icons-material/FormatItalic';
import FormatUnderlinedIcon from '@mui/icons-material/FormatUnderlined';
import Select from "@mui/joy/Select";
import Option from "@mui/joy/Option";
import {i18n} from "@lingui/core";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Modal from '@mui/joy/Modal';
import ModalClose from '@mui/joy/ModalClose';

import Link from '@mui/joy/Link';
import {NavLink} from "react-router-dom";
import Pagination from "./compontents/Paginator";

interface PaginationProps {
    page: number;
    totalPages: number;
    isLoadingQuery: boolean;
    handlePageChange: (page: number) => void;
}

interface Blockchain {
    bcName: string;
    bcId: string;
    icon: string;
    bcNT: string;
    bcDecimal:number;
    scannerUrl: string;
    tokens: Token[];
}

interface Token {
    tokenName: string;
    tokenId: string;
    icon: string;
    decimal:number;
}
interface PortfolioItem {
    chainName: string;
    chainUrl: string;
    tokenName: string;
    tokenSymb: string;
    tokenUrl: string;
    tokenIcon: string;
    portfolio: number;
    price: number;
    amount: number;
    value: number;
}

interface Portfolio {
    total: number;
    chains: number;
    list: PortfolioItem[];
}

interface Wallet {
    _id: string;
    address: string;
    nativeBalance: number;
    tokenBalance?: number; // Опционально, поскольку может не присутствовать, если не запрашивается баланс по конкретному токену
    createdAt?: Date;
    updatedAt?: Date;
    portfolio?: Portfolio | null;
}

interface SortingState {
    type: 'native' | 'token';
    order: 'asc' | 'desc' | null;
}



const WalletsPage: React.FC = () => {
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(25); // Default rows per page
    const [searchQuery, setSearchQuery] = useState('');
    const [searchPreQuery, setSearchPreQuery] = useState('');
    const [sorting, setSorting] = useState<SortingState | null>(null);

    const [blockchains, setBlockchains] = useState<Blockchain[]>([]);
    const [selectedBlockchain, setSelectedBlockchain] = useState<Blockchain | null>(null);
    const [tokens, setTokens] = useState<Token[]>([]);
    const [selectedToken, setSelectedToken] = useState<Token | null>(null);
    const [totalPages, setTotalPages]= useState(0);
    const [totalWallets, setTotalWallets]= useState(0);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const [isLoadingQuery, setIsLoadingQuery] = useState<boolean>(false);

    const [wallets, setWallets] = useState<Wallet[]>([]);

    const [isModaPortfolio,setIsModaPortfolio] =useState(false)
    const [showPortfolio,setShowPortfolio] =useState<Portfolio | null>(null)

    const startWalletIndex = (page - 1) * limit + 1;
    const endWalletIndex = startWalletIndex + wallets.length - 1;

    const handlePageChange = (newPage:number) => {
        setPage(newPage);
    };

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            const token = localStorage.getItem('authToken'); // Убедитесь, что токен хранится в localStorage
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/api/wallets/get-bcs-tokens`, {
                    method: 'POST',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                });
                if (!response.ok) throw new Error('Failed to fetch blockchains and tokens');
                const data = await response.json();
                setBlockchains(data);
                // Установка selectedBlockchain на bcId первого блокчейна в массиве
                if (data.length > 0) {
                    setSelectedBlockchain(data[0]); // Прямое сохранение объекта блокчейна
                    setTokens(data[0].tokens);
                }
            } catch (error) {
                console.error('Error:', error);
            } finally {
                setIsLoading(false)
            }
        };

        fetchData();
    }, []);

    useEffect(() => {
        // Условие проверяет наличие выбранного блокчейна перед обновлением списка токенов
        if (selectedBlockchain && selectedBlockchain.tokens.length > 0) {
            setTokens(selectedBlockchain.tokens);
            setSelectedToken(selectedBlockchain.tokens[0]); // Автоматический выбор первого токена
        } else {
            setTokens([]);
            setSelectedToken(null);
        }
    }, [selectedBlockchain]);



    const fetchWalletsBalance = async () => {
        setIsLoadingQuery(true);
        console.log(sorting)
        const authToken = localStorage.getItem('authToken'); // Получаем токен из localStorage
        if (!selectedToken) return;

        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/wallets/get-wallets-balance`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${authToken}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    page,
                    limit,
                    tokenId: selectedToken.tokenId, // Используем ID выбранного токена
                    blockchainId:selectedBlockchain?.bcId,
                    search:searchQuery,
                    sort: sorting?.type, // 'native' или 'token'
                    order: sorting?.order // 'asc' или 'desc'
                }),
            });

            if (!response.ok) {
                throw new Error('Failed to fetch wallets balance');
            }

            const data = await response.json();
            console.log(data); // Выводим полученные данные для проверки
            setWallets(data.wallets);
            setTotalPages(data.totalPages);
            setTotalWallets(data.totalWallets);
            // Здесь можно обновить состояние с данными о кошельках, если требуется
        } catch (error) {
            console.error('Error:', error);
        } finally {
            setIsLoadingQuery(false);
        }
    };

    useEffect(() => {
        if (selectedToken !==null) {
            fetchWalletsBalance();
        }
    }, [selectedToken,limit,page,searchQuery,sorting]);

    useEffect(() => {
        if (searchPreQuery.length <=0) {
            setSearchQuery(searchPreQuery)
        }
    }, [searchPreQuery]);


    const handleSortingChange = (sortType: 'native' | 'token') => {
        setSorting((currentSorting) => {
            if (!currentSorting || currentSorting.type !== sortType) {
                // Если текущая сортировка не установлена или отличается, начинаем с убывания.
                return { type: sortType, order: 'desc' };
            } else if (currentSorting.order === 'desc') {
                // Если текущая сортировка убывающая, меняем на возрастающую.
                return { ...currentSorting, order: 'asc' };
            } else {
                // Если текущая сортировка возрастающая, сбрасываем сортировку.
                return null; // Явно возвращаем null, чтобы избежать undefined.
            }
        });
    };


    const openModalPortfolio = (rowID: number) => {
        // Убедитесь, что rowID находится в пределах диапазона массива wallets
        if (rowID >= 0 && rowID < wallets.length) {
            const portfolio = wallets[rowID].portfolio;
            // Проверяем, что portfolio не null и не undefined
            if (portfolio !== null && portfolio !== undefined) {
                setShowPortfolio(portfolio);
                setIsModaPortfolio(true);
                console.log("portfolio",portfolio)
            }
        }
    }


    if (isLoading){
        return <LoadingSpinner/>
    }
    return (
        <>
            <Box sx={{display: 'flex', mb: 1, gap: 1, flexDirection: { xs: 'column', sm: 'row' }, alignItems: { xs: 'start', sm: 'center' }, flexWrap: 'wrap', justifyContent: 'space-between',}}>
                <Typography level="h2" component="h1">
                    Wallets
                </Typography>


                <Box>
                    <Button
                        color="primary"
                        size="sm"
                        startDecorator={<PersonAddIcon />}
                        sx={{
                            mr:1
                        }}
                    >
                        Add wallet
                    </Button>
                    <Button
                        color="primary"
                        size="sm"
                        startDecorator={<GroupAddIcon />}
                        component={NavLink}
                        to={"/dashboard/wallets/add-group"}
                    >
                        Group add wallets
                    </Button>
                </Box>
            </Box>


            <Box className="SearchAndFilters-tabletUp" sx={{borderRadius: 'sm', display: { sm: 'flex' }, flexWrap: 'wrap', gap: 1.5, '& > *': {minWidth: { xs: '120px', md: '160px' },},}}>
                <FormControl sx={{ flex: 1 }} size="sm">
                    <FormLabel>Blockchain</FormLabel>
                    <ToggleButtonGroup size="sm" value={selectedBlockchain?.bcId} onChange={(event, bcId) => {
                        const bc = blockchains.find(bc => bc.bcId === bcId);
                        setSelectedBlockchain(bc || null);
                    }}>
                        {blockchains.map(bc => (
                            <Button key={bc.bcId} value={bc.bcId}>{bc.bcName}</Button>
                        ))}
                    </ToggleButtonGroup>
                </FormControl>
            </Box>

            <Box className="SearchAndFilters-tabletUp" sx={{borderRadius: 'sm', py: 2, display: { sm: 'flex' }, flexWrap: 'wrap', gap: 1.5, '& > *': {minWidth: { xs: '120px', md: '160px' },},}}>
                <FormControl size="sm">
                    <FormLabel>Tokens</FormLabel>
                    <ToggleButtonGroup size="sm" value={selectedToken?.tokenId} onChange={(event, tokenId) => {
                        const token = tokens.find(t => t.tokenId === tokenId);
                        setSelectedToken(token || null);
                    }}>
                        {tokens.map((token) => (
                            <Button key={token.tokenId} value={token.tokenId}>{token.tokenName}</Button>
                        ))}
                    </ToggleButtonGroup>
                </FormControl>
                <FormControl sx={{ flex: 1 }} size="sm">
                    <FormLabel>Search for tokens</FormLabel>
                    <Input
                        size="sm"
                        placeholder=""
                        startDecorator={<SearchIcon />}
                        disabled={isLoadingQuery}
                        value={searchPreQuery}
                        endDecorator={
                            searchPreQuery.length > 0 && (
                                searchQuery === searchPreQuery ? (
                                    <Button
                                        startDecorator={<SearchOffIcon/>}
                                        onClick={()=>{
                                            setSearchPreQuery('')
                                        }}
                                    >
                                        Clean
                                    </Button>
                                ): (
                                    <Button
                                        startDecorator={<SearchIcon/>}
                                        onClick={()=>{
                                            setSearchQuery(searchPreQuery)
                                        }}
                                    >
                                        Search
                                    </Button>

                                )

                            )
                        }
                        onChange={(event)=>{
                            setSearchPreQuery(event.target.value)
                        }}
                    />
                </FormControl>
                <FormControl size="sm">
                    <FormLabel>Row Limit</FormLabel>
                    <Select
                        size="sm"
                        //disabled={isLoadingQuery}
                        defaultValue={limit}
                        onChange={(event,value)=>{
                            if (value) setLimit(value)
                        }}
                    >

                        <Option value={25}>25</Option>
                        <Option value={50}>50</Option>
                        <Option value={100}>100</Option>
                    </Select>
                </FormControl>
            </Box>

            <Sheet className="OrderTableContainer" variant="outlined" sx={{width: '100%', borderRadius: 'sm', flexShrink: 1, overflow: 'auto', minHeight: 0,}}>
                <Table
                    aria-labelledby="BlockChainTable"
                    stickyHeader
                    stickyFooter
                    hoverRow
                    sx={{
                        '--Table-headerUnderlineThickness': '1px',
                        '--TableRow-hoverBackground': 'var(--joy-palette-background-level1)',
                        '--TableCell-paddingY': '4px',
                        '--TableCell-paddingX': '8px',
                    }}
                >
                    <thead>
                    <tr>
                        <th style={{ width: "45%", padding: '12px 6px' }}>Wallet address</th>
                        <th style={{ width: "15%", padding: '12px 6px' }}>
                            <Link
                                underline="none"
                                color="primary"
                                component="button"
                                onClick={() => handleSortingChange('native')}
                                endDecorator={
                                    sorting?.type === 'native' ? (
                                        sorting.order === 'desc' ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />
                                    ) : null
                                }
                            >
                                {selectedBlockchain?.bcNT}
                            </Link>


                        </th>
                        <th style={{ width: "15%", padding: '12px 6px' }}>
                            <Link
                                underline="none"
                                color="primary"
                                component="button"
                                onClick={() => handleSortingChange('token')}
                                endDecorator={
                                    sorting?.type === 'token' ? (
                                        sorting.order === 'desc' ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />
                                    ) : null
                                }
                            >
                                {selectedToken?.tokenName}
                            </Link>


                        </th>
                        <th style={{ width: "15%", padding: '12px 6px' }}>Portfolio</th>
                        <th style={{ width: "10%",  padding: '12px 6px' }}>Comment</th>
                    </tr>
                    </thead>
                    <tbody>
                    {isLoadingQuery ? (
                        <tr >
                            <td colSpan={4}>
                                <LinearProgress
                                    variant="plain"
                                    size="sm"
                                    sx={{
                                        width: '100%', // Ширина равна 100% родительского элемента
                                        position: 'static', // Абсолютное позиционирование
                                        left: 0, // Позиционируем в левой части CardOverflow
                                        m: 0, // Убираем внешние отступы
                                        borderRadius: 0, // Убираем скругление
                                        zIndex:11,
                                    }}
                                />
                            </td>
                        </tr>
                    ) : (
                        wallets.length > 0 ? (
                            wallets.map((wallet, index) => (
                                <tr key={wallet._id}>
                                    <td>
                                        <Link
                                            href={`${selectedBlockchain?.scannerUrl}/${wallet.address}`}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            color="success"
                                        >
                                            {wallet.address}
                                        </Link>
                                    </td>
                                    <td>{(wallet.nativeBalance ?? -1) >= 0 ? wallet.nativeBalance : 'N/A'}</td>
                                    <td>{(wallet.tokenBalance ?? -1) >=0 ? wallet.tokenBalance : 'N/A'}</td>
                                    <td>

                                        {wallet.portfolio ? <Link
                                            underline="none"
                                            color="primary"
                                            component="button"
                                            onClick={() => openModalPortfolio(index)}
                                        >
                                            {wallet.portfolio?.total.toFixed(4)}
                                        </Link> : 'N/A'
                                        }
                                    </td>
                                    <td>Action</td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan={5} align="center">No wallets available</td>
                            </tr>
                        )
                    )}

                    </tbody>
                    {!isLoadingQuery && wallets.length > 0 && (
                        <tfoot>
                        <tr>
                            <td colSpan={5} style={{textAlign:'right' }}>{i18n._({ id: "WalletsPage.showiStartToEndusOfT", message: "showing {startWalletsIndex} to {endWalletsIndex} of {totalWallets}", values: { startWalletsIndex:startWalletIndex, endWalletsIndex:endWalletIndex, totalWallets:totalWallets } })}</td>
                        </tr>
                        </tfoot>
                    )}
                </Table>
            </Sheet>
            {wallets.length > 0 && (
                <Pagination page={page} totalPages={totalPages} isLoadingQuery={isLoadingQuery} handlePageChange={handlePageChange}/>
            )}



            <Modal
                aria-labelledby="modal-title"
                aria-describedby="modal-desc"
                open={isModaPortfolio}
                onClose={() => setIsModaPortfolio(false)}
                sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            >
                <Sheet
                    variant="outlined"
                    sx={{
                        maxWidth: 800,
                        borderRadius: 'md',
                        p: 3,
                        boxShadow: 'lg',
                    }}
                >
                    <ModalClose variant="plain" sx={{ m: 1 }} />
                    <Typography
                        component="h2"
                        id="modal-title"
                        level="h4"
                        textColor="inherit"
                        fontWeight="lg"
                        mb={1}
                    >
                        Multichain Portfolio
                    </Typography>
                    <Table aria-label="basic table">
                        <thead>
                        <tr>
                            <th style={{ width: '10%' }}>Chain</th>
                            <th style={{ width: '10%' }}>Token</th>
                            <th style={{ width: '10%' }}>Portfolio&nbsp;(%)</th>
                            <th style={{ width: '10%' }}>Price&nbsp;($)</th>
                            <th style={{ width: '10%' }}>Amount</th>
                            <th style={{ width: '10%' }}>Value&nbsp;($)</th>
                        </tr>
                        </thead>
                        <tbody>
                        {showPortfolio?.list.map((item, index) => (
                            <tr key={index}>
                                <td>{item.chainName}</td>
                                <td>
                                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                        <img src={item.tokenIcon} alt={item.tokenSymb} style={{ width: '24px', height: '24px' }} />
                                        {item.tokenSymb}
                                    </Box>
                                </td>
                                <td>{item.portfolio.toFixed(4)}</td>
                                <td>{item.price.toFixed(4)}</td>
                                <td>{item.amount.toFixed(4)}</td>
                                <td>{item.value.toFixed(4)}</td>
                            </tr>
                        ))}
                        </tbody>
                        <tfoot>
                        <tr>
                            <th scope="row">Totals</th>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td>{showPortfolio?.total.toFixed(4)}</td>
                        </tr>
                        </tfoot>
                    </Table>
                </Sheet>
            </Modal>
        </>
    );
};

export default WalletsPage;
