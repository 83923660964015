// SingIn.tsx
import React, {useEffect, useState} from 'react';
import {useAuth} from "../../context/GlobalContext";
import {NavLink, useNavigate} from 'react-router-dom';
import { useTheme } from '@mui/joy/styles';

import Box from '@mui/joy/Box';
import CompanyLogoBlock from "../../components/UI/CompanyLogoBlock";
import CopyrightBlock from "../../components/UI/CopyrightBlock";

import GlobalStyles from '@mui/joy/GlobalStyles';

import Typography from "@mui/joy/Typography";
import Button from '@mui/joy/Button';

import FormControl from '@mui/joy/FormControl';
import FormLabel, { formLabelClasses } from '@mui/joy/FormLabel';
import FormHelperText from '@mui/joy/FormHelperText';
import Stack from '@mui/joy/Stack';
import InfoOutlined from '@mui/icons-material/InfoOutlined';

import Link from '@mui/joy/Link';
import Input from '@mui/joy/Input';

import {RotatingSquare} from "react-loader-spinner";


import ColorSchemeToggle from '../../components/ColorSchemeToggle';


import Stepper from '@mui/joy/Stepper';
import Step from '@mui/joy/Step';
import StepIndicator from '@mui/joy/StepIndicator';
import Check from '@mui/icons-material/Check';

import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import FaceIcon from '@mui/icons-material/Face';
import BadgeIcon from '@mui/icons-material/Badge';
import EmailIcon from '@mui/icons-material/Email';
import PasswordIcon from '@mui/icons-material/Password';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import IconButton from "@mui/joy/IconButton";

import {Trans} from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { msg } from "@lingui/macro";
import { i18n } from "@lingui/core"

const SingUpPage = () => {
    const [isPasswordVisible, setIsPasswordVisible] = useState(false);

    const { _ } = useLingui();
    const theme = useTheme(); // Получаем текущую тему
    type FormErrorKeys = keyof typeof formErrors;

    const { user,login, globalSettings } = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        // Если пользователь уже авторизован, перенаправляем на /dashboard
        if (user) {
            navigate('/dashboard');

        }
    }, [user, navigate]);




    const [isLoading, setIsLoading] = useState(false)
    const [activeStep, setActiveStep] = useState(0);
    const [countdown, setCountdown] = useState(5); // Для отсчета времени
    const steps = globalSettings?.user_registration_login
        ? [
            _(msg({ id: "singUpPage.email", message: "Email" })),
            _(msg({ id: "singUpPage.login", message: "Login" })),
            _(msg({ id: "singUpPage.name", message: "Name" })),
            _(msg({ id: "singUpPage.password", message: "Password" })),
            _(msg({ id: "singUpPage.finish", message: "Finish" }))
        ]
        : [
            _(msg({ id: "singUpPage.email", message: "Email" })),
            _(msg({ id: "singUpPage.name", message: "Name" })),
            _(msg({ id: "singUpPage.password", message: "Password" })),
            _(msg({ id: "singUpPage.finish", message: "Finish" }))
        ];

    const [formData, setFormData] = useState({
        email: '',
        login: '',
        name: '',
        password: '',
        confirmPassword: ''
    });

    const [formErrors, setFormErrors] = useState({
        email: false,
        login: false,
        name: false,
        password: false,
        confirmPassword: false
    });

    const [errorMessages, setErrorMessages] = useState({
        email: '',
        login: '',
        name: '',
        password: '',
        confirmPassword: '',
        general:''
    });

    const resetForm = () => {
        setActiveStep(0);
        setFormData({
            email: '',
            login: '',
            name: '',
            password: '',
            confirmPassword: ''
        });

        setFormErrors({
            email: false,
            login: false,
            name: false,
            password: false,
            confirmPassword: false
        });

        setErrorMessages({
            email: '',
            login: '',
            name: '',
            password: '',
            confirmPassword: '',
            general: ''
        });
    };


    const handleNext = async () => {
        let resultCheck = true;
        const emailStepIndex = 0;
        const loginStepIndex = globalSettings?.user_registration_login ? 1 : -1; // -1, если шаг с логином не требуется
        const nameStepIndex = globalSettings?.user_registration_login ? 2 : 1;
        const passwordStepIndex = globalSettings?.user_registration_login ? 3 : 2;
        const finishStepIndex = globalSettings?.user_registration_login ? 4 : 3;

        switch (activeStep) {
            case emailStepIndex:
                resultCheck = await validateEmail();
                break;
            case loginStepIndex:
                if (globalSettings?.user_registration_login) {
                    resultCheck = await validateLogin();
                }
                break;
            case nameStepIndex:
                resultCheck = validateName();
                break;
            case passwordStepIndex:
                resultCheck = validatePassword();
                break;
            case finishStepIndex:
                registration();
                break;
            default:
                throw new Error('Unknown step');
        }

        if (resultCheck) {
            setActiveStep(activeStep + 1);
        }
    };

    const handleBack = () => {
        setActiveStep(activeStep - 1);
    };

    const verifyOnServer = async (key:string, value:string) => {
        setIsLoading(true);
        try {
            const queryParams = new URLSearchParams({ [key]: value }).toString();
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/registration/verify?${queryParams}`, {
                method: 'GET',
                headers: { 'Content-Type': 'application/json' }
            });

            const data = await response.json();
            setIsLoading(false);
            // Возвращаем объект с результатом проверки, статусом и сообщением
            return {
                code: response.status,
                status: response.status === 200,
                message: data.message
            };
        } catch (error) {
            console.error('Error verifying data:', error);
            setIsLoading(false);
            return {
                status: false,
                code: 500,
                message: `Server error:${error}`
            };
        }
    };


    const validateEmail = async () => {
        const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        const isValidEmail = emailRegex.test(formData.email);

        if (!isValidEmail){
            setFormErrors({ ...formErrors, email: !isValidEmail });
            setErrorMessages({ ...errorMessages, email: isValidEmail ? '' : _(msg({ id: "singUpPage.error.invalEmFormat", message: "Invalid email format" })) });

            return false;
        }

        const result = await verifyOnServer('email', formData.email.toLowerCase());

        if (!result.status) {
            setFormErrors({ ...formErrors, email: true });
            if (result.code==202)
                setErrorMessages({ ...errorMessages, email: _(msg({ id: "singUpPage.error.emailIsAlready", message: "Email is already in use" })) });
            else
                setErrorMessages({ ...errorMessages, email: result.message });
            return false
        }


        return true;
    };

    const validateLogin = async () => {
        // Регулярное выражение для проверки логина
        const loginRegex = /^[a-zA-Z0-9_]{5,15}$/;
        const isValidLogin = loginRegex.test(formData.login);

        if (!isValidLogin) {
            setFormErrors({ ...formErrors, login: true });
            setErrorMessages({ ...errorMessages, login: _(msg({ id: "singUpPage.error.loginShortEr", message: "Login must be at least 5 characters long and contain only letters, numbers, and underscores" })) });
            return false;
        }

        const result = await verifyOnServer('login', formData.login.toLowerCase());

        if (!result.status) {
            setFormErrors({ ...formErrors, login: true });
            if (result.code==202)
                setErrorMessages({ ...errorMessages, login: _(msg({ id: "singUpPage.error.loginIsAlready", message: "Login is already in use" })) });
            else
                setErrorMessages({ ...errorMessages, login: result.message });
            return false
        }


        return true;

    };

    const validateName = () => {
        const isValid = formData.name.length >= 3 && formData.name.length <= 20;

        setFormErrors({ ...formErrors, name: !isValid });
        setErrorMessages({ ...errorMessages, name: _(msg({ id: "singUpPage.error.nameIsShort", message: "Name is short" })) });
        return isValid
    };


    const validatePassword = () => {
        // Проверка на длину, наличие цифры и спецсимвола
        const passwordRegex = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-zA-Z]).{5,}$/;
        const isPasswordValid = passwordRegex.test(formData.password);

        if (!isPasswordValid) {
            setFormErrors({ ...formErrors, password: true });
            setErrorMessages({ ...errorMessages, password: _(msg({ id: "singUpPage.error.passwordShort", message: "Password must be at least 5 characters long and include at least one digit and one special character" })) });
            return false;
        }

        // Проверка на совпадение пароля и подтверждения пароля
        const isConfirmPasswordValid = formData.password === formData.confirmPassword;
        if (!isConfirmPasswordValid) {
            setFormErrors({ ...formErrors, confirmPassword: true });
            setErrorMessages({ ...errorMessages, confirmPassword: _(msg({ id: "singUpPage.error.passwordandConfirm", message: "Password and confirm password do not match" })) });
            return false;
        }

        // Если обе проверки пройдены
        return true;
    };

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const name = event.target.name as FormErrorKeys; // Type assertion
        let value = event.target.value;

        // Применяем .toLowerCase() только для login и email
        if (name === 'login' || name === 'email') {
            value = value.toLowerCase();
        }

        setFormData({ ...formData, [name]: value });

        if (formErrors[name]) {
            setFormErrors({ ...formErrors, [name]: false });
            setErrorMessages({ ...errorMessages, [name]: '' });
        }
    };



    const togglePasswordVisibility = () => {
        setIsPasswordVisible(!isPasswordVisible);
    };


    function getStepContent(step: number) {
        const loginStepIndex = globalSettings?.user_registration_login ? 1 : -1; // Если логин не требуется, устанавливаем индекс -1
        const nameStepIndex = globalSettings?.user_registration_login ? 2 : 1; // Индекс для шага с именем
        const passwordStepIndex = globalSettings?.user_registration_login ? 3 : 2; // Индекс для шага с паролем
        const finishIndex = globalSettings?.user_registration_login ? 4 : 3; // Индекс для шага с паролем

        switch (step) {
            case 0:
                return (
                    <FormControl error={formErrors.email}>
                        <FormLabel><Trans id="singUpPage.email">Email</Trans></FormLabel>
                        <Input
                            name="email"
                            startDecorator={<EmailIcon />}
                            placeholder="name@site.com"
                            value={formData.email}
                            onChange={handleInputChange}
                        />
                        {formErrors.email && (
                            <FormHelperText>
                                <InfoOutlined sx={{mr:1}}/>
                                {formErrors.email && errorMessages.email}
                            </FormHelperText>
                        )}
                    </FormControl>
                );
            case loginStepIndex:
                return (
                    <FormControl error={formErrors.login}>
                        <FormLabel><Trans id="singUpPage.login">Login</Trans></FormLabel>
                        <Input
                            name="login"
                            startDecorator={<FaceIcon />}
                            placeholder="nickname"
                            value={formData.login}
                            onChange={handleInputChange}
                            autoComplete='off'
                        />
                        {formErrors.login && (
                            <FormHelperText>
                               <InfoOutlined sx={{mr:1}}/>
                                {formErrors.login && errorMessages.login}
                            </FormHelperText>
                        )}
                    </FormControl>
                );
            case nameStepIndex:
                return (
                    <FormControl error={formErrors.name}>
                        <FormLabel><Trans id="singUpPage.name">Name</Trans></FormLabel>
                        <Input
                            name="name"
                            startDecorator={<BadgeIcon />}
                            placeholder="John"
                            value={formData.name}
                            onChange={handleInputChange}
                            autoComplete='off'
                        />
                        {formErrors.name && (
                            <FormHelperText>
                               <InfoOutlined sx={{mr:1}}/>
                                {formErrors.name && errorMessages.name}
                            </FormHelperText>
                        )}
                    </FormControl>
                );
            case passwordStepIndex:
                return (
                    <>
                        <FormControl error={formErrors.password}>
                            <FormLabel><Trans id="singUpPage.password">Password</Trans></FormLabel>
                            <Input
                                name="password"
                                autoComplete="new-password"
                                startDecorator={<PasswordIcon />}
                                endDecorator={
                                    <IconButton onClick={togglePasswordVisibility}>
                                        {isPasswordVisible ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                    </IconButton>
                                }
                                type={isPasswordVisible ? 'text' : 'password'}
                                value={formData.password}
                                onChange={handleInputChange}
                            />
                            {formErrors.password && (
                                <FormHelperText>
                                    <InfoOutlined sx={{mr:1}}/>
                                    {formErrors.password && errorMessages.password}
                                </FormHelperText>
                            )}
                        </FormControl>
                        <FormControl error={formErrors.confirmPassword}>
                            <FormLabel><Trans id="singUpPage.confirmPassword">Confirm password</Trans></FormLabel>
                            <Input
                                name="confirmPassword"
                                autoComplete="new-password"
                                startDecorator={<PasswordIcon />}
                                type={isPasswordVisible ? 'text' : 'password'}
                                value={formData.confirmPassword}
                                onChange={handleInputChange}
                            />
                            {formErrors.confirmPassword && (
                                <FormHelperText>
                                    <InfoOutlined sx={{mr:1}}/>
                                    {formErrors.confirmPassword && errorMessages.confirmPassword}
                                </FormHelperText>
                            )}
                        </FormControl>
                    </>
                );
            case finishIndex:
                return (
                    <>
                        <FormControl>
                            <FormLabel><Trans id="singUpPage.email">Email</Trans></FormLabel>
                            <Input
                                disabled={true}
                                startDecorator={<EmailIcon />}
                                value={formData.email}
                            />
                        </FormControl>
                        {globalSettings?.user_registration_login == true &&
                            <FormControl>
                                <FormLabel><Trans id="singUpPage.login">Login</Trans></FormLabel>
                                <Input
                                    disabled={true}
                                    startDecorator={<FaceIcon />}
                                    value={formData.login}
                                />
                            </FormControl>
                        }
                        <FormControl>
                            <FormLabel><Trans id="singUpPage.name">Name</Trans></FormLabel>
                            <Input
                                disabled={true}
                                startDecorator={<BadgeIcon />}
                                value={formData.name}
                            />
                        </FormControl>
                    </>
                );
            default:
                throw new Error('Unknown step');
        }
    }


    const registration = async () => {
        setIsLoading(true);
        try {
            const requestBody: { email: string; name: string; password: string; login?: string } = {
                email: formData.email.toLowerCase(),
                name: formData.name,
                password: formData.password,
            };

            if (globalSettings?.user_registration_login) {
                requestBody.login = formData.login.toLowerCase();
            }

            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/registration/singup`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(requestBody)
            });

            if (response.ok) {
                const data = await response.json();
                // Можно выполнить действия после успешной регистрации, например:
                login(data.token, data.user); // Если нужна автоматическая аутентификация после регистрации
                const interval = setInterval(() => {
                    setCountdown((prevCountdown) => {
                        if (prevCountdown === 1) {
                            clearInterval(interval); // Остановка интервала, когда достигнут ноль
                            navigate('/dashboard'); // Перенаправление на dashboard
                            return prevCountdown;
                        }
                        return prevCountdown - 1; // Уменьшение отсчета на 1
                    });
                }, 1000); // Запуск интервала каждую секунду

            } else {
                const errorData = await response.json();
                setErrorMessages(prev => ({ ...prev, general: errorData.message }));
            }
        } catch (error) {
            console.error('Network error:', error);
            setErrorMessages(prev => ({ ...prev, general: 'Network error' }));
        }
        setIsLoading(false);
    };


    if (user) {
        return null
    }
    return (
        <>
            <GlobalStyles
                styles={{
                    ':root': {
                        '--Collapsed-breakpoint': '769px', // form will stretch when viewport is below `769px`
                        '--Cover-width': '50vw', // must be `vw` only
                        '--Form-maxWidth': '800px',
                        '--Transition-duration': '0.4s', // set to `none` to disable transition
                    },
                }}
            />
            <Box
                sx={(theme) => ({
                    width:
                        'clamp(100vw - var(--Cover-width), (var(--Collapsed-breakpoint) - 100vw) * 999, 100vw)',
                    transition: 'width var(--Transition-duration)',
                    transitionDelay: 'calc(var(--Transition-duration) + 0.1s)',
                    position: 'relative',
                    zIndex: 1,
                    display: 'flex',
                    justifyContent: 'flex-end',
                    backdropFilter: 'blur(12px)',
                    backgroundColor: 'rgba(255 255 255 / 0.2)',
                    [theme.getColorSchemeSelector('dark')]: {
                        backgroundColor: 'rgba(19 19 24 / 0.4)',
                    },
                })}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        minHeight: '100dvh',
                        width:
                            'clamp(var(--Form-maxWidth), (var(--Collapsed-breakpoint) - 100vw) * 999, 100%)',
                        maxWidth: '100%',
                        px: 2,
                    }}
                >
                    <Box
                        component="header"
                        sx={{
                            py: 3,
                            display: 'flex',
                            alignItems: 'left',
                            justifyContent: 'space-between',
                        }}
                    >
                        <Box sx={{ gap: 2, display: 'flex', alignItems: 'center' }}>
                            <CompanyLogoBlock />
                        </Box>
                        <ColorSchemeToggle />
                    </Box>
                    <Box
                        component="main"
                        sx={{
                            my: 'auto',
                            py: 2,
                            pb: 5,
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 2,
                            width: 400,
                            maxWidth: '100%',
                            mx: 'auto',
                            borderRadius: 'sm',
                            '& form': {
                                display: 'flex',
                                flexDirection: 'column',
                                gap: 2,
                            },
                            [`& .${formLabelClasses.asterisk}`]: {
                                visibility: 'hidden',
                            },
                        }}
                    >
                        <Stack
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                height: '100%', // или конкретная высота, если вам нужно
                            }}
                        >
                            <Typography level="h2"><Trans id="singUpPage.title">SING UP</Trans></Typography>
                        </Stack>
                        <Stack gap={4} sx={{ mt: 2 }}>
                            <Stepper sx={{ width: '100%' }} size="sm">
                                {steps.map((step, index) => (
                                    <Step
                                        key={step}
                                        indicator={
                                            <StepIndicator
                                                variant={activeStep <= index ? 'soft' : 'solid'}
                                                color={activeStep < index ? 'neutral' : 'primary'}
                                            >
                                                {activeStep <= index ? index + 1 : <Check />}
                                            </StepIndicator>
                                        }
                                        sx={{
                                            '&::after': {
                                                ...(activeStep > index &&
                                                    index !== 2 && { bgcolor: 'primary.solidBg' }),
                                            },
                                        }}
                                    >
                                        {step}
                                    </Step>
                                ))}
                            </Stepper>
                            {activeStep === steps.length ? (
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        height: '100%', // Убедитесь, что у Box есть высота
                                    }}
                                >
                                    {isLoading ? (
                                        <>
                                            <RotatingSquare
                                                visible={true}
                                                height="100"
                                                width="100"
                                                color={theme.vars.palette.text.primary}
                                                ariaLabel="rotating-square-loading"
                                                wrapperStyle={{}}
                                                wrapperClass=""
                                            />
                                            <Typography level="h4" gutterBottom>
                                                <Trans id="singUpPage.reqSent">Registration request sent</Trans>
                                            </Typography>
                                            <Typography level="h4">
                                                <Trans id="singUpPage.reqSentWait">We are waiting for a response</Trans>
                                            </Typography>
                                        </>
                                    ) : errorMessages.general ? (
                                        <>
                                            <Typography level="h4" color="danger" gutterBottom>
                                                <Trans id="singUpPage.error.singUpError01">ERROR SING UP</Trans>
                                            </Typography>
                                            <Typography level="body-sm" color="danger">
                                                {errorMessages.general}
                                            </Typography>
                                            <Button
                                                onClick={resetForm}
                                                sx={{
                                                    mt:2
                                                }}
                                            >
                                                <Trans id="singUpPage.reset">Reset</Trans>
                                            </Button>
                                        </>
                                    ) : (
                                        <>
                                            <Typography level="h4" color="success" gutterBottom>
                                                <Trans id="singUpPage.regComplete">REGISTRATION COMPLETED</Trans>
                                            </Typography>
                                            <Typography level="body-sm" color="success">
                                                <Trans id="singUpPage.goToDashsec">Go to dashboard in</Trans> {countdown} <Trans id="singUpPage.sec">sec</Trans>
                                            </Typography>
                                            <Button
                                                component={NavLink}
                                                to="/dashboard"
                                                sx={{
                                                    mt:2
                                                }}
                                            >
                                                <Trans id="singUpPage.goToDashboard">Go to dashboard</Trans>
                                            </Button>
                                        </>
                                    )}
                                </Box>
                            ) : (
                                <Box>
                                    {getStepContent(activeStep)}
                                    <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                        {activeStep !== 0 && (
                                            <Button
                                                color="neutral"
                                                variant="outlined"
                                                onClick={handleBack} sx={{ mt: 3, ml: 1 }}
                                                startDecorator={<ArrowBackIosIcon/>}
                                                disabled={isLoading}
                                            >
                                                <Trans id="singUpPage.back">Back</Trans>
                                            </Button>
                                        )}
                                        <Button
                                            onClick={handleNext}
                                            sx={{ mt: 3, ml: 1 }}
                                            endDecorator={<ArrowForwardIosIcon/>}
                                            loading={isLoading}
                                        >
                                            {activeStep === steps.length - 1 ? _(msg({ id: "singUpPage.bntRegister", message: "Register" })) : _(msg({ id: "singUpPage.btnNext", message: "Next" }))}
                                        </Button>
                                    </Box>
                                    <Stack>
                                        <Box>
                                            <Typography level="title-sm">
                                                <Trans id="singUpPage.alreadyHaveAcc">Already have an account?</Trans>{' '}
                                                <Link component={NavLink} to="/singin">
                                                    <Trans id="singUpPage.signIn">Sign In</Trans>
                                                </Link>
                                            </Typography>
                                        </Box>
                                    </Stack>
                                </Box>
                            )}
                        </Stack>
                    </Box>
                    <Box component="footer" sx={{ py: 3 }}>
                        <CopyrightBlock />
                    </Box>
                </Box>
            </Box>
            <Box
                sx={(theme) => ({
                    height: '100%',
                    position: 'fixed',
                    right: 0,
                    top: 0,
                    bottom: 0,
                    left: 'clamp(0px, (100vw - var(--Collapsed-breakpoint)) * 999, 100vw - var(--Cover-width))',
                    transition:
                        'background-image var(--Transition-duration), left var(--Transition-duration) !important',
                    transitionDelay: 'calc(var(--Transition-duration) + 0.1s)',
                    backgroundColor: 'background.level1',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    backgroundImage:
                        'url(https://images.unsplash.com/photo-1527181152855-fc03fc7949c8?auto=format&w=1000&dpr=2)',
                    [theme.getColorSchemeSelector('dark')]: {
                        backgroundImage:
                            'url(https://images.unsplash.com/photo-1572072393749-3ca9c8ea0831?auto=format&w=1000&dpr=2)',
                    },
                })}
            />
        </>
    );
};

export default SingUpPage;
