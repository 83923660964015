// LayoutWrapper.tsx
import React from 'react';
import { useLocation } from 'react-router-dom';
import Box from '@mui/joy/Box';
import Sidebar from '../components/Sidebar';
import Header from '../components/Header';
import { Routes, Route } from 'react-router-dom';
import { useAuth } from "../context/GlobalContext";
import ProtectedRoute from "../components/Auth/ProtectedRoute";
import NotFoundPage from "../pages/404/NotFoundPage";
import routes from '../routes';
import NotConnectDB from "../pages/404/NotConnectDB"; // Импорт конфигурации маршрутов
import {i18n} from "@lingui/core";

const LayoutWrapper = () => {
    const location = useLocation();
    const { globalSettings ,user} = useAuth(); // Используйте контекст для проверки авторизации, если нужно



    if (!globalSettings) return null;

    const userRole = user?.role || 'user';
    const dynamicRoutes = routes(globalSettings,i18n,userRole);
    const route = dynamicRoutes.find(r => r.path === location.pathname);

    const isProtected = route?.protected; // Проверка, является ли маршрут защищенным

    if (isProtected) {
        return (
            <Box sx={{ display: 'flex', minHeight: '100dvh' }}>
                <Sidebar />
                <Header />
                {/*
                component="main"
                    className="MainContent"
                    sx={{
                        px: { xs: 2, md: 6 },
                        pt: {
                            xs: 'calc(12px + var(--Header-height))',
                            sm: 'calc(12px + var(--Header-height))',
                            md: 3,
                        },
                        pb: { xs: 2, sm: 2, md: 3 },
                        flex: 1,
                        display: 'flex',
                        flexDirection: 'column',
                        minWidth: 0,
                        height: '100dvh',
                        gap: 1,
                    }}
                */}
                <Box
                    component="main"
                    className="MainContent"
                    sx={{
                        px: { xs: 2, md: 6 },
                        pt: {
                            xs: 'calc(12px + var(--Header-height))',
                            sm: 'calc(12px + var(--Header-height))',
                            md: 3,
                        },
                        pb: { xs: 2, sm: 2, md: 3 },
                        flex: 1,
                        display: 'flex',
                        flexDirection: 'column',
                        minWidth: 0,
                        height: '100dvh',
                        gap: 1,
                        overflow: 'auto',
                    }}
                >
                    <Routes>
                        {dynamicRoutes.map((route, index) => (
                            route.element ? (
                                <Route key={index} path={route.path} element={<ProtectedRoute route={route}><route.element /></ProtectedRoute>} />
                            ) : null
                        ))}
                    </Routes>
                </Box>
            </Box>
        )
    }
    return (
        <Routes>
            {dynamicRoutes.map((route, index) => (
                route.element ? (
                    <Route key={index} path={route.path} element={<route.element />} />
                ) : null
            ))}
            <Route path="*" element={<NotFoundPage />} />
        </Routes>
    );
};

export default LayoutWrapper;



