// LangSelectionModal.tsx
import React, { useState,useEffect } from 'react';
import Box from "@mui/joy/Box";
import Grid from '@mui/joy/Grid';

import Modal from '@mui/joy/Modal';
import ModalClose from '@mui/joy/ModalClose';
import Sheet from '@mui/joy/Sheet';
import Typography from '@mui/joy/Typography';
import Button from "@mui/joy/Button";
import ListItemButton from '@mui/joy/ListItemButton';
import LanguageIcon from '@mui/icons-material/Language';
import CircularProgress from '@mui/joy/CircularProgress';
import DoneOutlineIcon from '@mui/icons-material/DoneOutline';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';

import { i18n } from "@lingui/core";
import { Trans } from "@lingui/macro";
import ReactCountryFlag from "react-country-flag";
import {languages} from "../../langConfig";
import {useAuth} from "../../context/GlobalContext";
import IconButton from "@mui/joy/IconButton";
import LightModeIcon from "@mui/icons-material/LightMode";

interface LangSelectionModalProps {
    triggerType: 'button' | 'listItem' | 'iconbtn';
}

const LangSelectionModal: React.FC<LangSelectionModalProps> = ({ triggerType }) => {
    const { globalChangeLocale} = useAuth();
    const [open, setOpen] = useState(false);
    const [laName, setLaName] = useState('');
    const [coCode, setCoCode] = useState('');
    const [activeLocale, setActiveLocale] = useState('');
    const [isLoading,setIsLoading] =useState(false);
    const [isErrorLangSelect, setIsErrorLangSelect] = useState(false);
    const [progress, setProgress] = useState(0); // Добавлено состояние прогресса


    const handleOpen = () => {
        setIsLoading(false);
        setIsErrorLangSelect(false)
        setOpen(true);
        setActiveLocale(i18n.locale);
    };

    const handleClose = () => setOpen(false);

    useEffect(() => {
        // Определение текущей активной локали

        setActiveLocale(i18n.locale);
    }, []);

    useEffect(() => {
        // Поиск названия и кода страны текущей локали
        const currentLanguage = languages.find(lang => lang.langCode === activeLocale);
        if (currentLanguage) {
            setLaName(currentLanguage.name);
            setCoCode(currentLanguage.countryCode);
        }
        globalChangeLocale(activeLocale)
    }, [activeLocale]);


    const changeLocale = async (locale: string) => {
        setIsLoading(true);
        setIsErrorLangSelect(false);
        setProgress(10); // Начальное значение прогресса

        try {
            const [messagesModule] = await Promise.all([
                import(`../../locales/${locale}/messages`),
                new Promise(resolve => setTimeout(resolve, 1000)) // Задержка минимум 2 секунды
            ]);

            const { messages } = messagesModule;
            i18n.load(locale, messages);
            i18n.activate(locale);
            setActiveLocale(locale);
            setProgress(100); // Завершение загрузки
            document.documentElement.setAttribute('lang', locale);
            localStorage.setItem('lang', locale);
        } catch (error) {
            console.error(`Could not load the language package for ${locale}: `, error);

            setTimeout(() => {
                setIsErrorLangSelect(true); // Установка ошибки
                setProgress(100); // Сброс прогресса
            }, 1000);
        } finally {
            //setIsLoading(false); // Остановка загрузки
        }
    };

    // Расчет количества столбцов
    // Определяем оптимальное количество столбцов
    const maxColumns = 4; // Максимальное количество столбцов
    let columnsCount = Math.min(maxColumns, languages.length); // Ограничиваем максимальным количеством и общим количеством языков



    return (
        <>
            {triggerType === 'iconbtn' && (
                <IconButton
                    size="sm"
                    variant="outlined"
                    color="neutral"
                    onClick={handleOpen}
                >
                    <LanguageIcon />
                </IconButton>
            )}
            {triggerType === 'button' && (
                <Button onClick={handleOpen}>Change Language</Button>
            )}
            {triggerType === 'listItem' && (
                <ListItemButton onClick={handleOpen}>
                    <LanguageIcon />
                    {laName}
                </ListItemButton>
            )}
            <Modal

                open={open}

                aria-labelledby="nested-modal-title"
                aria-describedby="nested-modal-description"
                sx={(theme) => ({
                    [theme.breakpoints.only('xs')]: {
                        top: 'unset',
                        bottom: 0,
                        left: 0,
                        right: 0,
                        borderRadius: 0,
                        transform: 'none',
                        maxWidth: 'unset',
                    },
                    display: 'flex', justifyContent: 'center', alignItems: 'center'
                })}
            >
                <Sheet
                    variant="outlined"
                    sx={{
                        maxWidth: 700,
                        minWidth:300,
                        borderRadius: 'md',
                        p: 3,
                        boxShadow: 'lg',
                    }}
                >
                    <ModalClose variant="plain" sx={{ m: 1 }} onClick={handleClose} disabled={false}/>

                        <Typography
                            component="h2"
                            id="modal-title"
                            level="h4"
                            textColor="inherit"
                            fontWeight="lg"
                            mb={1}
                        >
                            <Trans id="LangSelectionModal.title">Select your language</Trans>

                        </Typography>
                    {!isLoading && (
                        <Typography id="modal-desc" textColor="text.tertiary">
                            <Trans id="LangSelectionModal.description">available languages</Trans>
                        </Typography>
                    )}

                    {/* Динамическое создание кнопок для каждого языка */}

                    {isLoading ? (
                        <Box>
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    height: '100%',
                                    mt:2

                                }}
                            >
                                <CircularProgress
                                    color={isErrorLangSelect ? "danger" : "success"}
                                    value={progress}
                                    sx={{ '--CircularProgress-size': '80px' }}
                                >
                                    {progress === 100 && (
                                        isErrorLangSelect ?
                                            <Typography color="danger" level="h3"><PriorityHighIcon  /></Typography> :
                                            <DoneOutlineIcon color="success" />
                                    )}
                                </CircularProgress>
                            </Box>
                            <Box
                                sx={{
                                    mt:2
                                }}
                            >
                                {progress === 100 && (isErrorLangSelect ? (
                                    <Typography color="danger">
                                        <Trans id="LangSelectionModal.error.selected">The {laName} language has been selected.</Trans>
                                    </Typography>
                                ):(
                                    <Typography color="success">
                                        <Trans id="LangSelectionModal.succes.selected">The {laName}  language has been selected.</Trans>
                                    </Typography>
                                ))}

                            </Box>
                        </Box>

                    ):(
                        <Grid container spacing={2} sx={{ mt: 2 }}>
                        {/* Генерация столбцов */}
                        {[...Array(columnsCount)].map((_, columnIndex) => (
                            <Grid key={columnIndex} xs={12 / columnsCount}>
                                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                    {languages
                                        .slice(
                                            columnIndex * Math.ceil(languages.length / columnsCount),
                                            (columnIndex + 1) * Math.ceil(languages.length / columnsCount)
                                        )
                                        .map(({ langCode, name, countryCode }) => (
                                            <Button
                                                key={langCode}
                                                variant="plain"
                                                onClick={() => changeLocale(langCode)}
                                                sx={{
                                                    display: 'flex',
                                                    justifyContent: 'space-between',
                                                    width: '100%',
                                                    alignItems: 'center',
                                                    my: 1, // margin for the Button
                                                }}
                                                disabled={langCode === activeLocale}
                                            >
                                                <ReactCountryFlag
                                                    style={{ fontSize: '1.5em', marginRight: '5px' }}
                                                    countryCode={countryCode.toUpperCase()}
                                                />
                                                <span style={{ flex: 1, textAlign: 'right' }}>{name}</span>
                                            </Button>
                                        ))}
                                </Box>
                            </Grid>
                        ))}
                    </Grid>
                    )}
                </Sheet>
            </Modal>
        </>
    );
};

export default LangSelectionModal;