import React, { useState, useEffect } from 'react';
import {
    Box,
    Chip,
    Link,
    Typography,
    List,
    ListItem,
    ListItemContent,
    ListItemDecorator,
    IconButton,
    LinearProgress,
    Modal,
    ModalDialog,
    ModalClose,
    Sheet,
    Input,
    Button, Divider, Avatar, ListDivider,
} from '@mui/joy';
import { i18n } from '@lingui/core';
import {
    getRoleDisplayColor,
    getRoleDisplayIcon,
    getRoleDisplayName
} from "../../../../../components/UI/getRoleDisplayName";
import { Invitation } from './userInterfaces';
import DoneIcon from '@mui/icons-material/Done';
import PauseCircleOutlineIcon from '@mui/icons-material/PauseCircleOutline';
import SearchIcon from '@mui/icons-material/Search';
import SearchOffIcon from '@mui/icons-material/SearchOff';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import HourglassBottomIcon from "@mui/icons-material/HourglassBottom";
import PersonOffIcon from "@mui/icons-material/PersonOff";
import PersonIcon from "@mui/icons-material/Person";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import {Trans} from "@lingui/macro";
import { msg } from "@lingui/macro";
import { useLingui } from "@lingui/react";


interface InvitationsListProps {
    invitations: Invitation[];
    isLoading: boolean;
    page: number;
    limit: number;
    totalPages: number;
    totalInvitations: number;
    handlePageChange: (newPage: number) => void;
    renderFilters: () => JSX.Element;
    rowMenu: (rowID: number) => JSX.Element | null;

    searchQuery: string;
    searchPreQuery: string;
    setSearchQuery: (query: string) => void;
    setSearchPreQuery: (query: string) => void;
}

const InvitationsList: React.FC<InvitationsListProps> = (
    {
        invitations,
        isLoading,
        page,
        totalPages,
        handlePageChange,
        renderFilters,
        rowMenu,

        searchQuery,
        searchPreQuery,
        setSearchQuery,
        setSearchPreQuery,
    }) => {
    const { _ } = useLingui();
    const [isModalOpen, setIsModalOpen] = React.useState(false);

    useEffect(() => {
        setIsModalOpen(false)
    }, [invitations]);

    return (
        <>
            <Sheet className="SearchAndFilters-mobile" sx={{display: { xs: 'flex', sm: 'none' }, my: 1, gap: 1,}}>
                <Input
                    size="sm"
                    placeholder={i18n._({ id: "InvitationsList.searc", message: "Search" })}
                    sx={{ flexGrow: 1 }}
                    startDecorator={<SearchIcon />}
                    onChange={(event) => {
                        if (event.target.value === '')
                        {
                            setSearchQuery("")
                            setSearchPreQuery("")
                        } else {
                            setSearchPreQuery(event.target.value)
                        }

                    }}
                    value={searchPreQuery}
                    disabled={isLoading}
                    endDecorator={
                        searchPreQuery.length > 0 && (
                            searchQuery === searchPreQuery ? (
                                <Button
                                    startDecorator={<SearchOffIcon/>}
                                    onClick={(event)=>{
                                        setSearchPreQuery("");
                                        setSearchQuery("");
                                    }}
                                >
                                    <Trans id="InvitationsList.clear">Clear</Trans>
                                </Button>
                            ) : (
                                <Button
                                    onClick={(event)=>{setSearchQuery(searchPreQuery)}}
                                    startDecorator={<SearchIcon/>}
                                >
                                    <Trans id="InvitationsList.searc">Search</Trans>
                                </Button>
                            )
                        )
                    }
                />
                <IconButton
                    size="sm"
                    variant="outlined"
                    color="neutral"
                    onClick={() => setIsModalOpen(true)}
                >
                    <FilterAltIcon />
                </IconButton>
                <Modal open={isModalOpen} onClose={() => setIsModalOpen(false)}>
                    <ModalDialog aria-labelledby="filter-modal" layout="fullscreen">
                        <ModalClose />
                        <Typography id="filter-modal" level="h2">
                            <Trans id="InvitationsList.filte">Filters</Trans>
                        </Typography>
                        <Divider sx={{ my: 2 }} />
                        <Sheet sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                            {renderFilters()}
                        </Sheet>
                    </ModalDialog>
                </Modal>
            </Sheet>
            <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
                {!isLoading && (
                    invitations.length < 1 ? (
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'center', // Center horizontally
                                alignItems: 'center', // Center vertically
                                height: '100%', // Ensure the container takes up the full height
                                width: '100%', // Ensure the container takes up the full width
                            }}
                        >
                            <Typography>
                                <Trans id="InvitationsList.noResul">No results</Trans>
                            </Typography>
                        </Box>
                    ) : (
                        invitations.map((row,index) => (
                            <List
                                key={row.id}
                                size="sm"
                                sx={{
                                    '--ListItem-paddingX': 0,
                                }}
                            >
                                <ListItem
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'start',
                                    }}
                                >
                                    <ListItemContent sx={{ display: 'flex', gap: 2, alignItems: 'start' }}>
                                        <ListItemDecorator>
                                            <Avatar size="sm" />
                                        </ListItemDecorator>
                                        <div>
                                            <Typography fontWeight={600} gutterBottom>
                                                {row.name}
                                            </Typography>
                                            <Typography level="body-xs" gutterBottom>
                                                {row.email}
                                            </Typography>
                                        </div>
                                    </ListItemContent>
                                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'end' }}>
                                        <Box sx={{ display: 'flex', gap: 1, alignItems: 'center', mb: 1 }}>
                                            <Chip
                                                variant="soft"
                                                size="sm"
                                                startDecorator={
                                                    getRoleDisplayIcon(row.role,i18n)
                                                }
                                                color={getRoleDisplayColor(row.role,i18n)}
                                            >
                                                {getRoleDisplayName(row.role,i18n)}
                                            </Chip>
                                            <Chip
                                                variant="soft"
                                                size="sm"
                                                color={row.accepted ? 'success' : 'danger'}
                                            >
                                                {row.accepted ? <DoneIcon/> : <PauseCircleOutlineIcon/>}
                                            </Chip>

                                        </Box>
                                        {rowMenu(index)}
                                    </Box>


                                </ListItem>
                                <ListDivider />
                            </List>
                        ))
                    )
                )}
            </Box>
            <Box
                className="Pagination-mobile"
                sx={{ display: { xs: 'flex', md: 'none' }, alignItems: 'center', py: 2 }}
            >
                <IconButton
                    aria-label="previous page"
                    variant="outlined"
                    color="neutral"
                    size="sm"
                    onClick={() => handlePageChange(page - 1)}
                    disabled={page === 1 || isLoading}
                >
                    <KeyboardArrowLeftIcon />
                </IconButton>
                {isLoading ? (
                    <LinearProgress
                        variant="plain"
                        size="sm"
                        sx={{
                            width: '100%', // Ширина равна 100% родительского элемента
                            borderRadius: 0, // Убираем скругление
                            zIndex:11,
                        }}
                    />
                ):(

                    <Typography level="body-sm" mx="auto">
                        {invitations.length > 0 && (i18n._({ id: "InvitationsList.pagePageOfTotal", message: "Page {page} of {totalPages}", values: { page:page, totalPages:totalPages } }))}
                    </Typography>

                )}

                <IconButton
                    aria-label="next page"
                    variant="outlined"
                    color="neutral"
                    size="sm"
                    onClick={() => handlePageChange(page + 1)}
                    disabled={page >= totalPages || isLoading}
                >
                    <KeyboardArrowRightIcon />
                </IconButton>
            </Box>
        </>
    );
};

export default InvitationsList;
