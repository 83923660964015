// SingIn.tsx
import React,{useState,useEffect} from 'react';
import {useAuth} from "../../context/GlobalContext";
import {NavLink, useNavigate} from 'react-router-dom';


import Box from '@mui/joy/Box';
import CompanyLogoBlock from "../../components/UI/CompanyLogoBlock";
import CopyrightBlock from "../../components/UI/CopyrightBlock";

import GlobalStyles from '@mui/joy/GlobalStyles';


import Button from '@mui/joy/Button';
import Checkbox from '@mui/joy/Checkbox';

import FormControl from '@mui/joy/FormControl';
import FormLabel, { formLabelClasses } from '@mui/joy/FormLabel';

import FormHelperText from '@mui/joy/FormHelperText';
import Link from '@mui/joy/Link';
import Input from '@mui/joy/Input';

import Stack from '@mui/joy/Stack';

import ColorSchemeToggle from '../../components/ColorSchemeToggle';
import Tooltip from '@mui/joy/Tooltip';
import InfoOutlined from '@mui/icons-material/InfoOutlined';
import Typography from "@mui/joy/Typography";

import {Trans} from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { msg } from "@lingui/macro";
import { i18n } from "@lingui/core"

interface FormElements extends HTMLFormControlsCollection {
    login: HTMLInputElement;
    password: HTMLInputElement;
    persistent: HTMLInputElement;
}
interface SignInFormElement extends HTMLFormElement {
    readonly elements: FormElements;
}


const SingInPage = () => {
    const { _ } = useLingui();
    const {user, login,globalSettings } = useAuth();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] =useState(false);

    const [formErrors, setFormErrors] = useState({ login: '', password: '', general: '' });

    const validateLogin = (login : String) => {
        if (login.length < 5) {
            setFormErrors(prev => ({ ...prev, login: _(msg({ id: "singInPage.error.login.short", message: "Login must be at least 5 characters long" })) }));
            return false;
        }
        setFormErrors(prev => ({ ...prev, login: '' }));
        return true;
    };

    useEffect(() => {
        // Если пользователь уже авторизован, перенаправляем на /dashboard
        if (user) {
            navigate('/dashboard');
        }
    }, [user, navigate]);

    const validatePassword = (password: String) => {
        if (password.length < 5) {
            setFormErrors(prev => ({ ...prev, password: _(msg({ id: "singInPage.error.password.short", message: "Password must be at least 5 characters long" })) }));
            return false;
        }
        setFormErrors(prev => ({ ...prev, password: '' }));
        return true;
    };

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setIsLoading(true);
        setFormErrors({ login: '', password: '', general: '' }); // Очищаем ошибки при новой попытке отправки

        const formData = new FormData(event.currentTarget);
        const loginname = formData.get('login') as string;
        const password = formData.get('password') as string;
        const persistent = formData.get('persistent') === 'on'; // Проверяем, установлен ли флажок

        const isLoginValid = validateLogin(loginname);
        const isPasswordValid = validatePassword(password);

        if (!isLoginValid || !isPasswordValid) {
            setIsLoading(false);
            return;
        }

        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/auth/login`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ login: loginname, password: password, persistent: persistent })
        });

        if (response.ok) {
            const data = await response.json();
            login(data.token,data.user); // Обновляем состояние аутентификации
            navigate('/dashboard');
        } else if (response.status === 401) {
            setFormErrors(prev => ({...prev, general: _(msg({ id: "singInPage.error.Invalidlogin", message: "Invalid login or password" }))}));
        } else if (response.status === 429) {
            const errorData = await response.json();
            setFormErrors(prev => ({ ...prev, general: i18n._({ id: "singInPage.error.toomanylogin", message: "Too many login attempts, please try again after {retryAfter} minutes",values: { retryAfter:errorData.retryAfter } }) }));
        } else {
            const errorData = await response.json();
            console.log (response)
            setFormErrors(prev => ({ ...prev, general: errorData.error }));
        }
        setIsLoading(false);
    };

    if (user) {
        return null
    }

    return (
        <>
            <GlobalStyles
                styles={{
                    ':root': {
                        '--Collapsed-breakpoint': '769px', // form will stretch when viewport is below `769px`
                        '--Cover-width': '50vw', // must be `vw` only
                        '--Form-maxWidth': '800px',
                        '--Transition-duration': '0.4s', // set to `none` to disable transition
                    },
                }}
            />
            <Box
                sx={(theme) => ({
                    width:
                        'clamp(100vw - var(--Cover-width), (var(--Collapsed-breakpoint) - 100vw) * 999, 100vw)',
                    transition: 'width var(--Transition-duration)',
                    transitionDelay: 'calc(var(--Transition-duration) + 0.1s)',
                    position: 'relative',
                    zIndex: 1,
                    display: 'flex',
                    justifyContent: 'flex-end',
                    backdropFilter: 'blur(12px)',
                    backgroundColor: 'rgba(255 255 255 / 0.2)',
                    [theme.getColorSchemeSelector('dark')]: {
                        backgroundColor: 'rgba(19 19 24 / 0.4)',
                    },
                })}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        minHeight: '100dvh',
                        width:
                            'clamp(var(--Form-maxWidth), (var(--Collapsed-breakpoint) - 100vw) * 999, 100%)',
                        maxWidth: '100%',
                        px: 2,
                    }}
                >
                    <Box
                        component="header"
                        sx={{
                            py: 3,
                            display: 'flex',
                            alignItems: 'left',
                            justifyContent: 'space-between',
                        }}
                    >
                        <div style={{ gap: 2, display: 'flex', alignItems: 'center' }}>
                            <CompanyLogoBlock />
                        </div>
                        <ColorSchemeToggle />
                    </Box>
                    <Box
                        component="main"
                        sx={{
                            my: 'auto',
                            py: 2,
                            pb: 5,
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 2,
                            width: 400,
                            maxWidth: '100%',
                            mx: 'auto',
                            borderRadius: 'sm',
                            '& form': {
                                display: 'flex',
                                flexDirection: 'column',
                                gap: 2,
                            },
                            [`& .${formLabelClasses.asterisk}`]: {
                                visibility: 'hidden',
                            },
                        }}
                    >
                        <Stack
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                height: '100%', // или конкретная высота, если вам нужно
                            }}
                        >
                            <Typography level="h2"><Trans id="singInPage.title">SING IN</Trans></Typography>
                        </Stack>
                        <Stack gap={4} sx={{ mt: 2 }}>
                            <form onSubmit={handleSubmit} >
                                <FormControl required disabled={isLoading} error={!!formErrors.login}>
                                    <FormLabel>
                                        {globalSettings?.user_login_email && globalSettings?.user_login_login
                                            ? <Trans id="singInPage.loginOrEmail">Login or email</Trans>
                                            : globalSettings?.user_login_email
                                                ? <Trans id="singInPage.email">Email</Trans>
                                                : <Trans id="singInPage.login">Login</Trans>}
                                    </FormLabel>
                                    <Input type="text" name="login" />
                                    {formErrors.login && <FormHelperText>{formErrors.login}</FormHelperText>}
                                </FormControl>
                                <FormControl required disabled={isLoading} error={!!formErrors.password}>
                                    <FormLabel><Trans id="singInPage.password">Password</Trans></FormLabel>
                                    <Input type="password" name="password" />
                                    {formErrors.password && <FormHelperText>{formErrors.password}</FormHelperText>}
                                </FormControl>

                                <Stack gap={4} sx={{ mt: 2 }}>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <FormControl disabled={isLoading}>
                                            <Tooltip title={_(msg({ id: "singInPage.rememberToolTip", message: "will keep you logged in for 30 days" }))} placement="top">
                                                <Checkbox
                                                    size="sm"
                                                    label={_(msg({ id: "singInPage.rememberMe", message: "Remember me" }))}
                                                    name="persistent"
                                                />
                                            </Tooltip>
                                        </FormControl>
                                        {globalSettings && globalSettings.user_password_recovery && (
                                            <Link component={NavLink} to="/forgot-password" level="title-sm" disabled={isLoading}>
                                                <Trans id="singInPage.forgotPass">Forgot your password?</Trans>
                                            </Link>
                                            )}
                                    </Box>
                                    <FormControl required disabled={isLoading} error={!!formErrors.general}>
                                        <Button loading={isLoading} type="submit" fullWidth><Trans id="singInPage.signin">Sign in</Trans></Button>
                                        {formErrors.general && <FormHelperText><InfoOutlined /> {formErrors.general}</FormHelperText>}
                                    </FormControl>
                                </Stack>
                            </form>
                        </Stack>
                        {globalSettings && globalSettings.user_registration && (
                            <Stack>
                                <Box>
                                    <Typography level="title-sm">
                                        <Trans id="singInPage.dontHaveAcc">Don't have an account?</Trans>{' '}
                                        <Link component={NavLink} to="/singup" disabled={isLoading}>
                                            <Trans id="singInPage.signUp">Sign Up</Trans>
                                        </Link>
                                    </Typography>
                                </Box>
                            </Stack>
                        )}
                    </Box>
                    <Box component="footer" sx={{ py: 3 }}>
                        <CopyrightBlock />
                    </Box>
                </Box>
            </Box>
            <Box
                sx={(theme) => ({
                    height: '100%',
                    position: 'fixed',
                    right: 0,
                    top: 0,
                    bottom: 0,
                    left: 'clamp(0px, (100vw - var(--Collapsed-breakpoint)) * 999, 100vw - var(--Cover-width))',
                    transition:
                        'background-image var(--Transition-duration), left var(--Transition-duration) !important',
                    transitionDelay: 'calc(var(--Transition-duration) + 0.1s)',
                    backgroundColor: 'background.level1',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    backgroundImage:
                        'url(imgs/lightwpbc.png)',
                    [theme.getColorSchemeSelector('dark')]: {
                        backgroundImage:
                            'url(imgs/darkbcwp.png)',
                    },
                })}
            />
        </>
    );
};

export default SingInPage;
