// src/components/LoadingSpinner.tsx
import React from 'react';
import Box from '@mui/joy/Box';
import { useTheme } from '@mui/joy/styles';
import {Watch} from "react-loader-spinner";

const LoadingSpinner = () => {
    const theme = useTheme(); // Получаем текущую тему
    return (
        <Box sx={{ display: 'flex', minHeight: '100dvh' }}>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    maxWidth: 300,
                    margin: 'auto',
                    gap: 2,

                }}
            >

                <Watch
                    visible={true}
                    height="80"
                    width="80"
                    radius="48"
                    color={theme.vars.palette.text.primary}
                    ariaLabel="watch-loading"
                    wrapperStyle={{}}
                    wrapperClass=""
                />
            </Box>
        </Box>
    );
};

export default LoadingSpinner;
