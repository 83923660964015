// InvitationsPage.tsx
import React,{useState,useEffect,JSX} from 'react';
import Box from '@mui/joy/Box';
import Typography from "@mui/joy/Typography";
import Sheet from "@mui/joy/Sheet";
import Button from "@mui/joy/Button";
import ModalDialog from "@mui/joy/ModalDialog";
import ModalClose from "@mui/joy/ModalClose";
import DialogTitle from "@mui/joy/DialogTitle";
import DialogContent from "@mui/joy/DialogContent";
import Modal from "@mui/joy/Modal";
import DialogActions from "@mui/joy/DialogActions";
import Stack from "@mui/joy/Stack";
import FormControl from "@mui/joy/FormControl";
import Input from "@mui/joy/Input";
import FormLabel from "@mui/joy/FormLabel";
import Select from "@mui/joy/Select";
import Option from '@mui/joy/Option';
import {getAllRoles} from "../../../../components/UI/getRoleDisplayName";
import {i18n} from "@lingui/core";
import IconButton from "@mui/joy/IconButton";
import Switch from "@mui/joy/Switch";

import TextIncreaseIcon from '@mui/icons-material/TextIncrease';
import FormHelperText from "@mui/joy/FormHelperText";
import CustomSnackbar from "../../../../components/UI/CustomSnackbar";
import InvitationsTable from "./components/InvitationsTable";

import {Invitation} from "./components/userInterfaces";
import Dropdown from "@mui/joy/Dropdown";
import MenuButton from "@mui/joy/MenuButton";
import Menu from "@mui/joy/Menu";
import MenuItem from "@mui/joy/MenuItem";
import MoreHorizRoundedIcon from "@mui/icons-material/MoreHorizRounded";
import InfoIcon from "@mui/icons-material/Info";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import PersonIcon from "@mui/icons-material/Person";
import PersonOffIcon from "@mui/icons-material/PersonOff";
import RoomPreferencesIcon from "@mui/icons-material/RoomPreferences";
import Divider from "@mui/joy/Divider";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import InvitationsList from "./components/InvitationsList";
import {Trans} from "@lingui/macro";
import { msg } from "@lingui/macro";
import { useLingui } from "@lingui/react";

const InvitationsPage = () => {
    const { _ } = useLingui();
    const [isLoadingQuery,setIsLoadingQuery] = useState(false);
    const [isSendingQuery,setIsSendingQuery] = useState(false);
    const [isOpenSb, setIsOpenSb] = useState(false);
    const [messageSb, setMessageSb] = useState('');

    const [isModalAddInvite,setIsModalAddInvite] =useState(false)
    const roles = getAllRoles(i18n); // Получаем все роли

    const [isOpenModalResend, setIsOpenModalResend] = useState(false);
    const [invtitationSelected, setInvtitationSelected] = useState<number | null>(null);
    const [invtitationSelectedData, setInvtitationSelectedData] = useState<Invitation | null>(null);
    const [isOpenModalDelete, setIsOpenModalDelete] = useState(false);



    const [inviteFormData, setInviteFormData] = useState({
        email: '',
        name: '',
        role: 'user',
    });
    const [inviteFormErrors, setInviteFormErrors] = useState({
        email: false,
        name: false,
        role: false,
    });
    const [inviteErrorMessages, setInviteErrorMessages] = useState({
        email: '',
        name: '',
        role: '',
    });



    const verifyOnServer = async (key:string, value:string) => {
        try {
            const queryParams = new URLSearchParams({ [key]: value }).toString();
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/registration/verify?${queryParams}`, {
                method: 'GET',
                headers: { 'Content-Type': 'application/json' }
            });
            const data = await response.json();

            return {
                status: response.status === 200,
                message: data.message
            };
        } catch (error) {
            return {
                status: false,
                message: `Server error: ${error}`
            };
        }
    };


    const handleInviteInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setInviteFormData({ ...inviteFormData, [name]: value });
    };

    const handleInviteSelectChange = (name: string, newValue: string | null) => {
        setInviteFormData({ ...inviteFormData, [name]: newValue });
    };

    const sendInvite = async (inviteData: {}) => {
        try {
            const token = localStorage.getItem('authToken'); // Получение токена из локального хранилища

            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/admin/users/add-invitation`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify(inviteData)
            });

            const data = await response.json();
            if (response.ok) {
                return true; // Обработка успешного создания приглашения
            } else {
                console.error('Error sending invite:', data.message);
                // Обработка ошибок при создании приглашения
                return false;
            }
        } catch (error) {
            console.error('Network error:', error);
            return false;
        }
    };

    const sendInviteForm = async () => {
        setIsLoadingQuery(true); // Используем общее состояние загрузки
        let isValid = true;
        const errors = { ...inviteFormErrors };
        const messages = { ...inviteErrorMessages };

        // Валидация email
        const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        if (!emailRegex.test(inviteFormData.email)) {
            errors.email = true;
            messages.email = _(msg({ id: "InvitationsPage.invalEmailForma", message: "Invalid email format" }));
            isValid = false;
        } else {
            // Проверка уникальности email
            const emailResult = await verifyOnServer('email', inviteFormData.email);
            if (!emailResult.status) {
                errors.email = true;
                messages.email = emailResult.message;
                isValid = false;
            } else {
                errors.email = false;
                messages.email = '';
            }
        }

        // Валидация имени
        if (inviteFormData.name.length < 3) {
            errors.name = true;
            messages.name = _(msg({ id: "InvitationsPage.nameMustBeAtLeastCha", message: "Name must be at least 3 characters" }));
            isValid = false;
        } else {
            errors.name = false;
            messages.name = '';
        }

        setInviteFormErrors(errors);
        setInviteErrorMessages(messages);

        if (isValid) {
            const result = await sendInvite(inviteFormData);
            if (result) {
                setMessageSb(i18n._({ id: "InvitationsPage.invitSentSucce", message: "Invitation sent successfully" }));
                setIsOpenSb(true);
                // Очистка формы после успешной отправки
                setInviteFormData({
                    email: '',
                    name: '',
                    role: 'user',
                });
                setInviteFormErrors({
                    email: false,
                    name: false,
                    role: false,
                });
                setIsModalAddInvite(false); // Закрытие модального окна
                fetchInvitations()
            } else {
                // Обработать случай, когда приглашение не было успешно создано
            }
        }
        setIsLoadingQuery(false);
    };


    // ---------------------
    // Состояние для приглашений
    const [invitations, setInvitations] = useState<Invitation[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    // Состояние для пагинации
    const [page, setPage] = useState<number>(1);
    const [limit, setLimit] = useState<number>(25);
    const [totalInvitations, setTotalInvitations] = useState<number>(0); // Общее количество приглашений

    // Состояние для фильтров
    const [accepted, setAccepted] = useState<string>('false');
    const [searchQuery, setSearchQuery] = useState<string>('');
    const [searchPreQuery, setSearchPreQuery] = useState('');

    // Функция для получения приглашений
    const fetchInvitations = async () => {
        setInvitations([]);
        setIsLoading(true);
        try {
            const token = localStorage.getItem('authToken'); // Получаем токен из localStorage
            const queryParams = {
                page: page.toString(),
                limit: limit.toString(),
                accepted,
                searchQuery
            };

            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/admin/users/get-all-invitations`, {
                method: 'POST', // Изменение метода на POST
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}` // Передаем токен в заголовке запроса
                },
                body: JSON.stringify(queryParams) // Передаем параметры в теле запроса
            });

            if (!response.ok) {
                throw new Error('Failed to fetch invitations');
            }

            const data = await response.json();
            setInvitations(data.invitations);
            setTotalInvitations(data.totalInvitations);
        } catch (error) {
            console.error('Error fetching invitations:', error);
        } finally {
            setIsLoading(false);
        }
    };

    // Получение приглашений при монтировании и изменении состояния фильтров или пагинации
    useEffect(() => {
        fetchInvitations();
    }, [page, limit, accepted, searchQuery]);

    // Обработчики для поиска, фильтров и пагинации
    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPage(1);
        setSearchQuery(event.target.value);
    };


    const handleAcceptedChange = (
        event: React.SyntheticEvent | null,
        value: string | null
    ) => {
        if (value !== null) {
            if (value !== null) {
                setPage(1);
                setAccepted(value);
            } else {
                console.error("Invalid number input for limit");
            }
        }
    };

    const handlePageChange = (newPage: number) => {
        setPage(newPage);
    };

    const handleLimitChange = (
        event: React.SyntheticEvent | null,
        value: number | null
    ) => {
        if (value !== null) {
            if (!isNaN(value)) {
                setPage(1)
                setLimit(value);
            } else {
                console.error("Invalid number input for limit");
            }
        }
    };

    const renderFilters = () => {
        return (
            <React.Fragment>
                <FormControl size="sm">
                    <FormLabel><Trans id="InvitationsPage.accep">Accepted</Trans></FormLabel>
                    <Select
                        size="sm"
                        value={accepted}
                        onChange={handleAcceptedChange}
                        disabled={isLoading}
                    >
                        <Option value="all"><Trans id="InvitationsPage.all">All</Trans></Option>
                        <Option value="true"><Trans id="InvitationsPage.accep">Accepted</Trans></Option>
                        <Option value="false"><Trans id="InvitationsPage.notAccep">Not Accepted</Trans></Option>
                    </Select>
                </FormControl>

                {/* Поисковое поле */}
                <FormControl size="sm">
                    <FormLabel><Trans id="InvitationsPage.rowLimit">Row Limit</Trans></FormLabel>
                    <Select
                        size="sm"
                        slotProps={{ button: { sx: { whiteSpace: 'nowrap' } } }}
                        value={limit}
                        onChange={handleLimitChange}
                        disabled={isLoading}
                    >
                        <Option value={25}>25</Option>
                        <Option value={50}>50</Option>
                        <Option value={100}>100</Option>
                    </Select>
                </FormControl>
            </React.Fragment>
        );
    };


    const handleShowInvitationResend = (rowID:number) => {
        setInvtitationSelected(rowID)
        setInvtitationSelectedData(invitations[rowID]);
        setIsOpenModalResend(true);
    }

    const resendingEmailInvitation = async () => {
        if (invtitationSelected === null) return;
        setIsSendingQuery(true);
        try {
            const token = localStorage.getItem('authToken');
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/admin/users/resend-invitation`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({ invitationId: invitations[invtitationSelected]?.id })
            });

            const data = await response.json();
            if (response.ok) {
                const updatedInvitations = [...invitations];
                updatedInvitations[invtitationSelected].lastSendInvitation = data.lastSendInvitation;
                setInvitations(updatedInvitations);
                setMessageSb(_(msg({ id: "InvitationsPage.invitEmailSucceResen", message: "Invitation email successfully resent" })));
                setIsOpenSb(true);
            } else {
                throw new Error(data.message);
            }
        } catch (error) {
            console.error('Error resending invitation:', error);
        } finally {
            setIsSendingQuery(false);
            setIsOpenModalResend(false);
        }
    };


    const handleShowInvitationDelete = (rowID:number) => {
        setInvtitationSelected(rowID)
        setInvtitationSelectedData(invitations[rowID]);
        setIsOpenModalDelete(true);
    }

    const deletingInvitation = async () => {
        if (invtitationSelected === null) return;
        setIsSendingQuery(true);
        try {
            const token = localStorage.getItem('authToken');
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/admin/users/delete-invitation`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({ invitationId: invitations[invtitationSelected]?.id })
            });

            if (response.ok) {
                const updatedInvitations = invitations.filter((_, index) => index !== invtitationSelected);
                setInvitations(updatedInvitations);
                setMessageSb(i18n._({ id: "InvitationsPage.invitSucceDelet", message: "Invitation successfully deleted" }));
                setIsOpenSb(true);
            } else {
                const data = await response.json();
                throw new Error(data.message);
            }
        } catch (error) {
            console.error('Error deleting invitation:', error);
        } finally {
            setIsSendingQuery(false);
            setIsOpenModalDelete(false);
        }
    };

    // Row Menu (Action for user)
    const rowMenu = (rowID: number): JSX.Element | null => {
        if (rowID < 0) return null
        return (
            <React.Fragment>
                <Dropdown>
                    <MenuButton
                        slots={{ root: IconButton }}
                        slotProps={{ root: { variant: 'plain', color: 'neutral', size: 'sm' } }}
                        disabled={isLoading}
                    >
                        <MoreHorizRoundedIcon />
                    </MenuButton>
                    <Menu size="sm" sx={{ minWidth: 140 }}>
                        <MenuItem
                            color="primary"
                            onClick={()=>{handleShowInvitationResend(rowID)}}
                        >
                            <InfoIcon/><Typography><Trans id="InvitationsPage.resen">Resend</Trans></Typography>
                        </MenuItem>

                        <Divider />
                        <MenuItem
                            color="danger"
                            onClick={()=>{handleShowInvitationDelete(rowID)}}
                        >
                            <DeleteForeverIcon/><Typography><Trans id="InvitationsPage.Delete">Delete</Trans></Typography>
                        </MenuItem>
                    </Menu>
                </Dropdown>
            </React.Fragment>
        );
    }

    return (
        <>
            <Box sx={{display: 'flex', mb: 1, gap: 1, flexDirection: { xs: 'column', sm: 'row' }, alignItems: { xs: 'start', sm: 'center' }, flexWrap: 'wrap', justifyContent: 'space-between',}}>
                <Typography level="h2" component="h1">
                    <Trans id="InvitationsPage.invit">Invitations</Trans>
                </Typography>

                <Box>
                    <Button
                        color="primary"
                        size="sm"
                        onClick={()=> {
                            setIsModalAddInvite(true)
                        }}
                    >
                        <Trans id="InvitationsPage.creatInvit">Create invitation</Trans>
                    </Button>


                </Box>
            </Box>



            <InvitationsTable
                invitations={invitations}
                isLoading={isLoading}
                page={page}
                limit={limit}
                totalPages={Math.ceil(totalInvitations / limit)}
                totalInvitations={totalInvitations}
                handlePageChange={handlePageChange}
                renderFilters={renderFilters}
                rowMenu={rowMenu}

                searchQuery={searchQuery}
                searchPreQuery={searchPreQuery}
                setSearchQuery={setSearchQuery}
                setSearchPreQuery={setSearchPreQuery}
            />

            <InvitationsList
                invitations={invitations}
                isLoading={isLoading}
                page={page}
                limit={limit}
                totalPages={Math.ceil(totalInvitations / limit)}
                totalInvitations={totalInvitations}
                handlePageChange={handlePageChange}
                renderFilters={renderFilters}
                rowMenu={rowMenu}

                searchQuery={searchQuery}
                searchPreQuery={searchPreQuery}
                setSearchQuery={setSearchQuery}
                setSearchPreQuery={setSearchPreQuery}
            />





            <Modal
                open={isModalAddInvite}
                onClose={() => setIsModalAddInvite(false)}
            >
                <ModalDialog size="md">
                    <ModalClose/>
                    <DialogTitle><Trans id="InvitationsPage.addInvit">Add Invite</Trans></DialogTitle>
                    <DialogContent>
                        <Stack spacing={2} sx={{width: '100%'}}>
                            {/* Email Field */}
                            <FormControl error={inviteFormErrors.email}>
                                <FormLabel><Trans id="InvitationsPage.email">Email</Trans></FormLabel>
                                <Input
                                    name="email"
                                    placeholder={_(msg({ id: "InvitationsPage.enterEmail", message: "Enter email" }))}
                                    value={inviteFormData.email}
                                    onChange={handleInviteInputChange}
                                    autoComplete="off"
                                    disabled={isLoadingQuery}
                                />
                                {inviteFormErrors.email && <FormHelperText>{inviteErrorMessages.email}</FormHelperText>}
                            </FormControl>

                            {/* Name Field */}
                            <FormControl error={inviteFormErrors.name}>
                                <FormLabel><Trans id="InvitationsPage.name">Name</Trans></FormLabel>
                                <Input
                                    name="name"
                                    placeholder={i18n._({ id: "InvitationsPage.enterName", message: "Enter name" })}
                                    value={inviteFormData.name}
                                    onChange={handleInviteInputChange}
                                    autoComplete="off"
                                    disabled={isLoadingQuery}
                                />
                                {inviteFormErrors.name && <FormHelperText>{inviteErrorMessages.name}</FormHelperText>}
                            </FormControl>

                            {/* Role Selection */}
                            <FormControl error={inviteFormErrors.role}>
                                <FormLabel><Trans id="InvitationsPage.role">Role</Trans></FormLabel>
                                <Select
                                    name="role"
                                    value={inviteFormData.role}
                                    onChange={(event, newValue) => handleInviteSelectChange('role', newValue)}
                                    disabled={isLoadingQuery}
                                >
                                    {roles.map((role) => (
                                        <Option
                                            key={role.role}
                                            value={role.role}
                                            color={role.color}
                                        >
                                            {role.icon && React.createElement(role.icon)}{role.name}
                                        </Option>
                                    ))}
                                </Select>
                            </FormControl>
                        </Stack>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            variant="solid"
                            color="danger"
                            size="sm"
                            onClick={sendInviteForm}
                            loading={isLoadingQuery}
                        >
                            <Trans id="InvitationsPage.sendInvit">Send invite</Trans>
                        </Button>
                        <Button
                            variant="plain"
                            color="neutral"
                            size="sm"
                            onClick={() => setIsModalAddInvite(false)}
                            disabled={isLoadingQuery}
                        >
                            <Trans id="InvitationsPage.cance">Cancel</Trans>
                        </Button>
                    </DialogActions>
                </ModalDialog>
            </Modal>


            <Modal open={isOpenModalResend} onClose={() => setIsOpenModalResend(false)}>
                <ModalDialog variant="outlined" role="alertdialog">
                    <DialogTitle>
                        <Trans id="InvitationsPage.resen">Resend</Trans>
                    </DialogTitle>
                    <Divider />
                    <DialogContent>
                        {i18n._({ id: "InvitationsPage.doYouReallWantToRese", message: "Do you really want to resend the invitation to {name}({email}) ?", values: { name:invtitationSelectedData?.name, email:invtitationSelectedData?.email } })}
                    </DialogContent>
                    <DialogActions>
                        <Button
                            variant="solid"
                            color="success"
                            onClick={() => resendingEmailInvitation()}
                            loading={isSendingQuery}
                        >
                            <Trans id="InvitationsPage.resen">Resend</Trans>
                        </Button>
                        <Button
                            variant="plain"
                            color="neutral"
                            onClick={() => {
                                setIsOpenModalResend(false);
                                setInvtitationSelected(null);
                            }}
                            disabled={isSendingQuery}
                        >
                            <Trans id="InvitationsPage.cance">Cancel</Trans>
                        </Button>
                    </DialogActions>
                </ModalDialog>
            </Modal>


            <Modal open={isOpenModalDelete} onClose={() => setIsOpenModalDelete(false)}>
                <ModalDialog variant="outlined" role="alertdialog">
                    <DialogTitle>
                        <Trans id="InvitationsPage.Deletion">Deletion</Trans>
                    </DialogTitle>
                    <Divider />
                    <DialogContent>
                        <p>{i18n._({ id: "InvitationsPage.doYouReallWantToDele", message: "Do you really want to delete the invitation for {name}({email})?", values: { name:invtitationSelectedData?.name, email:invtitationSelectedData?.email } })}</p>
                        <p><Trans id="InvitationsPage.afterDeletTheUserWil">After deletion, the user will not be able to register under the specified role</Trans></p>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            variant="solid"
                            color="danger"
                            onClick={() => deletingInvitation()}
                            loading={isSendingQuery}
                        >
                            <Trans id="InvitationsPage.DeteheH1">DELETE</Trans>
                        </Button>
                        <Button
                            variant="plain"
                            color="neutral"
                            onClick={() => {
                                setIsOpenModalDelete(false);
                                setInvtitationSelected(null);
                            }}
                            disabled={isSendingQuery}
                        >
                            <Trans id="InvitationsPage.cance">Cancel</Trans>
                        </Button>
                    </DialogActions>
                </ModalDialog>
            </Modal>


            <CustomSnackbar
                open={isOpenSb}
                message={messageSb}
                handleClose={() => setIsOpenSb(false)}
            />
        </>
    );
};

export default InvitationsPage;
