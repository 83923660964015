// theme.ts
import { extendTheme } from '@mui/joy/styles';

const theme = extendTheme({
    cssVarPrefix: 'bcspider',

    components: {
        JoyModal: {
            defaultProps: {},
            styleOverrides: {
                root: {
                    zIndex: 10001,
                },
            },
        },
        JoySnackbar: {
            defaultProps: {},
            styleOverrides: {
                root: {
                    zIndex: 9995,
                },
            },
        },
        JoySelect: {
            defaultProps: {},
            styleOverrides: {
                listbox:{
                    zIndex: 10001,
                },
            },
        },
        JoyAutocomplete:{
            styleOverrides:{
                listbox:{
                    zIndex:10002,
                }
            }
        }
    },
});

export default theme;
