// Dashboard.tsx
import React,{useState,useEffect} from 'react';
import Box from '@mui/joy/Box';
import Typography from "@mui/joy/Typography";
import Button from "@mui/joy/Button";
import AddIcon from "@mui/icons-material/Add";

import AspectRatio from '@mui/joy/AspectRatio';

import Card, { CardProps } from '@mui/joy/Card';
import CardContent from '@mui/joy/CardContent';
import IconButton from '@mui/joy/IconButton';
import LinearProgress from '@mui/joy/LinearProgress';

import Sun from '@mui/icons-material/LightMode';
import Chip from '@mui/joy/Chip';
import InsertDriveFileRoundedIcon from '@mui/icons-material/InsertDriveFileRounded';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import RemoveCircleOutlineRoundedIcon from '@mui/icons-material/RemoveCircleOutlineRounded';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import LoadingSpinner from "../../components/UI/LoadingSpinner";
import {i18n} from "@lingui/core";

interface ServiceDetail {
    isRunning: boolean;
    walletsTotal: number;
    walletsProcessed: number;
    walletsSpeed: number;
    walletsLeft: number;
}

interface ServiceDetails {
    [blockchainId: string]: ServiceDetail;
}

interface Blockchain {
    id: string; // Предполагается, что у каждого блокчейна есть уникальный идентификатор
    name: string;
    // Добавьте другие необходимые поля
}

const DashboardIndexPage = () => {

    const [serviceStatus, setServiceStatus] = useState('inactive');
    const [details, setDetails] = useState<ServiceDetails>({});
    const [isWaitAnswer, setIsWaitAnswer] = useState(false);
    const [lastStarted, setLastStarted] = useState<number | null>(null);
    const [blockchains, setBlockchains] = useState<{ [id: string]: Blockchain }>({});
    const [isLoading, setIsLoading] = useState(true);

    const firstLoading = async () => {
        setIsLoading (true);
        await  updateServiceStatus(); // Начальный вызов функции
        await fetchBlockchains();
        setIsLoading (false);
    }

    useEffect(() => {
        firstLoading()

    }, []);

    const fetchBlockchains = async () => {
        const token = localStorage.getItem('authToken'); // Предполагается, что токен сохранен в localStorage
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/admin/bcs/get-all-blockchains`, { // Убедитесь, что URL соответствует вашей конфигурации
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });

            if (!response.ok) {
                throw new Error('Failed to fetch blockchains');
            }

            const data = await response.json();
            const blockchainsMap: { [id: string]: Blockchain } = {};
            console.log("BBC",data)
            // Предполагается, что data.blockChains - это массив объектов блокчейнов
            data.blockChains.forEach((blockchain: Blockchain) => {
                blockchainsMap[blockchain.id] = blockchain;
            });

            setBlockchains(blockchainsMap);
        } catch (error) {
            console.error("Error fetching blockchains", error);
        }
    };


    const updateServiceStatus = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/bcspider/status`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
                },
            });
            const data = await response.json();
            console.log(data)
            setServiceStatus(data.status);
            setDetails(data.details); // Обновляем детали, используя ключ blockchainId
            setLastStarted(data.lastStarted)
        } catch (e) {
            console.error("Error fetching service status", e);
        } finally {

        }
    };

    //setIsWaitAnswer(true); setIsWaitAnswer(false);
    useEffect(() => {
        let isMounted = true; // Флаг для отслеживания монтирования компонента

        const updateServiceStatusWithDelay = async () => {
            if (!isMounted) return; // Проверяем, что компонент все еще смонтирован

            await updateServiceStatus();

            // Планируем следующий вызов через секунду после завершения текущего
            setTimeout(updateServiceStatusWithDelay, 1000);
        };

        if (serviceStatus === 'active') {
            updateServiceStatusWithDelay(); // Начальный вызов функции, если сервис активен
        }

        return () => {
            isMounted = false; // При размонтировании компонента сбрасываем флаг
        };
    }, [serviceStatus]);

    const handleStartStop = async () => {
        const action = serviceStatus === 'active' ? 'stop' : 'start';
        setIsWaitAnswer(true);
        try {
            await fetch(`${process.env.REACT_APP_API_URL}/api/bcspider/start-stop`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ action }),
            });
            await updateServiceStatus(); // Повторно используем для обновления статуса
        } catch (e) {
            console.error("Error sending start/stop action", e);
        } finally {
            setIsWaitAnswer(false);
        }
    };


    if (isLoading)
    return (
        <LoadingSpinner/>
    )

    return (
        <>
            <Box sx={{display: 'flex', mb: 1, gap: 1, flexDirection: { xs: 'column', sm: 'row' }, alignItems: { xs: 'start', sm: 'center' }, flexWrap: 'wrap', justifyContent: 'space-between',}}>
                <Typography level="h2" component="h1">
                    Dasboard
                </Typography>

                <Box>
                    {lastStarted && (
                        <Chip
                            variant="soft"
                            startDecorator={<Sun />}
                            sx={{
                                mr:2
                            }}
                        >
                            {lastStarted ? new Date(lastStarted).toLocaleString(i18n.locale) : 'N/A'}
                        </Chip>
                    )}

                    <Button
                        color="primary"
                        size="sm"
                        loading={isWaitAnswer}
                        onClick={handleStartStop}
                    >
                        {serviceStatus === 'active' ? 'Stop' : 'Start'}
                    </Button>


                </Box>
            </Box>

            {serviceStatus === 'active' && (
                <Box>
                    {Object.entries(details).map(([blockchainId, detail]) => {

                        const profs = (detail.walletsTotal > 0 && detail.walletsProcessed >= 0) ? Math.ceil((detail.walletsProcessed / detail.walletsTotal) * 100) : 0;

                        return(<Box sx={{ mb: 2 }} key={blockchainId}>
                                <Card
                                    variant="outlined"
                                    orientation="horizontal"

                                    sx={[
                                        {
                                            gap: 1.5,
                                            alignItems: 'flex-start',
                                        },

                                    ]}
                                >
                                    <AspectRatio
                                        ratio="1"
                                        variant="soft"
                                        color="neutral"
                                        sx={{
                                            minWidth: 32,
                                            borderRadius: '50%',
                                            '--Icon-fontSize': '16px',
                                        }}
                                    >
                                        <div> <QueryStatsIcon /></div>
                                    </AspectRatio>
                                    <CardContent>
                                        <Typography>
                                            {blockchainId === "portfolioWorker" ? "Portfolior" : blockchains[blockchainId]?.name || "Unknown Blockchain"}
                                        </Typography>
                                        <Typography level="body-xs">processed {detail.walletsProcessed} wallets out of {detail.walletsTotal}</Typography>
                                        <Typography level="body-xs">speed: {detail.walletsSpeed} w/s</Typography>
                                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                            <LinearProgress
                                                color="neutral"
                                                value={profs}
                                                determinate
                                                sx={[
                                                    {
                                                        ...(detail.isRunning && {
                                                            color: 'var(--joy-palette-success-solidBg)',
                                                        }),
                                                    },
                                                ]}
                                            />
                                            <Typography fontSize="xs">
                                                {profs} %
                                            </Typography>
                                        </Box>
                                    </CardContent>
                                    {!detail.isRunning ? (
                                        <AspectRatio
                                            ratio="1"
                                            variant="solid"
                                            color="success"
                                            sx={{
                                                minWidth: 20,
                                                borderRadius: '50%',
                                                '--Icon-fontSize': '14px',
                                            }}
                                        >
                                            <div>
                                                <CheckRoundedIcon />
                                            </div>
                                        </AspectRatio>
                                    ) : (
                                        <IconButton variant="plain" color="danger" size="sm" sx={{ mt: -1, mr: -1 }}>
                                            <RemoveCircleOutlineRoundedIcon />
                                        </IconButton>
                                    )}
                                </Card>
                            </Box>)
                        }
                    )}
                </Box>
            )}
        </>
    );
};

export default DashboardIndexPage;
