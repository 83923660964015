import React from 'react';
import Snackbar from '@mui/joy/Snackbar';
import Button from '@mui/joy/Button';
import Typography from '@mui/joy/Typography';
import Box from '@mui/joy/Box';
import Stack from '@mui/joy/Stack';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import WarningIcon from '@mui/icons-material/Warning';
import ErrorIcon from '@mui/icons-material/Error';
import InfoIcon from '@mui/icons-material/Info';

interface CustomAlertSnackbarProps {
    open: boolean;
    onClose: () => void;
    title: React.ReactNode;
    message: React.ReactNode;
    duration?: number;
    color?: 'success' | 'warning' | 'primary' | 'neutral' | 'danger';
    variant?: 'plain' | 'outlined' | 'soft' | 'solid';
}

const CustomAlertSnackbar: React.FC<CustomAlertSnackbarProps> = ({
                                                                     open,
                                                                     onClose,
                                                                     title,
                                                                     message,
                                                                     duration = 5000,
                                                                     color = 'primary',
                                                                     variant = 'outlined'
                                                                 }) => {

    const getIcon = (color: string) => {
        switch(color) {
            case 'success':
                return <CheckCircleIcon />;
            case 'warning':
                return <WarningIcon />;
            case 'danger':
                return <ErrorIcon />;
            case 'neutral':
                return <InfoIcon />;
            default:
                return <ReportProblemIcon />;
        }
    };

    return (
        <Snackbar
            autoHideDuration={duration}
            variant={variant}
            color={color}
            size="lg"
            invertedColors
            open={open}
            onClose={onClose}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            sx={(theme) => ({
                background: `linear-gradient(45deg, ${theme.palette[color][600]} 30%, ${theme.palette[color][500]} 90%})`,
                maxWidth: 360,
            })}
        >
            <Box>
                <Stack direction="row" spacing={2} alignItems="center">
                    {getIcon(color)}
                    <Typography level="title-lg">{title}</Typography>
                </Stack>
                <Typography sx={{ mt: 1, mb: 2 }}>
                    {message}
                </Typography>
                <Button variant="solid" color={color} onClick={onClose} fullWidth>
                    OK
                </Button>
            </Box>
        </Snackbar>
    );
};

export default CustomAlertSnackbar;
