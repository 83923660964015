import React from 'react';
import { Box, Button } from '@mui/joy';
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import IconButton, {iconButtonClasses} from '@mui/joy/IconButton';
import Typography from "@mui/joy/Typography";

interface PaginationProps {
    page: number;
    totalPages: number;
    isLoadingQuery: boolean;
    handlePageChange: (page: number) => void;
}

const Pagination: React.FC<PaginationProps> = ({ page, totalPages, isLoadingQuery, handlePageChange }) => {
    const delta = 2; // Количество страниц вокруг текущей страницы
    let range: (number | string)[] = [];

    for (let i = Math.max(2, page - delta); i <= Math.min(totalPages - 1, page + delta); i++) {
        range.push(i);
    }

    if (page - delta > 2) {
        range.unshift("...");
    }
    if (page + delta < totalPages - 1) {
        range.push("...");
    }

    range.unshift(1);
    if (totalPages !== 1) {
        range.push(totalPages);
    }

    return (
        <Box className="Pagination-laptopUp" sx={{pt: 2, gap: 1, [`& .${iconButtonClasses.root}`]: { borderRadius: '50%' }, display: {xs: 'none', md: 'flex',},}}>
            <Button
                size="sm"
                variant="outlined"
                color="neutral"
                startDecorator={<KeyboardArrowLeftIcon />}
                onClick={() => handlePageChange(page - 1)}
                disabled={page === 1 || isLoadingQuery}
            >
                Previous
            </Button>

            <Box sx={{ flex: 1 }} />
            {range.map((el, index) => (
                <React.Fragment key={index}>
                    {el === "..." ? (
                        <Typography>...</Typography>
                    ) : (
                        <IconButton
                            onClick={() => typeof el === "number" && handlePageChange(el)}
                            size="sm"
                            variant={el === page ? 'solid' : 'outlined'}
                            color="neutral"
                            disabled={isLoadingQuery}
                        >
                            {el}
                        </IconButton>
                    )}
                </React.Fragment>
            ))}
            <Box sx={{ flex: 1 }} />

            <Button
                onClick={() => handlePageChange(page + 1)}
                disabled={page === totalPages || isLoadingQuery}
                size="sm"
                variant="outlined"
                color="neutral"
                endDecorator={<KeyboardArrowRightIcon />}
            >
                Next
            </Button>
        </Box>
    );
};

export default Pagination;
