// GroupAddWalletPage.tsx
import React,{useState, useEffect, useRef} from 'react';
import * as XLSX from 'xlsx';
import Web3 from 'web3';
import Box from '@mui/joy/Box';
import Typography from "@mui/joy/Typography";
import Button from "@mui/joy/Button";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import LinearProgress from '@mui/joy/LinearProgress';
import {Table} from "@mui/joy";
import Sheet from "@mui/joy/Sheet";
import Card, { CardProps } from '@mui/joy/Card';
import CardContent from '@mui/joy/CardContent';
import Link from '@mui/joy/Link';
import AspectRatio from '@mui/joy/AspectRatio';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import FileUploadRoundedIcon from '@mui/icons-material/FileUploadRounded';
import IconButton from "@mui/joy/IconButton";
import {NavLink} from "react-router-dom";
import FileUpload from "./compontents/FileUpload";
import CustomSnackbar from "../../../components/UI/CustomSnackbar";






const GroupAddWalletPage = () => {
    const [wallets, setWallets] = useState<{ address: string; status: boolean }[]>([]);
    const [uploadedFile, setUploadedFile] = useState<File | null>(null); // Состояние для хранения загруженного файла
    const fileInputRef = useRef<HTMLInputElement>(null);
    const [isDragOver, setIsDragOver] = useState(false);
    const [error, setError] = useState<string | null>(null); // Новое состояние для ошибки

    const [preDataWallets, setPreDataWallets] = useState<string[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [progressCheckWallet, setProgressCheckWallet] = useState(0);
    // Существующие функции...
    const [isProcesAdding, setIsProcesAdding] = useState(false);

    const [progressAdding, setProgressAdding] = useState(0)
    const [progressAddingCount, setProgressAddingCount] = useState(0)

    const [isOpenSb, setIsOpenSb] = useState(false);
    const [messageSb, setMessageSb] = useState('');

    const handleFileUploadClick = () => {
        fileInputRef.current?.click();
    };

    const handleFileChange = (files: FileList | null) => {
        if (files && files.length > 0) {
            setIsLoading(true)
            const file = files[0];
            const reader = new FileReader();
            reader.onload = (e: ProgressEvent<FileReader>) => {
                if (!e.target || !e.target.result) {
                    console.error("Error reading file");
                    return;
                }
                const data = e.target.result;
                let workbook = XLSX.read(data, { type: "binary" });

                let totalCells = 0;
                workbook.SheetNames.forEach(sheetName => {
                    const worksheet = workbook.Sheets[sheetName];
                    const rows: any[] = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
                    rows.forEach(row => {
                        totalCells += row.length;
                    });
                });

                let processedCells = 0;
                const newPreDataWallets = new Set<string>();

                workbook.SheetNames.forEach(sheetName => {
                    const worksheet = workbook.Sheets[sheetName];
                    const rows: any[] = XLSX.utils.sheet_to_json(worksheet, { header: 1, raw: false });
                    rows.forEach(row => {
                        row.forEach((cellValue: any) => {
                            if (Web3.utils.isAddress(cellValue)) {
                                newPreDataWallets.add(cellValue);
                            }
                            processedCells++;
                            setProgressCheckWallet(Math.round((processedCells / totalCells) * 100));
                        });
                    });

                });
                setProgressCheckWallet(100)
                setPreDataWallets(Array.from(newPreDataWallets));
                setUploadedFile(file);
                setError(null);
                setIsLoading(false)
            };

            if (file.type === "text/csv" || ["application/vnd.ms-excel", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"].includes(file.type)) {
                reader.readAsBinaryString(file);
            } else {
                console.log("Invalid file type");
                setError(`File: ${file.name} error`);
                setUploadedFile(null);
                setIsLoading(false)
            }
        }
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        handleFileChange(event.target.files);
    };

    const handleCancelUpload = () => {
        setUploadedFile(null); // Сбрасываем выбранный файл
    };

    const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault(); // Необходимо для возможности drop
        setIsDragOver(true); // Устанавливаем hover-состояние в true
    };

    const handleDragEnter = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        setIsDragOver(true); // Устанавливаем hover-состояние в true
    };

    const handleDragLeave = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        setIsDragOver(false); // Устанавливаем hover-состояние в false
    };

    const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        setIsDragOver(false); // Сбрасываем hover-состояние при drop
        if (event.dataTransfer) {
            handleFileChange(event.dataTransfer.files);
        }
    };


    const addWalletAddresses = async (addresses: string[]) => {
        // Здесь должен быть ваш API endpoint

        try {
            const authToken = localStorage.getItem('authToken'); // Получаем токен из localStorage
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/wallets/add-wallets`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${authToken}`,
                },
                body: JSON.stringify({ addresses }),
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            return response.json();
        } catch (error) {
            console.error('Error during the add operation:', error);
            throw error; // Пробрасываем ошибку дальше
        }
    };


    const handleStartAdding = async () => {
        if (preDataWallets.length === 0) {
            console.log("No addresses to add");
            return;
        }
        let addedCount = 0;
        setIsProcesAdding(true);
        const chunkSize = 50;
        const chunks = [];
        for (let i = 0; i < preDataWallets.length; i += chunkSize) {
            chunks.push(preDataWallets.slice(i, i + chunkSize));
        }

        let processedCount = 0; // Счетчик обработанных адресов
        for (const chunk of chunks) {
            try {
                const response = await addWalletAddresses(chunk);
                // Обработка успешно добавленных адресов

                addedCount += response.addedWallets.length; // Увеличение счетчика добавленных адресов

                const updatedWallets = response.addedWallets.map((address: string) => ({
                    address,
                    status: "added"
                }));
                setWallets(prevWallets => [...prevWallets, ...updatedWallets]);

                // Обработка адресов с ошибками
                const errorWallets = response.errorWallets.map((errorInfo: { address: string, error: { code: number, message: string } }) => ({
                    address: errorInfo.address,
                    status: errorInfo.error.code
                }));
                setWallets(prevWallets => [...prevWallets, ...errorWallets]);

                // Обновление прогресса
                processedCount += chunk.length;
                setProgressAdding(Math.round((processedCount / preDataWallets.length) * 100));
                setProgressAddingCount(processedCount);

            } catch (error) {
                console.error("Error during the add operation for chunk", error);
            }
        }

        setMessageSb(`Successfully added ${addedCount} addresses.`);
        setIsOpenSb(true);
        handleCancelUpload();
        setIsProcesAdding(false);
    };



    return (
        <>
            <Box sx={{display: 'flex', mb: 1, gap: 1, flexDirection: { xs: 'column', sm: 'row' }, alignItems: { xs: 'start', sm: 'center' }, flexWrap: 'wrap', justifyContent: 'space-between',}}>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                    <IconButton
                        component={NavLink}
                        to={"/dashboard/wallets"}
                    >
                        <ArrowBackIosIcon/>
                    </IconButton>
                    <Typography level="h2" component="h1">
                        Add wallets
                    </Typography>
                </Box>
            </Box>

            <Box  sx={{borderRadius: 'sm', display: { sm: 'flex' }, flexWrap: 'wrap', gap: 1.5, '& > *': {minWidth: { xs: '120px', md: '160px' },},}}>
                <Card
                    variant="soft"
                    sx={{
                        borderRadius: 'sm',
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 1,
                        alignItems: 'center',
                        px: 3,
                        flexGrow: 1,
                        boxShadow: 'none',

                        backgroundColor: isDragOver ? 'lightgray' : uploadedFile && !error ? 'success' : error ? 'warning' : '', // Динамическое изменение фона
                    }}


                    onDragOver={handleDragOver}
                    onDragEnter={handleDragEnter}
                    onDragLeave={handleDragLeave}
                    onDrop={handleDrop}
                >
                    <input
                        type="file"
                        ref={fileInputRef}
                        style={{ display: 'none' }}
                        accept=".csv, .xls, .xlsx"
                        onChange={handleChange}
                        disabled={isProcesAdding}
                    />


                    {uploadedFile ? (
                        <Box sx={{ textAlign: 'center' }}>
                            <Box>
                                <FileUpload
                                    //icon={<InsertDriveFileRoundedIcon />}
                                    fileName={uploadedFile.name}
                                    walletsCount={preDataWallets.length}
                                    progress={progressCheckWallet}
                                    sx={{
                                        minWidth:300
                                    }}
                                />
                            </Box>
                        </Box>
                    ) : (
                        <>
                            <AspectRatio
                                ratio="1"
                                variant="solid"
                                color="primary"
                                sx={{
                                    minWidth: 32,
                                    borderRadius: '50%',
                                    '--Icon-fontSize': '16px',
                                }}
                                onClick={handleFileUploadClick}
                            >
                                <div><FileUploadRoundedIcon /></div>
                            </AspectRatio>
                            <Typography level="body-sm" textAlign="center" onClick={handleFileUploadClick}>
                                <Link component="button" overlay>
                                    Click to upload
                                </Link>{' '}
                                or drag and drop
                                <br /> CSV, XLS, or XLSX
                            </Typography>
                            {error && (
                                <Typography level="body-sm" color="danger">
                                    {error}
                                </Typography>
                            )}
                        </>

                    )}

                </Card>
            </Box>

            {uploadedFile && (
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '10px', marginTop: '20px' }}>
                    <Typography>
                        {preDataWallets.length} addresses detected for addition.
                    </Typography>
                    <Box>
                        {preDataWallets.length > 0 && (
                            <Button
                                onClick={() => {handleStartAdding()}}
                                sx={{ marginRight: '10px' }}
                                color="success"
                                loading={isProcesAdding}
                            >
                                Start Adding
                            </Button>
                        )}

                        <Button
                            onClick={() => {handleCancelUpload()}}
                            variant='outlined'
                            color='danger'
                            disabled={isProcesAdding}
                        >
                            Cancel
                        </Button>
                    </Box>
                </Box>

            )}

            {isProcesAdding && (
                <>
                    <Box>
                        <LinearProgress
                            determinate
                            variant="outlined"
                            thickness={24}
                            value={progressAdding}
                        >
                            <Typography
                                level="body-xs"
                                fontWeight="xl"
                                textColor="common.white"
                                sx={{ mixBlendMode: 'difference' }}
                            >
                                address {progressAddingCount}
                            </Typography>
                        </LinearProgress>
                    </Box>

                    {wallets.length > 0 && (
                        <Sheet className="OrderTableContainer" variant="outlined" sx={{width: '100%', borderRadius: 'sm', flexShrink: 1, overflow: 'auto', minHeight: 0,}}>
                            <Table
                                aria-labelledby="BlockChainTable"
                                stickyHeader
                                stickyFooter
                                hoverRow
                                sx={{
                                    '--Table-headerUnderlineThickness': '1px',
                                    '--TableRow-hoverBackground': 'var(--joy-palette-background-level1)',
                                    '--TableCell-paddingY': '4px',
                                    '--TableCell-paddingX': '8px',
                                }}
                            >
                                <thead>
                                <tr>
                                    <th style={{ width: "80%", padding: '12px 6px' }}>Wallet address</th>
                                    <th style={{ width: "20%",  padding: '12px 6px' }}>Status</th>
                                </tr>
                                </thead>
                                <tbody>
                                {wallets.map((wallet, index) => (
                                    <tr key={index}>
                                        <td>{wallet.address}</td>
                                        <td>{wallet.status}</td>
                                    </tr>
                                ))}
                                </tbody>
                            </Table>
                        </Sheet>
                    )}

                </>
            )}

            <CustomSnackbar
                open={isOpenSb}
                message={messageSb}
                handleClose={() => setIsOpenSb(false)}
            />
        </>
    );
};

export default GroupAddWalletPage;
