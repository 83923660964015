// NotFoundPage.tsx
import React from 'react';
import Box from '@mui/joy/Box';
import Typography from '@mui/joy/Typography';

import Button from "@mui/joy/Button";
import { useNavigate } from "react-router-dom";

import {Trans} from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { msg } from "@lingui/macro";
import { i18n } from "@lingui/core"


const AccessDeniedPage = () => {
    const { _ } = useLingui();
    const navigate = useNavigate();
    const handleBack = () => {
        navigate(-1); // Навигация на один шаг назад
    };
    return (
        <Box sx={{ display: 'flex', minHeight: '100dvh' }}>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    maxWidth: 300,
                    margin: 'auto',
                    gap: 2,
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        height: '100%',
                        textAlign: 'center',
                    }}
                >
                    <Typography level="h1">1020</Typography>
                    <Typography level="h4"><Trans id="фccessDeniedPage.accesDenie"><Trans id="accessDeniedPage.accesDenie">Access Denied</Trans></Trans></Typography>
                    <Typography level="body-xs">
                        <Trans id="accessDeniedPage.youHaveBeenDenieAcce">You have been denied access to this page</Trans>
                    </Typography>
                    <Button
                        sx={{
                            mt: 1
                        }}
                        onClick={handleBack}
                    >
                        <Trans id="accessDeniedPage.back">BACK</Trans>
                    </Button>

                </Box>
            </Box>
        </Box>

    );
};

export default AccessDeniedPage;