import React from 'react';
import IconButton from '@mui/joy/IconButton';
import Typography from '@mui/joy/Typography';
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import {NavLink} from "react-router-dom";
import Box from "@mui/joy/Box";

const CompanyLogoBlock = () => (
    <Box
        component={NavLink}
        to="/"
        sx={{
            display: 'flex', // Установка в одну строку
            alignItems: 'center', // Центрирование элементов по вертикали
            textDecoration: 'none', // Убрать стандартное подчеркивание
            color: 'inherit' // Использовать наследуемый цвет текста
        }}
    >
        <AccountBalanceIcon />
        <Typography level="title-lg" sx={{ ml: 1 }}>BCSpider</Typography>
    </Box>
);

export default CompanyLogoBlock;
