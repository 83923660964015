// App.tsx
import React from 'react';
import { BrowserRouter as Router} from 'react-router-dom';
import { CssVarsProvider, StyledEngineProvider  } from '@mui/joy/styles';
import CssBaseline from '@mui/joy/CssBaseline';
import {GlobalProvider} from "./context/GlobalContext";
import LocalizedAppWrapper from "./context/LocalizedAppWrapper";
import LayoutWrapper from "./context/LayoutWrapper";

import theme from './theme';

function App() {
    return (
        <StyledEngineProvider injectFirst>
        <CssVarsProvider disableTransitionOnChange theme={theme}>
            <CssBaseline />
            <GlobalProvider>
                <LocalizedAppWrapper>
                    <Router>
                        <LayoutWrapper/>
                    </Router>
                </LocalizedAppWrapper>
            </GlobalProvider>
        </CssVarsProvider>
        </StyledEngineProvider>
    );
}




export default App;
