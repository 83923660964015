import React, { forwardRef } from "react";
import { NavLink, NavLinkProps } from "react-router-dom";

const NavLinkMui = forwardRef<HTMLAnchorElement, NavLinkProps & { className?: string }>((props, ref) => {
    const { className, ...rest } = props;
    return (
        <NavLink
            ref={ref}
            {...rest}
            className={({ isActive }) =>
                isActive ? `${className} Mui-selected` : className}
            end
        />
    );
});

export default NavLinkMui;
