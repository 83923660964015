// SendEmailSettingsPage.tsx
import React, { useState, useEffect } from 'react';
import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import Typography from '@mui/joy/Typography';
import Stack from "@mui/joy/Stack";
import Card from "@mui/joy/Card";
import Divider from "@mui/joy/Divider";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import Switch from '@mui/joy/Switch';
import Input from "@mui/joy/Input";
import CardOverflow from "@mui/joy/CardOverflow";
import CardActions from "@mui/joy/CardActions";
import CustomSnackbar from "../../../../components/UI/CustomSnackbar";
import LoadingSpinner from "../../../../components/UI/LoadingSpinner";
import FormHelperText from "@mui/joy/FormHelperText";
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import IconButton from "@mui/joy/IconButton";
import {Trans} from "@lingui/macro";
import { msg } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import {i18n} from "@lingui/core";

interface SendEmailSettingsState {
    host: string;
    port: number;
    secure: boolean;
    user: string;
    pass: string;
    from_email:string;
    from_name:string;
    rejectUnauthorized:boolean;
}

const SendEmailSettingsPage = () => {
    const { _ } = useLingui();
    const [settings, setSettings] = useState<SendEmailSettingsState>({
        host: '',
        port: 587,
        secure: false,
        user: '',
        pass: '',
        from_email:'',
        from_name:'',
        rejectUnauthorized:false
    });
    const [initialSettings, setInitialSettings] = useState<SendEmailSettingsState>(settings);
    const settingsChanged = JSON.stringify(settings) !== JSON.stringify(initialSettings);
    const [isLoadingData, setIsLoadingData] = useState(true);
    const [isSavingData, setIsSavingData] = useState(false);
    const [isOpenSb, setIsOpenSb] = useState(false);
    const [isPasswordVisible, setIsPasswordVisible] = useState(false);

    const togglePasswordVisibility = () => {
        setIsPasswordVisible(!isPasswordVisible);
    };


    const fetchSettings = async () => {
        setIsLoadingData(true)
        try {
            const token = localStorage.getItem('authToken');
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/admin/settings/get-email-settings`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            setSettings(data);
            setInitialSettings(data);
        } catch (error) {
            console.error('Error fetching settings:', error);
        } finally {
            setIsLoadingData(false)
        }
    };

    useEffect(() => {
        fetchSettings();
    }, []);

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setSettings(prevSettings => ({ ...prevSettings, [name]: value }));
    };


    const saveSettings = async () => {
        setIsSavingData(true);
        try {
            const token = localStorage.getItem('authToken');
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/admin/settings/put-email-settings`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify(settings)
            });

            if (!response.ok) {
                throw new Error('Failed to save settings');
            }

            setIsSavingData(false);
            setInitialSettings(settings); // Обновляем начальные настройки после успешного сохранения
            setIsOpenSb(true);
        } catch (error) {
            console.error('Error saving settings:', error);
            setIsSavingData(false);
        }
    };

    if (isLoadingData){
        return (<LoadingSpinner/>)
    }

    return (
        <>
            <Box
                sx={{
                    display: 'flex',
                    mb: 1,
                    gap: 1,
                    flexDirection: { xs: 'column', sm: 'row' },
                    alignItems: { xs: 'start', sm: 'center' },
                    flexWrap: 'wrap',
                    justifyContent: 'space-between',
                }}
            >
                <Typography level="h2" component="h1">
                    <Trans id="SendEmailSettingsPage.emailSetti">Email Settings</Trans>
                </Typography>
            </Box>

            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                }}
            >
                <Box
                    sx={{
                        maxWidth: '600px',
                        flexShrink: 1,
                        overflow: 'auto',
                        minHeight: 0,
                    }}
                >
                    <Card>
                        <Box sx={{ mb: 1 }}>
                            <Typography level="title-md"><Trans id="SendEmailSettingsPage.emailSetti">Email Settings</Trans></Typography>
                            <Typography level="body-sm">
                                <Trans id="SendEmailSettingsPage.confiTheSettiForEmai">Configure the settings for email service.</Trans>
                            </Typography>
                        </Box>
                        <Divider />
                        <Stack
                            direction="column"
                            spacing={3}
                            sx={{ my: 1 }}
                        >
                            {/* Email settings fields */}
                            <Stack
                                direction="row"
                                spacing={2}
                                sx={{ alignItems: 'center' }} // Add this line to align the items vertically
                            >
                                <FormControl sx={{ flexGrow: 1 }}> {/* Add flex grow to fill space */}
                                    <FormLabel><Trans id="SendEmailSettingsPage.host">Host</Trans></FormLabel>
                                    <Input
                                        name="host"
                                        value={settings.host}
                                        onChange={handleInputChange}
                                        disabled={isSavingData}
                                    />
                                </FormControl>
                                <FormControl>
                                    <FormLabel><Trans id="SendEmailSettingsPage.port">Port</Trans></FormLabel>
                                    <Input
                                        name="port"
                                        type="number"
                                        value={settings.port}
                                        onChange={handleInputChange}
                                        sx={{ width: '5rem' }} // Control the width of the port input
                                        disabled={isSavingData}
                                    />
                                </FormControl>
                            </Stack>
                            <FormControl> {/* Add flex grow to fill space */}
                                <FormLabel><Trans id="SendEmailSettingsPage.user">User</Trans></FormLabel>
                                <Input
                                    name="user"
                                    value={settings.user}
                                    onChange={handleInputChange}
                                    disabled={isSavingData}
                                    autoComplete='off'
                                />
                            </FormControl>
                            <FormControl>
                                <FormLabel><Trans id="SendEmailSettingsPage.passw">Password</Trans></FormLabel>
                                <Input
                                    name="pass"
                                    type={isPasswordVisible ? 'text' : 'password'}
                                    value={settings.pass}
                                    onChange={handleInputChange}
                                    disabled={isSavingData}
                                    autoComplete='new-password'
                                    endDecorator={
                                        <IconButton onClick={togglePasswordVisibility} size="sm">
                                            {isPasswordVisible ? <VisibilityOffIcon /> : <VisibilityIcon />}
                                        </IconButton>
                                    }
                                />
                            </FormControl>
                            <Stack
                                direction="row"
                                spacing={2}
                                sx={{ alignItems: 'center' }} // Add this line to align the items vertically
                            >
                                <FormControl>
                                    <FormLabel><Trans id="SendEmailSettingsPage.fromName">From Name</Trans></FormLabel>
                                    <Input
                                        name="from_name"
                                        value={settings.from_name}
                                        onChange={handleInputChange}
                                        sx={{ width: '10rem' }} // Control the width of the from name input
                                        disabled={isSavingData}
                                    />
                                </FormControl>
                                <FormControl sx={{ flexGrow: 1 }}> {/* Add flex grow to fill space */}
                                    <FormLabel><Trans id="SendEmailSettingsPage.fromEmail">From Email</Trans></FormLabel>
                                    <Input
                                        name="from_email"
                                        value={settings.from_email}
                                        onChange={handleInputChange}
                                        disabled={isSavingData}
                                    />
                                </FormControl>
                            </Stack>
                            <FormControl
                                orientation="horizontal"
                                sx={{ justifyContent: 'space-between' }}
                            >
                                <div>
                                    <FormLabel><Trans id="SendEmailSettingsPage.secur">Secure</Trans></FormLabel>
                                    <FormHelperText sx={{ mt: 0 }}><Trans id="SendEmailSettingsPage.onForPortOffForOther">ON for port 465, OFF for other ports</Trans></FormHelperText>
                                </div>
                                <Switch

                                    checked={settings.secure}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                                        setSettings(prevSettings => ({...prevSettings, secure: event.target.checked}))
                                    }
                                    disabled={isSavingData}
                                />
                            </FormControl>
                            <FormControl
                                orientation="horizontal"
                                sx={{ justifyContent: 'space-between' }}
                            >
                                <div>
                                    <FormLabel><Trans id="SendEmailSettingsPage.rejecUnaut">Reject Unauthorized</Trans></FormLabel>
                                    <FormHelperText sx={{ mt: 0 }}><Trans id="SendEmailSettingsPage.offForUseOfSelfsCert">OFF for use of self-signed certificates</Trans></FormHelperText>
                                </div>
                                <Switch
                                    checked={settings.rejectUnauthorized}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                                        setSettings(prevSettings => ({...prevSettings, rejectUnauthorized: event.target.checked}))
                                    }
                                    disabled={isSavingData}
                                />
                            </FormControl>
                        </Stack>

                        <CardOverflow sx={{ borderTop: '1px solid', borderColor: 'divider' }}>
                            <CardActions sx={{ alignSelf: 'flex-end', pt: 2 }}>
                                {settingsChanged && (
                                    <Button
                                        size="sm"
                                        variant="outlined"
                                        color="neutral"
                                        onClick={() => setSettings(initialSettings)}
                                        disabled={isSavingData}
                                    >
                                        <Trans id="SendEmailSettingsPage.cance">Cancel</Trans>
                                    </Button>
                                )}
                                <Button
                                    size="sm"
                                    variant="solid"
                                    disabled={!settingsChanged}
                                    onClick={saveSettings}
                                    loading={isSavingData}
                                >
                                    <Trans id="SendEmailSettingsPage.save">Save</Trans>
                                </Button>
                            </CardActions>
                        </CardOverflow>
                    </Card>
                </Box>
            </Box>

            <CustomSnackbar
                open={isOpenSb}
                message={i18n._({ id: "SendEmailSettingsPage.emailSettiHaveBeenSa", message: "Email settings have been saved" })}
                handleClose={() => setIsOpenSb(false)}
            />
        </>
    );
};

export default SendEmailSettingsPage;
