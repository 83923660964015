import React from 'react';
import Typography from '@mui/joy/Typography';
import { useAuth } from '../../context/GlobalContext'; // Предполагается, что путь к контексту правильный

const CopyrightBlock = () => {
    const { globalSettings } = useAuth(); // Получение globalsettings из контекста

    return (
        <Typography level="body-xs" textAlign="center">
            © BCSpider {new Date().getFullYear()} | v.{globalSettings?.version}
        </Typography>
    );
};

export default CopyrightBlock;
