// WalletsIndexPage.tsx
import React, {useState, useEffect, JSX} from 'react';
import Web3 from 'web3';
import BigNumber from 'bignumber.js';
import Box from '@mui/joy/Box';
import Typography from "@mui/joy/Typography";
import Button from "@mui/joy/Button";
import {Chip, LinearProgress, Table} from "@mui/joy";
import LoadingSpinner from "../../../components/UI/LoadingSpinner";
import AddIcon from "@mui/icons-material/Add";
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import {FormControl, FormLabel, Input} from "@mui/joy";
import SearchIcon from "@mui/icons-material/Search";
import SearchOffIcon from "@mui/icons-material/SearchOff";
import Sheet from "@mui/joy/Sheet";
import IconButton, {iconButtonClasses} from '@mui/joy/IconButton';
import ToggleButtonGroup from '@mui/joy/ToggleButtonGroup';
import FormatBoldIcon from '@mui/icons-material/FormatBold';
import FormatItalicIcon from '@mui/icons-material/FormatItalic';
import FormatUnderlinedIcon from '@mui/icons-material/FormatUnderlined';
import Select from "@mui/joy/Select";
import Option from "@mui/joy/Option";
import {i18n} from "@lingui/core";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Modal from '@mui/joy/Modal';
import ModalClose from '@mui/joy/ModalClose';

import Link from '@mui/joy/Link';
import {NavLink} from "react-router-dom";
import Pagination from "./compontents/Paginator";
import Tooltip from "@mui/joy/Tooltip";
import Textarea from '@mui/joy/Textarea';
import ModalDialog from '@mui/joy/ModalDialog';


interface FetchWalletsBalanceRequest {
    page: number;
    limit:number;
    search: string;
    sort?: 'asc' | 'desc'; // Делаем sort необязательным свойством
}

interface PortfolioItem {
    chainName: string;
    chainUrl: string;
    tokenName: string;
    tokenSymb: string;
    tokenUrl: string;
    tokenIcon: string;
    portfolio: number;
    price: number;
    amount: number;
    value: number;
}

interface Portfolio {
    total: number;
    chains: number;
    list: PortfolioItem[];
}

interface Wallet {
    _id: string;
    address: string;
    comment: string;
    nativeBalance: number;
    portfolioBalances: number;
    createdAt?: Date;
    updatedAt?: Date;
    portfolio?: Portfolio | null;
}




const PorfolioPage: React.FC = () => {
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(25); // Default rows per page
    const [searchQuery, setSearchQuery] = useState('');
    const [searchPreQuery, setSearchPreQuery] = useState('');
    const [sortOrder, setSortOrder] = useState<'asc' | 'desc' | null>(null);

    const [totalPages, setTotalPages]= useState(0);
    const [totalWallets, setTotalWallets]= useState(0);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const [isLoadingQuery, setIsLoadingQuery] = useState<boolean>(false);

    const [wallets, setWallets] = useState<Wallet[]>([]);

    const [isModaPortfolio,setIsModaPortfolio] =useState(false)
    const [showPortfolio,setShowPortfolio] =useState<Portfolio | null>(null)

    const startWalletIndex = (page - 1) * limit + 1;
    const endWalletIndex = startWalletIndex + wallets.length - 1;

    const [editCommentId, setEditCommentId] = useState<string | null>(null);
    const [editCommentValue, setEditCommentValue] = useState<string>("");
    const [isEditing, setIsEditing] = useState(false);
    const [currentEditingId, setCurrentEditingId] = useState<string | null>(null);

    const [isOpenEditComment, setIsOpenEditComment] = useState(false)
    // Функция для открытия формы редактирования
    const handleEdit = (id: string, comment: string) => {
        setIsEditing(true);
        setCurrentEditingId(id);
        setEditCommentValue(comment);
    };

    const handlePageChange = (newPage:number) => {
        setPage(newPage);
    };


    const getFirstTenChars = (inputString: string | null): string => {
        if (!inputString) {
            return "no comment";
        }
        return `${inputString.slice(0, 10)}...`;
    };



    const fetchWalletsBalance = async () => {
        setIsLoadingQuery(true);

        const authToken = localStorage.getItem('authToken'); // Получаем токен из localStorage


        let requestBody: FetchWalletsBalanceRequest = {
            page,
            limit,
            search: searchQuery,
            // sort не включён сюда изначально, так как он необязателен
        };

        if (sortOrder !== null) {
            requestBody.sort = sortOrder; // Теперь TypeScript знает, что sort может быть частью объекта
        }
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/wallets/get-wallets-portfolio`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${authToken}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(requestBody),
            });

            if (!response.ok) {
                throw new Error('Failed to fetch wallets balance');
            }

            const data = await response.json();
            setWallets(data.wallets);
            setTotalPages(data.totalPages);
            setTotalWallets(data.totalWallets);
            // Здесь можно обновить состояние с данными о кошельках, если требуется
        } catch (error) {
            console.error('Error:', error);
        } finally {
            setIsLoadingQuery(false);
        }
    };

    useEffect(() => {
        fetchWalletsBalance()
    }, [limit,page,searchQuery,sortOrder]);

    useEffect(() => {
        if (searchPreQuery.length <=0) {
            setSearchQuery(searchPreQuery)
        }
    }, [searchPreQuery]);



    const toggleSortOrder = () => {
        setSortOrder(prevSortOrder => {
            if (prevSortOrder === 'asc') return 'desc';
            if (prevSortOrder === 'desc') return null;
            return 'asc';
        });
    };


    const openModalPortfolio = (rowID: number) => {
        // Убедитесь, что rowID находится в пределах диапазона массива wallets
        if (rowID >= 0 && rowID < wallets.length) {
            const portfolio = wallets[rowID].portfolio;
            // Проверяем, что portfolio не null и не undefined
            if (portfolio !== null && portfolio !== undefined) {
                setShowPortfolio(portfolio);
                setIsModaPortfolio(true);
            }
        }
    }

    const saveComment = async (walletId:string) => {

        const authToken = localStorage.getItem('authToken'); // Получаем токен из localStorage, предполагая, что он там сохранён


        // Построение тела запроса
        const requestBody = {
            walletId: walletId,
            comment: editCommentValue,
        };

        try {
            setIsLoadingQuery(true)
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/wallets/add-comment`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${authToken}`, // Предполагается, что аутентификация происходит через токен
                },
                body: JSON.stringify(requestBody),
            });

            const data = await response.json();

            if (response.ok) {
                // Обработка успешного ответа

                // Здесь можно обновить UI, например, закрыть модальное окно редактирования или обновить список кошельков
                setIsEditing(false);
                setEditCommentId(null);
                setEditCommentValue("");
                // Перезагрузить данные кошельков, чтобы отразить обновленный комментарий
                fetchWalletsBalance();
            } else {
                // Обработка ошибок от сервера
                console.error('Error saving comment:', data.message);
            }
        } catch (error) {
            // Обработка ошибок сети
            console.error('Network error:', error);
        } finally {
            setIsLoadingQuery(false)
            setIsOpenEditComment(false)
        }
    };


    // Функция для отмены редактирования комментария
    const cancelEdit = () => {
        setEditCommentId(null);
        setEditCommentValue("");
        setIsOpenEditComment(false)
    };

    // Этот обработчик вызывается, когда пользователь кликает по комментарию для его редактирования
    const handleCommentClick = (walletId:string, comment:string) => {
        setEditCommentId(walletId);
        setEditCommentValue(comment || "");
        setIsEditing(true); // Входим в режим редактирования
    };
    // Обработчик изменения текста в TextArea
    const handleTextareaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setEditCommentValue(e.target.value);
    };

    // Обработчик для сохранения комментария
    const handleSaveComment = async () => {
        if (editCommentId) { // Проверяем, что editCommentId не null
            await saveComment(editCommentId);
        } else {
            // Обрабатываем случай, когда editCommentId равно null
            console.error("Cannot save comment without a wallet ID.");
        }
    };

// Обработчик для отмены редактирования
    const handleCancelEdit = () => {
        setIsEditing(false);
        setEditCommentId(null);
        setEditCommentValue("");
    };


    if (isLoading){
        return <LoadingSpinner/>
    }
    return (
        <>
            <Box sx={{display: 'flex', mb: 1, gap: 1, flexDirection: { xs: 'column', sm: 'row' }, alignItems: { xs: 'start', sm: 'center' }, flexWrap: 'wrap', justifyContent: 'space-between',}}>
                <Typography level="h2" component="h1">
                    Portfolio
                </Typography>
            </Box>



            <Box className="SearchAndFilters-tabletUp" sx={{borderRadius: 'sm', py: 2, display: { sm: 'flex' }, flexWrap: 'wrap', gap: 1.5, '& > *': {minWidth: { xs: '120px', md: '160px' },},}}>
                <FormControl sx={{ flex: 1 }} size="sm">
                    <FormLabel>Search for tokens</FormLabel>
                    <Input
                        size="sm"
                        placeholder=""
                        startDecorator={<SearchIcon />}
                        disabled={isLoadingQuery}
                        value={searchPreQuery}
                        endDecorator={
                            searchPreQuery.length > 0 && (
                                searchQuery === searchPreQuery ? (
                                    <Button
                                        startDecorator={<SearchOffIcon/>}
                                        onClick={()=>{
                                            setSearchPreQuery('')
                                        }}
                                    >
                                        Clean
                                    </Button>
                                ): (
                                    <Button
                                        startDecorator={<SearchIcon/>}
                                        onClick={()=>{
                                            setSearchQuery(searchPreQuery)
                                        }}
                                    >
                                        Search
                                    </Button>

                                )

                            )
                        }
                        onChange={(event)=>{
                            setSearchPreQuery(event.target.value)
                        }}
                    />
                </FormControl>
                <FormControl size="sm">
                    <FormLabel>Row Limit</FormLabel>
                    <Select
                        size="sm"
                        //disabled={isLoadingQuery}
                        defaultValue={limit}
                        onChange={(event,value)=>{
                            if (value) setLimit(value)
                        }}
                    >

                        <Option value={25}>25</Option>
                        <Option value={50}>50</Option>
                        <Option value={100}>100</Option>
                    </Select>
                </FormControl>
            </Box>

            <Sheet className="OrderTableContainer" variant="outlined" sx={{width: '100%', borderRadius: 'sm', flexShrink: 1, overflow: 'auto', minHeight: 0,}}>
                <Table
                    aria-labelledby="BlockChainTable"
                    stickyHeader
                    stickyFooter
                    hoverRow
                    sx={{
                        '--Table-headerUnderlineThickness': '1px',
                        '--TableRow-hoverBackground': 'var(--joy-palette-background-level1)',
                        '--TableCell-paddingY': '4px',
                        '--TableCell-paddingX': '8px',
                    }}
                >
                    <thead>
                    <tr>
                        <th style={{ width: "45%", padding: '12px 6px' }}>Wallet address</th>
                        <th style={{ width: "15%", padding: '12px 6px' }}>
                            <Link
                                underline="none"
                                color="primary"
                                component="button"
                                onClick={toggleSortOrder}
                                endDecorator={sortOrder === 'desc' ? <KeyboardArrowDownIcon /> : sortOrder === 'asc' ? <KeyboardArrowUpIcon /> : null}
                            >
                                Portfolio
                            </Link>


                        </th>

                        <th style={{ width: "10%",  padding: '12px 6px' }}>Comment</th>
                    </tr>
                    </thead>
                    <tbody>
                    {isLoadingQuery ? (
                        <tr >
                            <td colSpan={3}>
                                <LinearProgress
                                    variant="plain"
                                    size="sm"
                                    sx={{
                                        width: '100%', // Ширина равна 100% родительского элемента
                                        position: 'static', // Абсолютное позиционирование
                                        left: 0, // Позиционируем в левой части CardOverflow
                                        m: 0, // Убираем внешние отступы
                                        borderRadius: 0, // Убираем скругление
                                        zIndex:11,
                                    }}
                                />
                            </td>
                        </tr>
                    ) : (
                        wallets.length > 0 ? (
                            wallets.map((wallet, index) => (
                                <tr key={wallet._id}>
                                    <td>
                                        <Link
                                            href={`https://bscscan.com/address/${wallet.address}`}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            color="success"
                                        >
                                            {wallet.address}
                                        </Link>
                                    </td>
                                    <td>

                                        {wallet.portfolioBalances ? <Link
                                            underline="none"
                                            color="primary"
                                            component="button"
                                            onClick={() => openModalPortfolio(index)}
                                        >
                                            {wallet.portfolioBalances.toFixed(4)}
                                        </Link> : 'N/A'
                                        }
                                    </td>
                                    <td>
                                        { !wallet.comment  ?
                                            (
                                                <Typography
                                                    sx={{
                                                        cursor:"pointer"
                                                    }}
                                                    onClick={()=>{
                                                        setIsOpenEditComment(true);
                                                        setEditCommentId(wallet._id);
                                                        setEditCommentValue(wallet.comment)
                                                    }}
                                                >
                                                    {getFirstTenChars(wallet.comment)}
                                                </Typography>
                                            ):(
                                                <Tooltip
                                                    arrow
                                                    placement="left"
                                                    size="sm"
                                                    title={
                                                        <Box
                                                            sx={{
                                                                display: 'flex',
                                                                flexDirection: 'column',
                                                                maxWidth: 320,
                                                                justifyContent: 'center',
                                                                p: 1,
                                                            }}
                                                        >
                                                            <Box
                                                                sx={{ display: 'flex', gap: 1, width: '100%', mt: 1, cursor: "pointer" }}

                                                            >
                                                                <Typography
                                                                    fontWeight="lg"
                                                                    fontSize="sm"

                                                                >
                                                                    {wallet.comment}
                                                                </Typography>
                                                            </Box>
                                                        </Box>
                                                    }
                                                >
                                                    <Typography
                                                        sx={{
                                                            cursor:"pointer"
                                                        }}
                                                        onClick={()=>{
                                                            setEditCommentId(wallet._id);
                                                            setEditCommentValue(wallet.comment)
                                                            setIsOpenEditComment(true)

                                                        }}
                                                    >
                                                        {getFirstTenChars(wallet.comment)}
                                                    </Typography>
                                                </Tooltip>
                                            )
                                        }

                                    </td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan={3} align="center">No wallets available</td>
                            </tr>
                        )
                    )}

                    </tbody>
                    {!isLoadingQuery && wallets.length > 0 && (
                        <tfoot>
                        <tr>
                            <td colSpan={3} style={{textAlign:'right' }}>{i18n._({ id: "WalletsPage.showiStartToEndusOfT", message: "showing {startWalletsIndex} to {endWalletsIndex} of {totalWallets}", values: { startWalletsIndex:startWalletIndex, endWalletsIndex:endWalletIndex, totalWallets:totalWallets } })}</td>
                        </tr>
                        </tfoot>
                    )}
                </Table>
            </Sheet>
            {wallets.length > 0 && (
                <Pagination page={page} totalPages={totalPages} isLoadingQuery={isLoadingQuery} handlePageChange={handlePageChange}/>
            )}



            <Modal
                aria-labelledby="modal-title"
                aria-describedby="modal-desc"
                open={isModaPortfolio}
                onClose={() => setIsModaPortfolio(false)}
                sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            >
                <Sheet
                    variant="outlined"
                    sx={{
                        maxWidth: 800,
                        borderRadius: 'md',
                        p: 3,
                        boxShadow: 'lg',
                    }}
                >
                    <ModalClose variant="plain" sx={{ m: 1 }} />
                    <Typography
                        component="h2"
                        id="modal-title"
                        level="h4"
                        textColor="inherit"
                        fontWeight="lg"
                        mb={1}
                    >
                        Multichain Portfolio
                    </Typography>
                    <Table aria-label="basic table">
                        <thead>
                        <tr>
                            <th style={{ width: '10%' }}>Chain</th>
                            <th style={{ width: '10%' }}>Token</th>
                            <th style={{ width: '10%' }}>Portfolio&nbsp;(%)</th>
                            <th style={{ width: '10%' }}>Price&nbsp;($)</th>
                            <th style={{ width: '10%' }}>Amount</th>
                            <th style={{ width: '10%' }}>Value&nbsp;($)</th>
                        </tr>
                        </thead>
                        <tbody>
                        {showPortfolio?.list.map((item, index) => (
                            <tr key={index}>
                                <td>{item.chainName}</td>
                                <td>
                                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                        <img src={item.tokenIcon} alt={item.tokenSymb} style={{ width: '24px', height: '24px' }} />
                                        {item.tokenSymb}
                                    </Box>
                                </td>
                                <td>{item.portfolio.toFixed(4)}</td>
                                <td>{item.price.toFixed(4)}</td>
                                <td>{item.amount.toFixed(4)}</td>
                                <td>{item.value.toFixed(4)}</td>
                            </tr>
                        ))}
                        </tbody>
                        <tfoot>
                        <tr>
                            <th scope="row">Totals</th>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td>{showPortfolio?.total.toFixed(4)}</td>
                        </tr>
                        </tfoot>
                    </Table>
                </Sheet>
            </Modal>


            <Modal
                open={isOpenEditComment}
                onClose={() => setIsOpenEditComment(false)}
            >
                <ModalDialog>
                    <ModalClose />
                    <Typography>Modal title</Typography>
                    <Textarea
                        placeholder="Add a comment..."
                        style={{ width: "100%" }}
                        value={editCommentValue}
                        onChange={(e) => setEditCommentValue(e.target.value)}
                        minRows={2}
                        disabled={isLoadingQuery}
                    />
                    <Box sx={{ display: 'flex', gap: 1 }}>
                        <Button
                            loading={isLoadingQuery}
                            onClick={()=>handleSaveComment()}
                        >
                            Save
                        </Button>
                        <Button
                            onClick={cancelEdit}
                            disabled={isLoadingQuery}
                        >
                            Cancel
                        </Button>
                    </Box>
                </ModalDialog>
            </Modal>
        </>
    );
};

export default PorfolioPage;
