// src/components/ProtectedRoute.tsx
import React,{useEffect} from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth} from "../../context/GlobalContext";
import {MyRoute} from "../../routes";
import AccessDeniedPage from "../../pages/404/AccessDenied";
import { useLocation } from 'react-router-dom';
import AccountActivationPage from "../../pages/Auth/AccountActivationPage";

interface ProtectedRouteProps {
    route: MyRoute;
    children: React.ReactNode;
}

export const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ route, children }) => {
    const { user, isAuthChecked,logout } = useAuth();
    const location = useLocation(); // Получаем текущий маршрут

    useEffect(() => {
        const currentTime = Math.floor(Date.now() / 1000);

        if (user?.exp && currentTime > user.exp) {
            logout();
        }
    }, [location]); // Добавляем зависимости для useEffect




    if (!isAuthChecked) {
        return null;
    }


    if (!user) {
        return <Navigate to="/singin" />;
    }

    if (!user?.email_confirmed) {
        return <AccountActivationPage/>;
    }



    // Проверяем, нужно ли защищать маршрут и соответствует ли роль пользователя требуемым ролям
    if (route.protected && route.role && !route.role.includes(user.role)) {
        return <AccessDeniedPage />; // Перенаправляем на страницу "Access Denied"
    }

    return <>{children}</>;
};

export default ProtectedRoute;