// Dashboard.tsx
import React, {JSX, useState, useEffect } from 'react';

//MUI
import Box from '@mui/joy/Box';
import Typography from '@mui/joy/Typography';
import Button from '@mui/joy/Button';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import Select from '@mui/joy/Select';
import Option from '@mui/joy/Option';
import { i18n } from '@lingui/core';
import Dropdown from '@mui/joy/Dropdown';
import MenuButton from '@mui/joy/MenuButton';
import IconButton from '@mui/joy/IconButton';
import Menu from '@mui/joy/Menu';
import MenuItem from '@mui/joy/MenuItem';
import Divider from '@mui/joy/Divider';
import ModalDialog from '@mui/joy/ModalDialog';

import Switch from "@mui/joy/Switch";
import FormHelperText from "@mui/joy/FormHelperText";

import Modal from '@mui/joy/Modal';
import ModalClose from '@mui/joy/ModalClose';
import DialogTitle from '@mui/joy/DialogTitle';
import DialogContent from '@mui/joy/DialogContent';
import DialogActions from '@mui/joy/DialogActions';

// Icons
import PersonIcon from '@mui/icons-material/Person';
import PersonOffIcon from '@mui/icons-material/PersonOff';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import InfoIcon from '@mui/icons-material/Info';
import RoomPreferencesIcon from '@mui/icons-material/RoomPreferences';
import MoreHorizRoundedIcon from '@mui/icons-material/MoreHorizRounded';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

// Components
import { UsersTable } from './components/UsersTable';
import { UsersList } from './components/UsersList';
import CustomSnackbar from '../../../../components/UI/CustomSnackbar';
import {Trans} from "@lingui/macro";
import { msg } from "@lingui/macro";
import { useLingui } from "@lingui/react";

// Functions
import {
    getRoleDisplayName,
    getAllRoles,
} from '../../../../components/UI/getRoleDisplayName';

//Interfaces
import { User,ProfilePermissions } from './components/userInterfaces';
import Stack from "@mui/joy/Stack";
import Input from "@mui/joy/Input";
import TextIncreaseIcon from "@mui/icons-material/TextIncrease";




const UsersListPage = () => {
    const { _ } = useLingui();

    const [isLoading,setIsLoading] = useState(false);
    const roles = getAllRoles(i18n); // Получаем все роли

    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(25);
    const [role, setRole] = useState('all');
    const [emailConfirmed, setEmailConfirmed] = useState('all');
    const [blocked, setBlocked] = useState('all');
    const [searchQuery, setSearchQuery] = useState('');
    const [searchPreQuery, setSearchPreQuery] = useState('');

    const [totalPages, setTotalPages] = useState(0);
    const [totalUsers, setTotalUsers] = useState(0);
    const [users, setUsers] = useState<User[]>([]);


    const [isOpenSb, setIsOpenSb] = useState(false);
    const [messageSb, setMessageSb] = useState('');

    const [selectedIds, setSelectedIds] = useState<number[] | null>(null)

    const [changeRowId,setChangeRowId] = useState<number | null>(null);

    //AddUser
    const [isModalAddUser,setIsModalAddUser] =useState(false)
    const [isLoadingQuery,setIsLoadingQuery] = useState(false);

    // Функция для загрузки пользователей
    const fetchUsers = async () => {
        try {
            setUsers([]);
            setIsLoading(true);
            setSelectedIds([]);
            const token = localStorage.getItem('authToken'); // Получаем токен из localStorage
            const queryParams = {
                page,
                limit,
                role,
                blocked,
                emailConfirmed,
                searchQuery
            };
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/admin/users/get-all-users`, {
                method: 'POST', // Изменение метода на POST
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}` // Передаем токен в заголовке запроса
                },
                body: JSON.stringify(queryParams) // Передаем параметры в теле запроса
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await response.json();

            setUsers(data.users);
            setTotalPages(data.totalPages);
            setTotalUsers(data.totalUsers);
        } catch (error) {
            console.error('Error fetching users:', error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchUsers(); // Вызываем функцию fetchUsers при монтировании компонента
    }, [page]); // Пустой массив зависимостей, чтобы вызвать функцию один раз при монтировании

    useEffect(() => {
        setPage(1)
        fetchUsers(); // Вызываем функцию fetchUsers при монтировании компонента
    }, [limit, role, emailConfirmed, searchQuery,blocked]); // Пустой массив зависимостей, чтобы вызвать функцию один раз при монтировании


    //Filters
    const renderFilters = () => (
        <React.Fragment>
            <FormControl size="sm">
                <FormLabel><Trans id="UsersListPage.role">Role</Trans></FormLabel>
                <Select
                    size="sm"
                    value={role}
                    onChange={handleRoleChange}
                    disabled={isLoading}
                >

                    <Option value="all"><Trans id="UsersListPage.all">All</Trans></Option>
                    {roles.map((role) => (
                        <Option
                            key={role.role}
                            value={role.role}
                            color={role.color}
                        >
                            {role.icon && React.createElement(role.icon)}{role.name}
                        </Option>
                    ))}
                </Select>
            </FormControl>
            <FormControl size="sm">
                <FormLabel><Trans id="UsersListPage.email">Email</Trans></FormLabel>
                <Select
                    size="sm"
                    slotProps={{ button: { sx: { whiteSpace: 'nowrap' } } }}
                    value={emailConfirmed}
                    onChange={handleEmailConfirmedChange}
                    disabled={isLoading}
                >
                    <Option value="all"><Trans id="UsersListPage.all">All</Trans></Option>
                    <Option value="confirmed" color="success"><PersonIcon/><Trans id="UsersListPage.confirmed">Confirmed</Trans></Option>
                    <Option value="unconfirmed" color="danger"><PersonOffIcon/><Trans id="UsersListPage.uncon">Unconfirmed</Trans></Option>
                </Select>
            </FormControl>
            <FormControl size="sm">
                <FormLabel><Trans id="UsersListPage.block">Blocked</Trans></FormLabel>
                <Select
                    size="sm"
                    placeholder="Filter by status"
                    slotProps={{ button: { sx: { whiteSpace: 'nowrap' } } }}
                    value={blocked}
                    onChange={handleBlockedChange}
                    disabled={isLoading}
                >
                    <Option value="all"><Trans id="UsersListPage.all">All</Trans></Option>
                    <Option value="active" color="success"><PersonIcon/><Trans id="UsersListPage.activ">Active</Trans></Option>
                    <Option value="blocked" color="danger"><PersonOffIcon/><Trans id="UsersListPage.block">Blocked</Trans></Option>
                </Select>
            </FormControl>

            <FormControl size="sm">
                <FormLabel><Trans id="UsersListPage.rowLimit">Row Limit</Trans></FormLabel>
                <Select
                    size="sm"
                    slotProps={{ button: { sx: { whiteSpace: 'nowrap' } } }}
                    value={limit}
                    onChange={handleLimitChange}
                    disabled={isLoading}
                >
                    <Option value={25}>25</Option>
                    <Option value={50}>50</Option>
                    <Option value={100}>100</Option>
                </Select>
            </FormControl>
        </React.Fragment>
    );

    // Modal Info
    const [modalInfoOpen, setModalInfoOpen] = useState(false);
    const [modalInfoUser, setModalInfoUser] = useState<User | null>(null);

    const handleShowUserInfo = (rowID: number) => {
        const user = users[rowID];
        if (user) {
            setModalInfoUser(user);
            setModalInfoOpen(true);
        } else {
            console.error('User not found');
        }
    };

    // Modal Delete
    const [modalDeleteOpen, setModalDeleteOpen] = useState(false);
    const [modalDeleteUser, setModalDeleteUser] = useState<User[] | null>(null);

    const handleDeleteUser = (rowID: number) => {
        const user = users[rowID];
        setModalDeleteUser(null);
        if (user) {
            setModalDeleteUser([user]);
            setModalDeleteOpen(true);
        } else {
            console.error('User not found');
        }
    };



    //Modal Email Confirm
    const [modalEmailOpen, setModalEmailOpen] = useState(false);
    const [modalEmailUser, setModalEmailUser] = useState<User | null>(null);

    const handleEmail = (rowID: number) => {
        const user = users[rowID];
        if (user) {
            setModalEmailUser(user);
            setModalEmailOpen(true);
        } else {
            console.error('User not found');
        }
    };


    //Modal Blocked
    const [modalBlockedOpen, setModalBlockedOpen] = useState(false);
    const [modalBlockedUser, setModalBlockedUser] = useState<User | null>(null);

    const handleBlocked = (rowID: number) => {
        const user = users[rowID];
        if (user) {
            setModalBlockedUser(user);
            setModalBlockedOpen(true);

        } else {
            console.error('User not found');
        }
    };

    // Modal Role & Permissioins
    const [modalPermissionsOpen, setModalPermissionsOpen] = useState(false);
    const [modalPermissionsUser, setModalPermissionsUser] = useState<User | null>(null);
    const [profileRole, setProfileRole] = useState('');
    const [profileRoleNew, setProfileRoleNew] = useState('');
    const [isChangedRP, setIsChangedRP] = useState(false);
    const [profilePermissions, setProfilePermissions] = useState<ProfilePermissions>({
        change_name: false,
        change_password: false,
        change_email: false,
        change_login: false,
    });

    const [profilePermissionsNew, setProfilePermissionsNew] = useState<ProfilePermissions>({
        change_name: false,
        change_password: false,
        change_email: false,
        change_login: false,
    });

    useEffect(() => {
        const isRoleChanged = profileRole !== profileRoleNew;
        const isPermissionsChanged = JSON.stringify(profilePermissions) !== JSON.stringify(profilePermissionsNew);
        setIsChangedRP(isRoleChanged || isPermissionsChanged);
    }, [profileRole, profileRoleNew, profilePermissions, profilePermissionsNew]); // Обновление при изменении этих значений

   const  handleModalRoleChaneg = (
        event: React.SyntheticEvent | null,
        newValue: string | null,
    ) => {
       setProfileRoleNew(newValue!);
    };

    const handleChangePermission = (rowID: number) => {
        const user = users[rowID];
        setChangeRowId(rowID);
        if (user) {
            setModalPermissionsUser(user);
            setProfilePermissions(user.user_data.profile_permissions || {
                change_name: false,
                change_password: false,
                change_email: false,
                change_login: false,
            });
            setProfilePermissionsNew(user.user_data.profile_permissions || {
                change_name: false,
                change_password: false,
                change_email: false,
                change_login: false,
            });
            setProfileRole(user.role);
            setProfileRoleNew(user.role);
            setModalPermissionsOpen(true);
        } else {
            console.error('User not found');
        }
    };


    // Row Menu (Action for user)
    const rowMenu = (rowID: number): JSX.Element | null => {
        if (rowID < 0) return null
        return (
            <React.Fragment>
            <Dropdown>
                <MenuButton
                    slots={{ root: IconButton }}
                    slotProps={{ root: { variant: 'plain', color: 'neutral', size: 'sm' } }}
                    disabled={isLoading}
                >
                    <MoreHorizRoundedIcon />
                </MenuButton>
                <Menu size="sm" sx={{ minWidth: 140 }}>
                    <MenuItem
                        color="primary"
                        onClick={()=>{handleShowUserInfo(rowID)}}
                    >
                        <InfoIcon/><Typography><Trans id="UsersListPage.info">Info</Trans></Typography>
                    </MenuItem>
                    <MenuItem
                        onClick={() => handleEmail(rowID)}
                        disabled={users[rowID].email_confirmed}
                    >
                        <AlternateEmailIcon/><Typography><Trans id="UsersListPage.verifEmail">Verify email</Trans></Typography>
                    </MenuItem>
                    <MenuItem
                        color={users[rowID].blocked ? "success" : "danger"}
                        onClick={() => handleBlocked(rowID)}
                    >
                        {users[rowID].blocked ?
                                <>
                                    <PersonIcon/><Typography><Trans id="UsersListPage.unblo">Unblocked</Trans></Typography>
                                </>
                            :
                                <><PersonOffIcon/>
                                    <Typography><Trans id="UsersListPage.block">Blocked</Trans></Typography>
                                </>
                        }
                    </MenuItem>
                    <MenuItem
                        color="warning"
                        onClick={() => handleChangePermission(rowID)}
                    >
                        <RoomPreferencesIcon/><Typography><Trans id="UsersListPage.rolePermi">Role & Permissions</Trans></Typography>
                    </MenuItem>
                    <Divider />
                    <MenuItem
                        color="danger"
                        onClick={()=>{handleDeleteUser(rowID)}}
                    >
                        <DeleteForeverIcon/><Typography><Trans id="UsersListPage.delet">Delete</Trans></Typography>
                    </MenuItem>
                </Menu>
            </Dropdown>
            </React.Fragment>
        );
    }
    const handleBlockedChange = (
        event: React.SyntheticEvent | null,
        value: string | null
    ) => {
        if (value !== null) {
            setBlocked(value);
        }
    };
    const handleEmailConfirmedChange = (
        event: React.SyntheticEvent | null,
        value: string | null
    ) => {
        if (value !== null) {
            setEmailConfirmed(value);
        }
    };
    const handleRoleChange = (
        event: React.SyntheticEvent | null,
        value: string | null
    ) => {
        if (value !== null) {
            setRole(value);
        }
    };
    const handleLimitChange = (
        event: React.SyntheticEvent | null,
        value: number | null
    ) => {
        if (value !== null) {
            if (!isNaN(value)) {
                setLimit(value);
            } else {
                console.error("Invalid number input for limit");
            }
        }
    };
    const handlePageChange = (newPage:number) => {
        setPage(newPage);
    };


    //Selected Menu (down)
    const renderSelectedRowMenu = (): JSX.Element | null => {
        //selectedIds
        if ((selectedIds?.length ?? 0) < 1) return null;
        return (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography>Users selected: {selectedIds?.length}</Typography>
                <Typography
                    sx={{
                        ml:1
                    }}
                >| <Trans id="UsersListPage.actio">Actions</Trans>:</Typography>
                <IconButton
                    color="danger"
                    onClick={handleDeleteSelectedUsers}
                    size="sm"
                    variant="soft"
                    sx={{
                        ml:1
                    }}
                >
                    <DeleteForeverIcon />
                </IconButton>
            </Box>
        )
    }



    // Save Role & Permissions
    interface Changes {
        role?: string;
        profilePermissions?: ProfilePermissions;
    }
    const handleSavePermissions = async () => {
        const changes: Changes = {}; // Use the Changes interface here
        const  user = modalPermissionsUser;
        if (profileRole !== profileRoleNew) {
            changes.role = profileRoleNew;
        }
        if (JSON.stringify(profilePermissions) !== JSON.stringify(profilePermissionsNew)) {
            changes.profilePermissions = profilePermissionsNew;
        }

        if (user?.id && (changes.role || changes.profilePermissions)) {
            setModalPermissionsOpen(false);
            const success = await updateUserRoleAndPermissions(user.id, changes);
            if (success) {
                // Update the local users array
                setUsers(users.map((u, index) => {
                    if (index === changeRowId) {
                        return { ...u, role: changes.role || u.role, user_data: { ...u.user_data, profile_permissions: changes.profilePermissions || u.user_data.profile_permissions } };
                    }
                    return u;
                }));
            }
        }

    };
    const updateUserRoleAndPermissions = async (userId:string, changes:Changes) => {
        try {
            setIsLoading(true);
            const token = localStorage.getItem('authToken'); // Assuming you store the token in local storage
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/admin/users/role-permissions`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({
                    userId,
                    ...changes
                })
            });

            const data = await response.json();

            if (response.ok) {
                // Handle the successful response here
                setMessageSb(_(msg({ id: "UsersListPage.userRoleAndPermiUpda", message: "User role and permissions updated successfully" })));
                setIsOpenSb(true);
                return true;
            } else {
                // Handle the error response here
                setMessageSb(data.message || _(msg({ id: "UsersListPage.errorUpdatUserRoleAn", message: "Error updating user role and permissions" })));
                setIsOpenSb(true);
                return false;
            }
        } catch (error) {
            console.error('Network error:', error);
            //setMessageSb(error || 'Network error');
            //setIsOpenSb(true);
            return false;
        } finally {
            setIsLoading(false);
        }
    };

    // Save Email Verification
    const handleConfirmEmailVerification = async () => {
        if (modalEmailUser?.id) {
            setModalEmailOpen(false); // Close the modal
            const success = await verifyEmail(modalEmailUser.id);
            if (success) {
                // Update the local users array
                setUsers(users.map((u) => {
                    if (u.id === modalEmailUser.id) {
                        return { ...u, email_confirmed: true };
                    }
                    return u;
                }));
                setMessageSb(_(msg({ id: "UsersListPage.emailVerifSucce", message: "Email verified successfully" })));
                setIsOpenSb(true);
            }
        }
    };
    const verifyEmail = async (userId: string) => {
        try {
            setIsLoading(true);
            const token = localStorage.getItem('authToken'); // Assuming you store the token in local storage
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/admin/users/email-verify`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({ userId })
            });

            const data = await response.json();

            if (response.ok) {
                // Handle the successful response here
                setMessageSb(_(msg({ id: "UsersListPage.emailVerifSucce", message: "Email verified successfully" })));
                setIsOpenSb(true);
                return true;
            } else {
                // Handle the error response here
                setMessageSb(data.message || _(msg({ id: "UsersListPage.errorVerifEmail", message: "Error verifying email" })));
                setIsOpenSb(true);
                return false;
            }
        } catch (error) {
            console.error('Network error:', error);
            //setMessageSb(error || 'Network error');
            //setIsOpenSb(true);
            return false;
        } finally {
            setIsLoading(false);
        }
    };


    //Save user Block/Unblock
    const handleToggleUserBlockedStatus = async () => {
        if (modalBlockedUser?.id) {
            setModalBlockedOpen(false); // Close the modal
            const isBlocked = !modalBlockedUser.blocked; // Toggle the blocked status
            const success = await toggleBlockedStatus(modalBlockedUser.id, isBlocked);
            if (success) {
                // Update the local users array
                setUsers(users.map((u) => {
                    if (u.id === modalBlockedUser.id) {
                        return { ...u, blocked: isBlocked };
                    }
                    return u;
                }));
                const textBloked = isBlocked ? _(msg({ id: "UsersListPage.blocked_uncups", message: "blocked" })) : _(msg({ id: "UsersListPage.unblobloked_uncups", message: "unblocked" }))
                setMessageSb(i18n._({ id: "UsersListPage.userTextbSucce", message: "User {textBloked} successfully", values: { textBloked:textBloked } }));
                setIsOpenSb(true);
            }
        }
    };
    const toggleBlockedStatus = async (userId: string, isBlocked: boolean) => {
        try {
            setIsLoading(true);
            const token = localStorage.getItem('authToken'); // Assuming you store the token in local storage
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/admin/users/blocked`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({ userId, blocked: isBlocked })
            });

            const data = await response.json();

            if (response.ok) {
                // Handle the successful response here
                return true;
            } else {
                // Handle the error response here
                const textBloked = isBlocked ? _(msg({ id: "UsersListPage.blocked_uncups", message: "blocked" })) : _(msg({ id: "UsersListPage.unblobloked_uncups", message: "unblocked" }))
                setMessageSb(data.message || i18n._({ id: "UsersListPage.errorTextbUser", message: "Error {textBloked} user", values: { textBloked:textBloked } }));
                setIsOpenSb(true);
                return false;
            }
        } catch (error) {
            console.error('Network error:', error);
            return false;
        } finally {
            setIsLoading(false);
        }
    };


    //Delete User/s


    // Delete Users
    const handleDeleteUsers = async () => {
        if (modalDeleteUser && modalDeleteUser.length > 0) {
            setModalDeleteOpen(false); // Close the modal
            const userIdsToDelete = modalDeleteUser.map(user => user.id); // Get array of user IDs
            const result = await deleteUsers(userIdsToDelete);
            if (result && result.success.length) {
                // Update the local users array
                setUsers(users.filter((user) => !result.success.includes(user.id)));
                setMessageSb(i18n._({ id: "UsersListPage.resulUsersDeletSucce", message: "{resultsuccesslength} user(s) deleted successfully", values: { resultsuccesslength:result.success.length } }));
                setIsOpenSb(true);
            }
        }
    };
    const handleDeleteSelectedUsers = () => {
        setModalDeleteUser(null);
        if (selectedIds && selectedIds.length > 0) {
            // Map the indices to actual user objects
            const usersToDelete = selectedIds.map(index => users[index]);

            // Update the state to reflect the users to be deleted and open the modal
            setModalDeleteUser(usersToDelete);
            setModalDeleteOpen(true);
        } else {
            console.error('No users selected for deletion');
        }
    };
    const deleteUsers = async (userIds: string[]) => {
        try {
            setIsLoading(true);
            const token = localStorage.getItem('authToken'); // Assuming you store the token in local storage
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/admin/users/delete-users`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({ userIds })
            });

            const data = await response.json();

            if (response.ok) {
                // Handle the successful response here
                if (data.success.length === users.length && page > 1)
                {
                    setPage(page - 1);
                    fetchUsers()
                }
                return data;
            } else {
                // Handle the error response here

                setMessageSb(data.message || i18n._({ id: "UsersListPage.errorDeletUsers", message: "Error deleting users" }));
                setIsOpenSb(true);
                return null;
            }
        } catch (error) {
            console.error('Network error:', error);
            return null;
        } finally {
            setIsLoading(false);
            setModalDeleteUser(null);
            setSelectedIds(null)
        }
    };



    //Add user
    const [sendNotification, setSendNotification] = useState(true);
    const [formData, setFormData] = useState({
        login: '',
        email: '',
        name: '',
        password: '',
        role: 'user', // default role
    });
    const [formErrors, setFormErrors] = useState({
        login: false,
        email: false,
        name: false,
        role:false,
        password: false,
    });
    const [errorMessages, setErrorMessages] = useState({
        login: '',
        email: '',
        name: '',
        role:'',
        password: '',
    });
    const resetFormAddUser = () => {
        setFormData({
            login: '',
            email: '',
            name: '',
            password: '',
            role: 'user',
        });
        setFormErrors({
            login: false,
            email: false,
            name: false,
            role: false,
            password: false,
        });
        setErrorMessages({
            login: '',
            email: '',
            name: '',
            role: '',
            password: '',
        });
        setSendNotification(false);
    };
    const handleInputChangeAddUser = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSelectChangeAddUser = (name: string, newValue: string | null) => {
        setFormData({ ...formData, [name]: newValue });
    };
    const generatePassword = () => {
        const length = Math.floor(Math.random() * (12 - 6 + 1)) + 6; // длина от 6 до 12 символов
        const charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*";
        let password = "";
        for (let i = 0, n = charset.length; i < length; ++i) {
            password += charset.charAt(Math.floor(Math.random() * n));
        }
        return password;
    }
    const handleGeneratePassword = () => {
        const newPassword = generatePassword();
        setFormData({ ...formData, password: newPassword });
    };

    const addUser = async (userData:{}) => {
        try {
            const token = localStorage.getItem('authToken'); // Получение токена из локального хранилища

            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/admin/users/add-user`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify(userData)
            });

            const data = await response.json();
            if (response.ok) {
                return true
                // Обработка успешного добавления пользователя
            } else {
                console.error('Error adding user:', data.message);
                // Обработка ошибок при добавлении пользователя
                return false
            }
        } catch (error) {
            console.error('Network error:', error);
            return false
        }
    };


    const verifyOnServer = async (key:string, value:string) => {
        try {
            const queryParams = new URLSearchParams({ [key]: value }).toString();
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/registration/verify?${queryParams}`, {
                method: 'GET',
                headers: { 'Content-Type': 'application/json' }
            });
            const data = await response.json();

            return {
                status: response.status === 200,
                message: data.message
            };
        } catch (error) {
            console.error('Error verifying data:', error);
            return {
                status: false,
                message: `Server error: ${error}`
            };
        }
    };

    const sendFormAddUser = async () => {
        setIsLoadingQuery(true);
        let isValid = true;
        const errors = { ...formErrors };
        const messages = { ...errorMessages };

        // Валидация логина
        const loginRegex = /^[a-zA-Z0-9_]{5,}$/;
        if (!loginRegex.test(formData.login)) {
            errors.login = true;
            messages.login = _(msg({ id: "UsersListPage.loginMustBeAtLeastCh", message: "Login must be at least 5 characters and contain only letters, numbers, and underscores" }));
            isValid = false;
        } else {
            // Проверка уникальности логина
            const loginResult = await verifyOnServer('login', formData.login);
            if (!loginResult.status) {
                errors.login = true;
                messages.login = loginResult.message;
                isValid = false;
            }else {
                errors.login = false;
                messages.login = '';
            }
        }

        // Валидация email
        const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        if (!emailRegex.test(formData.email)) {
            errors.email = true;
            messages.email =_(msg({ id: "UsersListPage.InvalEmailForma", message: " Invalid email format" }));
            isValid = false;
        } else {
            // Проверка уникальности email
            const emailResult = await verifyOnServer('email', formData.email);
            if (!emailResult.status) {
                errors.email = true;
                messages.email = emailResult.message;
                isValid = false;
            } else {
                errors.email = false;
                messages.email = '';
            }
        }

        // Валидация имени
        if (formData.name.length < 3) {
            errors.name = true;
            messages.name = _(msg({ id: "UsersListPage.nameMustBeAtLeastCha", message: "Name must be at least 3 characters" }));
            isValid = false;
        }else {
            errors.name = false;
            messages.name = '';
        }

        // Валидация пароля
        const passwordRegex = /^(?=.*\d)(?=.*[!@#$%^&*]).{5,}$/;
        if (!passwordRegex.test(formData.password)) {
            errors.password = true;
            messages.password = _(msg({ id: "UsersListPage.passwMustBeAtLeastCh", message: "Password must be at least 5 characters and include at least one digit and one special character" }));
            isValid = false;
        }else {
            errors.password = false;
            messages.password = '';
        }

        setFormErrors(errors);
        setErrorMessages(messages);

        if (isValid) {
            const dataToSend = {
                ...formData,
                sendNotification
            };
            console.log(dataToSend);
            const result = await addUser(dataToSend)
            resetFormAddUser();
            setIsModalAddUser(false);
            if (result){
                setMessageSb(_(msg({ id: "UsersListPage.newUserAdded", message: "New user added" })));
                setIsOpenSb(true);
            }
            // здесь можно добавить логику для отправки данных на сервер
        }
        setIsLoadingQuery(false);
    };

    const closeModalAddUser = () => {
        resetFormAddUser();
        setIsModalAddUser(false)
    }


    return (
        <>
            <Box sx={{display: 'flex', mb: 1, gap: 1, flexDirection: { xs: 'column', sm: 'row' }, alignItems: { xs: 'start', sm: 'center' }, flexWrap: 'wrap', justifyContent: 'space-between',}}>
                <Typography level="h2" component="h1">
                    <Trans id="UsersListPage.usersList">Users list</Trans>
                </Typography>
                <Button
                    color="primary"
                    size="sm"
                    onClick={()=> {
                        setIsModalAddUser(true)
                    }}
                >
                    <Trans id="UsersListPage.addUser">Add user</Trans>
                </Button>
            </Box>


            <UsersTable
                users={users}
                isLoading={isLoading}
                i18n={i18n}
                searchQuery={searchQuery}
                searchPreQuery={searchPreQuery}
                setSearchQuery={setSearchQuery}
                setSearchPreQuery={setSearchPreQuery}
                renderFilters={renderFilters}
                rowMenu={rowMenu}
                page={page}
                totalPages={totalPages}
                handlePageChange={handlePageChange}
                totalUsers={totalUsers}
                limit={limit}

                selectedIds={selectedIds}
                setSelectedIds={setSelectedIds}
                renderSelectedRowMenu={renderSelectedRowMenu}
            />




            <UsersList
                users={users}
                isLoading={isLoading}
                i18n={i18n}
                searchQuery={searchQuery}
                searchPreQuery={searchPreQuery}
                setSearchQuery={setSearchQuery}
                setSearchPreQuery={setSearchPreQuery}
                renderFilters={renderFilters}
                rowMenu={rowMenu}
                page={page}
                totalPages={totalPages}
                handlePageChange={handlePageChange}
            />

            {/* Modal Info */}
            <Modal
                aria-labelledby="modal-title"
                aria-describedby="modal-desc"
                open={modalInfoOpen}
                onClose={() => setModalInfoOpen(false)}
                sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            >

                <ModalDialog
                    variant="outlined"
                    sx={{
                        maxWidth: 500,
                        borderRadius: 'md',
                        p: 3,
                        boxShadow: 'lg',
                    }}
                >
                    <ModalClose variant="plain" sx={{ m: 1 }} onClick={() => setModalInfoOpen(false)} />
                    <DialogTitle>
                       <Trans id="UsersListPage.UserInfo">User Info</Trans>
                    </DialogTitle>
                    <Divider />
                    <DialogContent sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                        <FormControl
                            orientation="horizontal"
                            sx={{ width: 400, justifyContent: 'space-between' }}
                        >
                            <FormLabel>ID</FormLabel>
                            {modalInfoUser?.id}
                        </FormControl>
                        <FormControl
                            orientation="horizontal"
                            sx={{ width: 400, justifyContent: 'space-between' }}
                        >
                            <FormLabel><Trans id="UsersListPage.name">Name</Trans></FormLabel>
                            {modalInfoUser?.user_data.name}
                        </FormControl>
                        <FormControl
                            orientation="horizontal"
                            sx={{ width: 400, justifyContent: 'space-between', mb: 1 }}
                        >
                            <FormLabel><Trans id="UsersListPage.login">Login</Trans></FormLabel>
                            {modalInfoUser?.user_data.login}
                        </FormControl>
                        <FormControl
                            orientation="horizontal"
                            sx={{ width: 400, justifyContent: 'space-between', mb: 1 }}
                        >
                            <FormLabel><Trans id="UsersListPage.email">Email</Trans></FormLabel>
                            {modalInfoUser?.user_data.email}
                        </FormControl>
                        <FormControl
                            orientation="horizontal"
                            sx={{ width: 400, justifyContent: 'space-between', mb: 1 }}
                        >
                            <FormLabel><Trans id="UsersListPage.role">Role</Trans></FormLabel>
                            {getRoleDisplayName(modalInfoUser?.role ?? '', i18n)}
                        </FormControl>
                        <FormControl
                            orientation="horizontal"
                            sx={{ width: 400, justifyContent: 'space-between', mb: 1 }}
                        >
                            <FormLabel><Trans id="UsersListPage.emailConfi">Email Confirmed</Trans></FormLabel>
                            {modalInfoUser?.email_confirmed ? 'Yes' : 'No'}
                        </FormControl>
                        <FormControl
                            orientation="horizontal"
                            sx={{ width: 400, justifyContent: 'space-between', mb: 1 }}
                        >
                            <FormLabel><Trans id="UsersListPage.block_1">Blocked</Trans></FormLabel>
                            {modalInfoUser?.blocked ? _(msg({ id: "UsersListPage.yes", message: "Yes" })) : <Trans id="UsersListPage.no">No</Trans>}
                        </FormControl>
                        <FormControl
                            orientation="horizontal"
                            sx={{ width: 400, justifyContent: 'space-between', mb: 1 }}
                        >
                            <FormLabel><Trans id="UsersListPage.lastLogin">Last Login</Trans></FormLabel>
                            {modalInfoUser?.lastLogin ? new Date(modalInfoUser.lastLogin).toLocaleString(i18n.locale) : 'N/A'}
                        </FormControl>
                        <FormControl
                            orientation="horizontal"
                            sx={{ width: 400, justifyContent: 'space-between', mb: 1 }}
                        >
                            <FormLabel><Trans id="UsersListPage.creatAt">Created At</Trans></FormLabel>
                            {modalInfoUser?.createdAt ? new Date(modalInfoUser.createdAt).toLocaleString(i18n.locale) : 'N/A'}
                        </FormControl>
                        <FormControl
                            orientation="horizontal"
                            sx={{ width: 400, justifyContent: 'space-between', mb: 1 }}
                        >
                            <FormLabel><Trans id="UsersListPage.updatAt">Updated At</Trans></FormLabel>
                            {modalInfoUser?.updatedAt ? new Date(modalInfoUser.updatedAt).toLocaleString(i18n.locale) : 'N/A'}
                        </FormControl>

                    </DialogContent>
                </ModalDialog>
            </Modal>



            {/* Modal for Email Verification */}
            <Modal open={modalEmailOpen} onClose={() => setModalEmailOpen(false)}>
                <ModalDialog
                    variant="outlined"
                    sx={{
                        maxWidth: 500,
                        borderRadius: 'md',
                        p: 3,
                        boxShadow: 'lg',
                    }}
                >
                    <ModalClose variant="plain" sx={{ m: 1 }} onClick={() => setModalEmailOpen(false)} />
                    <DialogTitle>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                            }}
                        >
                            <Typography>
                                <Trans id="UsersListPage.emailVerif">Email Verification</Trans>
                            </Typography>
                            <Typography>
                                {i18n._({id: "UsersListPage.forModal", message: "for {modalEmailUser}", values: {modalEmailUser: modalEmailUser?.user_data.name}})}
                            </Typography>
                        </Box>

                    </DialogTitle>
                    <Divider/>
                    <DialogContent sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                        {i18n._({id: "UsersListPage.doYouReallWantToConf", message: "Do you really want to confirm the email for the user {login} ({email})?", values: {login: modalEmailUser?.user_data.login,email:modalEmailUser?.user_data.email}})}
                    </DialogContent>
                    <DialogActions>
                        <Button
                            variant="plain"
                            color="neutral"
                            onClick={() => setModalEmailOpen(false)}
                        >
                            <Trans id="UsersListPage.cance">Cancel</Trans>
                        </Button>
                        <Button
                            variant="solid"
                            color="danger"
                            onClick={handleConfirmEmailVerification}
                        >
                            <Trans id="UsersListPage.confirm">Confirm</Trans>
                        </Button>
                    </DialogActions>
                </ModalDialog>
            </Modal>

            {/* Modal for Blocked/Unblocked */}
            <Modal open={modalBlockedOpen} onClose={() => setModalBlockedOpen(false)}>
                 <ModalDialog
                    variant="outlined"
                    sx={{
                        maxWidth: 500,
                        borderRadius: 'md',
                        p: 3,
                        boxShadow: 'lg',
                    }}
                 >
                    <ModalClose variant="plain" sx={{ m: 1 }} onClick={() => setModalBlockedOpen(false)} />
                    <DialogTitle>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                            }}
                        >

                            {modalBlockedUser?.blocked ? <Typography color="success"><Trans id="UsersListPage.unblo_2">Unblocked</Trans></Typography>  : <Typography color="danger"><Trans id="UsersListPage.block_2">Blocked</Trans></Typography> }
                            <Typography>
                                {i18n._({id: "UsersListPage.userModal", message: "user {modalBlockedUser}", values: {modalBlockedUser: modalBlockedUser?.user_data.name}})}
                            </Typography>
                        </Box>

                    </DialogTitle>
                    <Divider/>
                    <DialogContent sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>

                        {i18n._({
                            id: "UsersListPage.doYouReallWantToModa", message: "Do you really want to {blocked} the user {login} ({email}) ?",
                            values: {
                                blocked: modalBlockedUser?.blocked ? <Typography color="success">unblocked</Typography> : <Typography color="danger">blocked</Typography>,
                                login: modalBlockedUser?.user_data.login,
                                email: modalBlockedUser?.user_data.email
                            }
                        })}
                    </DialogContent>
                    <DialogActions>
                        <Button
                            variant="plain"
                            color="neutral"
                            onClick={() => setModalBlockedOpen(false)}
                        >
                            <Trans id="UsersListPage.cance">Cancel</Trans>
                        </Button>
                        <Button
                            variant="solid"
                            color={modalBlockedUser?.blocked ? "success"  : "danger" }
                            onClick={handleToggleUserBlockedStatus}

                        >
                            {modalBlockedUser?.blocked ? _(msg({ id: "UsersListPage.unblo_5", message: "Unblocked" }))  : _(msg({ id: "UsersListPage.block_6", message: "Blocked" })) }
                        </Button>
                    </DialogActions>
                </ModalDialog>
            </Modal>



            {/* Modal for Permissions */}
            <Modal
                open={modalPermissionsOpen}
                onClose={() => setModalPermissionsOpen(false)}
                sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            >
                <ModalDialog
                    variant="outlined"
                    sx={{
                        maxWidth: 500,
                        borderRadius: 'md',
                        p: 3,
                        boxShadow: 'lg',
                    }}
                >
                    <ModalClose variant="plain" sx={{ m: 1 }} onClick={() => setModalPermissionsOpen(false)} />
                    <DialogTitle>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                            }}
                        >
                            <Typography>
                                <Trans id="UsersListPage.rolePermi">Role & Permissions</Trans>
                            </Typography>
                            <Typography>
                                {i18n._({id: "UsersListPage.forModalUser", message: "for {modalPermissionsUser}", values: {modalPermissionsUser: modalPermissionsUser?.user_data.name}})}
                            </Typography>
                        </Box>

                    </DialogTitle>
                    <Divider/>
                    <DialogContent sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                        <FormControl
                            orientation="horizontal"
                            sx={{ width: 300, justifyContent: 'space-between' }}
                        >
                            <div>
                                <FormLabel><Trans id="UsersListPage.role">Role</Trans></FormLabel>
                                <FormHelperText sx={{ mt: 0 }}></FormHelperText>
                            </div>
                            <Select
                                defaultValue={profileRoleNew}
                                onChange={handleModalRoleChaneg} // Обновление profileRoleNew при изменении
                            >
                                {roles.map((role) => (
                                    <Option
                                        key={role.role}
                                        value={role.role}
                                        color={role.color}
                                    >
                                        {role.icon && React.createElement(role.icon)}{role.name}
                                    </Option>
                                ))}
                            </Select>
                        </FormControl>
                        <Divider/>
                        {/* Разместите здесь переключатели для разрешений */}
                        <FormControl
                            orientation="horizontal"
                            sx={{ width: 300, justifyContent: 'space-between' }}
                        >
                            <div>
                                <FormLabel><Trans id="UsersListPage.changName">Change name</Trans></FormLabel>
                                <FormHelperText sx={{ mt: 0 }}><Trans id="UsersListPage.userCanChangName">User can change name</Trans></FormHelperText>
                            </div>
                            <Switch
                                checked={profilePermissionsNew.change_name}
                                onChange={(event) => setProfilePermissionsNew({ ...profilePermissionsNew, change_name: event.target.checked })}
                                color={profilePermissionsNew.change_name ? 'success' : 'neutral'}
                                variant={profilePermissionsNew.change_name ? 'solid' : 'outlined'}
                                endDecorator={profilePermissionsNew.change_name ? _(msg({ id: "UsersListPage.on", message: "On" })) : _(msg({ id: "UsersListPage.off", message: "Off" }))}
                                slotProps={{
                                    endDecorator: {
                                        sx: {
                                            minWidth: 24,
                                        },
                                    },
                                }}
                            />
                        </FormControl>

                        <FormControl
                            orientation="horizontal"
                            sx={{ width: 300, justifyContent: 'space-between' }}
                        >
                            <div>
                                <FormLabel><Trans id="UsersListPage.changPassw">Change Password</Trans></FormLabel>
                                <FormHelperText sx={{ mt: 0 }}><Trans id="UsersListPage.userCanChangPassw">User can change password</Trans></FormHelperText>
                            </div>
                            <Switch
                                checked={profilePermissionsNew.change_password}
                                onChange={(event) => setProfilePermissionsNew({ ...profilePermissionsNew, change_password: event.target.checked })}
                                color={profilePermissionsNew.change_password ? 'success' : 'neutral'}
                                variant={profilePermissionsNew.change_password ? 'solid' : 'outlined'}
                                endDecorator={profilePermissionsNew.change_password ? _(msg({ id: "UsersListPage.on", message: "On" })) : _(msg({ id: "UsersListPage.off", message: "Off" }))}
                                slotProps={{
                                    endDecorator: {
                                        sx: {
                                            minWidth: 24,
                                        },
                                    },
                                }}
                            />
                        </FormControl>

                        <FormControl
                            orientation="horizontal"
                            sx={{ width: 300, justifyContent: 'space-between' }}
                        >
                            <div>
                                <FormLabel><Trans id="UsersListPage.changEmail">Change Email</Trans></FormLabel>
                                <FormHelperText sx={{ mt: 0 }}><Trans id="UsersListPage.userCanChangEmail">User can change email</Trans></FormHelperText>
                            </div>
                            <Switch
                                checked={profilePermissionsNew.change_email}
                                onChange={(event) => setProfilePermissionsNew({ ...profilePermissionsNew, change_email: event.target.checked })}
                                color={profilePermissionsNew.change_email ? 'success' : 'neutral'}
                                variant={profilePermissionsNew.change_email ? 'solid' : 'outlined'}
                                endDecorator={profilePermissionsNew.change_email ? _(msg({ id: "UsersListPage.on", message: "On" })) : _(msg({ id: "UsersListPage.off", message: "Off" }))}
                                slotProps={{
                                    endDecorator: {
                                        sx: {
                                            minWidth: 24,
                                        },
                                    },
                                }}
                            />
                        </FormControl>

                        <FormControl
                            orientation="horizontal"
                            sx={{ width: 300, justifyContent: 'space-between' }}
                        >
                            <div>
                                <FormLabel><Trans id="UsersListPage.changLogin">Change Login</Trans></FormLabel>
                                <FormHelperText sx={{ mt: 0 }}><Trans id="UsersListPage.userCanChangLogin">User can change login</Trans></FormHelperText>
                            </div>
                            <Switch
                                checked={profilePermissionsNew.change_login}
                                onChange={(event) => setProfilePermissionsNew({ ...profilePermissionsNew, change_login: event.target.checked })}
                                color={profilePermissionsNew.change_login ? 'success' : 'neutral'}
                                variant={profilePermissionsNew.change_login ? 'solid' : 'outlined'}
                                endDecorator={profilePermissionsNew.change_login ? _(msg({ id: "UsersListPage.on", message: "On" })) : _(msg({ id: "UsersListPage.off", message: "Off" }))}
                                slotProps={{
                                    endDecorator: {
                                        sx: {
                                            minWidth: 24,
                                        },
                                    },
                                }}
                            />
                        </FormControl>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            variant="plain"
                            color="neutral"
                            onClick={() => setModalPermissionsOpen(false)}
                        >
                            <Trans id="UsersListPage.cance">Cancel</Trans>
                        </Button>
                        <Button
                            variant="solid"
                            color="danger"
                            disabled={!isChangedRP}
                            onClick={handleSavePermissions}
                        >
                            <Trans id="UsersListPage.save">Save</Trans>
                        </Button>
                    </DialogActions>
                </ModalDialog>
            </Modal>

            {/* Modal Delete */}
            <Modal open={modalDeleteOpen} onClose={() => {setModalDeleteOpen(false)}}>
                <ModalDialog
                    variant="outlined"
                    sx={{
                        maxWidth: 500,
                        borderRadius: 'md',
                        p: 3,
                        boxShadow: 'lg',
                    }}
                >
                    <ModalClose variant="plain" sx={{ m: 1 }} onClick={() => setModalPermissionsOpen(false)} />
                    <DialogTitle>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                            }}
                        >
                            <Typography>
                                <Trans id="UsersListPage.deleteH1">DELETE</Trans>
                            </Typography>
                            <Typography>
                                {modalDeleteUser?.length == 1 ? (
                                    <>{i18n._({id: "UsersListPage.forModalEmail", message: "for {modalDeleteUser}", values: {modalDeleteUser:modalDeleteUser[0]?.user_data.name}})}</>
                                ):(
                                    <Trans id="UsersListPage.selecUsers">selected users</Trans>
                                )}

                            </Typography>
                        </Box>

                    </DialogTitle>
                    <Divider/>
                    <DialogContent sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                        {modalDeleteUser?.length == 1 ? (
                            i18n._({ id: "UsersListPage.doYouReallWantToDele_one", message: "Do you really want to delete the user {login} ({email}) ?", values: { login:modalDeleteUser[0]?.user_data.login, email:modalDeleteUser[0]?.user_data.email } })
                        ):(
                            i18n._({ id: "UsersListPage.doYouReallWantToDele_many", message: "Do you really want to delete selected ({modalDeleteUser}) users", values: { modalDeleteUser:modalDeleteUser?.length } })
                        )}

                    </DialogContent>
                    <DialogActions>
                        <Button
                            variant="outlined"
                            color="neutral"
                            onClick={() => {
                                setModalDeleteOpen(false)
                            }}
                        >
                            <Trans id="UsersListPage.cance">Cancel</Trans>
                        </Button>
                        <Button
                            variant="solid"
                            color="danger"
                            onClick={handleDeleteUsers}
                        >
                            <Trans id="UsersListPage.delet">Delete</Trans>
                        </Button>
                    </DialogActions>
                </ModalDialog>
            </Modal>


            {/*Modal Add Users*/}
            <Modal
                open={isModalAddUser}
                onClose={() => closeModalAddUser()}
            >
                <ModalDialog size="md">
                    <ModalClose/>
                    <DialogTitle><Trans id="UsersListPage.addNewUser">Add new User</Trans></DialogTitle>
                    <DialogContent>
                        <Stack spacing={2} sx={{width: '100%'}}>
                            {/* Login Field */}
                            <FormControl error={formErrors.login}>
                                <FormLabel><Trans id="UsersListPage.login">Login</Trans></FormLabel>
                                <Input
                                    name="login"
                                    placeholder={i18n._({ id: "UsersListPage.enterLogin", message: "Enter login" })}
                                    value={formData.login}
                                    onChange={handleInputChangeAddUser}
                                    autoComplete="off"
                                    disabled={isLoadingQuery}
                                />
                                {formErrors.login && <FormHelperText>{errorMessages.login}</FormHelperText>}
                            </FormControl>


                            {/* Email Field */}
                            <FormControl error={formErrors.email}>
                                <FormLabel><Trans id="UsersListPage.email">Email</Trans></FormLabel>
                                <Input
                                    name="email"
                                    placeholder={i18n._({ id: "UsersListPage.enterEmail", message: "Enter email" })}
                                    value={formData.email}
                                    onChange={handleInputChangeAddUser}
                                    autoComplete="off"
                                    disabled={isLoadingQuery}
                                />
                                {formErrors.email && <FormHelperText>{errorMessages.email}</FormHelperText>}
                            </FormControl>


                            {/* Name Field */}
                            <FormControl error={formErrors.name}>
                                <FormLabel><Trans id="UsersListPage.name">Name</Trans></FormLabel>
                                <Input
                                    name="name"
                                    placeholder={i18n._({ id: "UsersListPage.enterName", message: "Enter name" })}
                                    value={formData.name}
                                    onChange={handleInputChangeAddUser}
                                    autoComplete="off"
                                    disabled={isLoadingQuery}
                                />
                                {formErrors.name && <FormHelperText>{errorMessages.name}</FormHelperText>}
                            </FormControl>


                            {/* Role Selection */}
                            <FormControl error={formErrors.role}>
                                <FormLabel><Trans id="UsersListPage.role">Role</Trans></FormLabel>
                                <Select
                                    name="role"
                                    value={formData.role}
                                    onChange={(event, newValue) => handleSelectChangeAddUser('role', newValue)}
                                    disabled={isLoadingQuery}
                                >
                                    {roles.map((role) => (
                                        <Option
                                            key={role.role}
                                            value={role.role}
                                            color={role.color}
                                        >
                                            {role.icon && React.createElement(role.icon)}{role.name}
                                        </Option>
                                    ))}
                                </Select>
                            </FormControl>


                            {/* Password Field */}
                            <FormControl error={formErrors.password}>
                                <FormLabel><Trans id="UsersListPage.passw">Password</Trans></FormLabel>
                                <Input
                                    name="password"
                                    type="text"
                                    placeholder={i18n._({ id: "UsersListPage.enterPassw", message: "Enter password" })}
                                    value={formData.password}
                                    onChange={handleInputChangeAddUser}
                                    autoComplete="new-password"
                                    endDecorator={
                                        <IconButton onClick={handleGeneratePassword}>
                                            <TextIncreaseIcon/>
                                        </IconButton>
                                    }
                                    disabled={isLoadingQuery}
                                />
                                {formErrors.password && <FormHelperText>{errorMessages.password}</FormHelperText>}
                            </FormControl>
                            <FormControl
                                orientation="horizontal"
                                sx={{ width: '100%', justifyContent: 'space-between', mt: 2 }}
                            >
                                <div>
                                    <FormLabel><Trans id="UsersListPage.sendNotif">Send Notification</Trans></FormLabel>
                                    <FormHelperText sx={{ mt: 0 }}><Trans id="UsersListPage.notifUserViaEmailAft">Notify user via email after creation</Trans></FormHelperText>
                                </div>
                                <Switch
                                    checked={sendNotification}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                                        setSendNotification(event.target.checked)
                                    }
                                    color={sendNotification ? 'success' : 'neutral'}
                                    variant={sendNotification ? 'solid' : 'outlined'}
                                    endDecorator={sendNotification ? _(msg({ id: "UsersListPage.on", message: "On" })) : _(msg({ id: "UsersListPage.off", message: "Off" }))}
                                    slotProps={{
                                        endDecorator: {
                                            sx: {
                                                minWidth: 24,
                                            },
                                        },
                                    }}
                                    disabled={isLoadingQuery}
                                />
                            </FormControl>


                        </Stack>
                    </DialogContent>

                    <DialogActions>
                        <Button
                            variant="solid"
                            color="success"
                            size="sm"
                            onClick={sendFormAddUser}
                            loading={isLoadingQuery}
                        >
                            <Trans id="UsersListPage.creatUser">Create user</Trans>
                        </Button>
                        <Button
                            variant="plain"
                            color="neutral"
                            size="sm"
                            onClick={() => closeModalAddUser()}
                            disabled={isLoadingQuery}
                        >
                            <Trans id="UsersListPage.cance">Cancel</Trans>
                        </Button>
                    </DialogActions>
                </ModalDialog>
            </Modal>


            <CustomSnackbar
                open={isOpenSb}
                message={messageSb}
                handleClose={() => setIsOpenSb(false)} // Просто закрывает снэкбар
            />
        </>
    );
};

export default UsersListPage;
