// NotFoundPage.tsx
import React from 'react';
import Box from '@mui/joy/Box';
import Typography from '@mui/joy/Typography';

import {MagnifyingGlass} from "react-loader-spinner";


const NotConnectDB = () => {
    return (
        <Box sx={{ display: 'flex', minHeight: '100dvh' }}>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    maxWidth: 300,
                    margin: 'auto',
                    gap: 2,
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        height: '100%',
                        textAlign: 'center',
                    }}
                >
                    <MagnifyingGlass
                        visible={true}
                        height="80"
                        width="80"
                        ariaLabel="magnifying-glass-loading"
                        wrapperStyle={{}}
                        wrapperClass="magnifying-glass-wrapper"
                        glassColor="#c0efff"
                        color="#e15b64"
                    />
                    <Typography level="h3">CONNECTION REFUSED</Typography>

                    <Typography level="body-xs">
                        Sorry, the server connection is unavailable. Please try again later
                    </Typography>


                </Box>
            </Box>
        </Box>

    );
};

export default NotConnectDB;