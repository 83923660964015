// SingIn.tsx
import React,{useState,useEffect} from 'react';
import {useAuth} from "../../context/GlobalContext";
import {NavLink, useNavigate} from 'react-router-dom';


import Box from '@mui/joy/Box';
import CompanyLogoBlock from "../../components/UI/CompanyLogoBlock";
import CopyrightBlock from "../../components/UI/CopyrightBlock";

import GlobalStyles from '@mui/joy/GlobalStyles';


import Button from '@mui/joy/Button';


import FormControl from '@mui/joy/FormControl';
import FormLabel, { formLabelClasses } from '@mui/joy/FormLabel';

import FormHelperText from '@mui/joy/FormHelperText';
import Link from '@mui/joy/Link';
import Input from '@mui/joy/Input';

import Stack from '@mui/joy/Stack';

import ColorSchemeToggle from '../../components/ColorSchemeToggle';

import InfoOutlined from '@mui/icons-material/InfoOutlined';
import Typography from "@mui/joy/Typography";

import {Trans} from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { msg } from "@lingui/macro";
import { i18n } from "@lingui/core"
import EmailServiceButton from "../../components/UI/EmailServiceButton";




interface FormElements extends HTMLFormControlsCollection {
    email: HTMLInputElement;
}
interface SignInFormElement extends HTMLFormElement {
    readonly elements: FormElements;
}


const PasswordRecoveryPage = () => {
    const { _ } = useLingui();
    const {user,globalSettings } = useAuth();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] =useState(false);

    const [formErrors, setFormErrors] = useState({ email: '',general:'' });

    const [isEmailSent, setIsEmailSent] = useState(false); // Новое состояние для отслеживания отправки email
    const [email, setEmail] = useState('')

    useEffect(() => {
        // Если пользователь уже авторизован, перенаправляем на /dashboard
        if (user) {
            navigate('/dashboard');
        }

    }, [user, navigate]);



    const handleSubmit = async (event: React.FormEvent<SignInFormElement>) => {
        event.preventDefault();
        setIsLoading(true);
        setFormErrors({ email: '', general: '' }); // Очищаем ошибки при новой попытке отправки

        const email = event.currentTarget.elements.email.value;

        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/auth/forgot-password`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email })
            });

            if (!response.ok) {
                if (response.status === 404) {
                    setFormErrors({ ...formErrors, email: _(msg({ id: "passwordRecoveryPage.error.userNotFound", message: "User not found" })) });
                } else if (response.status === 427) {
                    const data = await response.json();
                    setFormErrors({ ...formErrors, general: i18n._({ id: "passwordRecoveryPage.error.errorSendEmail", message: "Error sending recovery code email"}) });
                } else if (response.status === 429) {
                    const data = await response.json();
                    setFormErrors({ ...formErrors, general: i18n._({ id: "passwordRecoveryPage.error.limitReachTryAgainAf", message: "Limit reached. Try again after {data_message}",values: { data_message:data.message } }) });
                } else {
                    throw new Error('Something went wrong');
                }
            } else {
                // Показываем сообщение об успешной отправке или перенаправляем пользователя
                setIsEmailSent(true);
                setEmail(email)
            }
        } catch (error) {

            setFormErrors({ ...formErrors, general: _(msg({ id: "passwordRecoveryPage.error.anErrorOccurPleasTry", message: "An error occurred. Please try again" })) });
        }

        setIsLoading(false);
    };


    if (user) {
        return null
    }

    return (
        <>
            <GlobalStyles
                styles={{
                    ':root': {
                        '--Collapsed-breakpoint': '769px', // form will stretch when viewport is below `769px`
                        '--Cover-width': '50vw', // must be `vw` only
                        '--Form-maxWidth': '800px',
                        '--Transition-duration': '0.4s', // set to `none` to disable transition
                    },
                }}
            />
            <Box
                sx={(theme) => ({
                    width:
                        'clamp(100vw - var(--Cover-width), (var(--Collapsed-breakpoint) - 100vw) * 999, 100vw)',
                    transition: 'width var(--Transition-duration)',
                    transitionDelay: 'calc(var(--Transition-duration) + 0.1s)',
                    position: 'relative',
                    zIndex: 1,
                    display: 'flex',
                    justifyContent: 'flex-end',
                    backdropFilter: 'blur(12px)',
                    backgroundColor: 'rgba(255 255 255 / 0.2)',
                    [theme.getColorSchemeSelector('dark')]: {
                        backgroundColor: 'rgba(19 19 24 / 0.4)',
                    },
                })}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        minHeight: '100dvh',
                        width:
                            'clamp(var(--Form-maxWidth), (var(--Collapsed-breakpoint) - 100vw) * 999, 100%)',
                        maxWidth: '100%',
                        px: 2,
                    }}
                >
                    <Box
                        component="header"
                        sx={{
                            py: 3,
                            display: 'flex',
                            alignItems: 'left',
                            justifyContent: 'space-between',
                        }}
                    >
                        <div style={{ gap: 2, display: 'flex', alignItems: 'center' }}>
                            <CompanyLogoBlock />
                        </div>
                        <ColorSchemeToggle />
                    </Box>
                    <Box
                        component="main"
                        sx={{
                            my: 'auto',
                            py: 2,
                            pb: 5,
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 2,
                            width: 400,
                            maxWidth: '100%',
                            mx: 'auto',
                            borderRadius: 'sm',
                            '& form': {
                                display: 'flex',
                                flexDirection: 'column',
                                gap: 2,
                            },
                            [`& .${formLabelClasses.asterisk}`]: {
                                visibility: 'hidden',
                            },
                        }}
                    >
                        <Stack
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                height: '100%', // или конкретная высота, если вам нужно
                            }}
                        >
                            {!isEmailSent && (
                                <Typography level="h2"><Trans id="passwordRecoveryPage.passwRecov">Password recovery</Trans></Typography>
                            )}
                        </Stack>
                        {isEmailSent ? (
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center', // Выравнивание по горизонтали
                                    justifyContent: 'center', // Выравнивание по вертикали
                                    mt:1,
                                }}
                            >
                                <Typography level="h2" color="success"><Trans id="passwordRecoveryPage.succe">SUCCESS</Trans></Typography>
                                <Typography
                                    level="body-lg"
                                    sx={{
                                        textAlign: 'center',
                                        whiteSpace: 'normal',
                                    }}
                                >
                                    <Trans id="passwordRecoveryPage.passwRecovLinkHasBe">A password recovery link has been sent to your email: {email}</Trans>
                                </Typography>
                                <EmailServiceButton email={email} />
                            </Box>
                        ) : (
                            <Stack gap={4} sx={{ mt: 2 }}>
                                <form onSubmit={handleSubmit} >
                                    <FormControl required disabled={isLoading} error={!!formErrors.email}>
                                        <FormLabel><Trans id="passwordRecoveryPage.email">Email</Trans></FormLabel>
                                        <Input type="email" name="email" />
                                        {formErrors.email && <FormHelperText>{formErrors.email}</FormHelperText>}
                                    </FormControl>

                                    <Stack gap={4} sx={{ mt: 2 }}>
                                        <FormControl required disabled={isLoading} error={!!formErrors.general}>
                                            <Button loading={isLoading} type="submit" fullWidth><Trans id="passwordRecoveryPage.resetPassw">Reset Password</Trans></Button>
                                            {formErrors.general && <FormHelperText><InfoOutlined /> {formErrors.general}</FormHelperText>}
                                        </FormControl>
                                    </Stack>
                                </form>
                            </Stack>
                        )}

                        {!isEmailSent && globalSettings && globalSettings.user_registration && (
                            <Stack>
                                <Box>
                                    <Typography level="title-sm">
                                        <Trans id="passwordRecoveryPage.dontHaveAnAccou">Don't have an account?</Trans>{' '}
                                        <Link component={NavLink} to="/singup" disabled={isLoading}>
                                            <Trans id="passwordRecoveryPage.signUp">Sign Up</Trans>
                                        </Link>
                                    </Typography>
                                </Box>
                            </Stack>
                        )}
                    </Box>
                    <Box component="footer" sx={{ py: 3 }}>
                        <CopyrightBlock />
                    </Box>
                </Box>
            </Box>
            <Box
                sx={(theme) => ({
                    height: '100%',
                    position: 'fixed',
                    right: 0,
                    top: 0,
                    bottom: 0,
                    left: 'clamp(0px, (100vw - var(--Collapsed-breakpoint)) * 999, 100vw - var(--Cover-width))',
                    transition:
                        'background-image var(--Transition-duration), left var(--Transition-duration) !important',
                    transitionDelay: 'calc(var(--Transition-duration) + 0.1s)',
                    backgroundColor: 'background.level1',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    backgroundImage:
                        'url(imgs/lightwpbc.png)',
                    [theme.getColorSchemeSelector('dark')]: {
                        backgroundImage:
                            'url(imgs/darkbcwp.png)',
                    },
                })}
            />
        </>
    );
};

export default PasswordRecoveryPage;
