import React from 'react';
import { i18n as i18Type } from "@lingui/core";
import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied';

import AccessibilityIcon from '@mui/icons-material/Accessibility';
import SettingsAccessibilityIcon from '@mui/icons-material/SettingsAccessibility';
import PregnantWomanIcon from '@mui/icons-material/PregnantWoman';

// Определяем тип для деталей роли
interface RoleDetails {
    name: string;
    icon: React.ComponentType | null ;
    color: "primary" | "neutral" | "danger" | "success" | "warning" | undefined;
}

// Определяем тип для объекта roleDisplayNames
interface RoleDisplayNames {
    [key: string]: RoleDetails;
}


function listRoles(i18n: typeof i18Type): RoleDisplayNames {
    return {
        admin: {
            name: i18n._({ id: "role.admin", message: "Administrator" }),
            icon: PregnantWomanIcon,
            color: "danger",
        },
        user: {
            name: i18n._({id: "role.user", message: "User"}),
            icon: AccessibilityIcon,
            color: "neutral",
        },
        editor: {
            name: i18n._({ id: "role.editor", message: "Editor" }),
            icon: SettingsAccessibilityIcon,
            color: "success",
        },
        // Добавьте другие роли здесь по необходимости
    };
}

// Функция для получения отображаемого имени роли
export const getRoleDisplayName = (role: string, i18n: typeof i18Type): string => {
    const roleDisplayNames = listRoles(i18n);
    return roleDisplayNames[role]?.name || role;
};

export const getRoleDisplayIcon = (role: string, i18n: typeof i18Type): React.ReactElement | null => {
    const roleDisplayNames = listRoles(i18n);
    const IconComponent = roleDisplayNames[role]?.icon || SentimentDissatisfiedIcon;
    return IconComponent ? React.createElement(IconComponent) : null; // Создание JSX элемента из компонента
};

export const getRoleDisplayColor = (role: string, i18n: typeof i18Type): "primary" | "neutral" | "danger" | "success" | "warning" | undefined => {
    const roleDisplayNames = listRoles(i18n);
    const colorRole = roleDisplayNames[role]?.color;
    return colorRole; // Создание JSX элемента из компонента
};

export const getAllRoles = (i18n: typeof i18Type): Array<{ role: string, name: string,icon:React.ComponentType | null, color:"primary" | "neutral" | "danger" | "success" | "warning" | undefined }> => {
    const roleDisplayNames = listRoles(i18n);
    return Object.keys(roleDisplayNames).map(role => ({
        role: role,
        name: roleDisplayNames[role].name,
        icon: roleDisplayNames[role].icon,
        color: roleDisplayNames[role].color,
    }));
};