// MyProfilePage.tsx
import React, {useState} from 'react';
import { Trans } from "@lingui/macro";
import { msg } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import md5 from 'md5';
import AspectRatio from '@mui/joy/AspectRatio';
import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import Divider from '@mui/joy/Divider';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import FormHelperText from '@mui/joy/FormHelperText';
import Input from '@mui/joy/Input';
import IconButton from '@mui/joy/IconButton';
import Textarea from '@mui/joy/Textarea';
import Stack from '@mui/joy/Stack';
import Select from '@mui/joy/Select';
import Option from '@mui/joy/Option';
import Typography from '@mui/joy/Typography';
import Tabs from '@mui/joy/Tabs';
import TabList from '@mui/joy/TabList';
import Tab, { tabClasses } from '@mui/joy/Tab';
import Breadcrumbs from '@mui/joy/Breadcrumbs';
import Link from '@mui/joy/Link';
import Card from '@mui/joy/Card';
import CardActions from '@mui/joy/CardActions';
import CardOverflow from '@mui/joy/CardOverflow';

import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import EmailRoundedIcon from '@mui/icons-material/EmailRounded';
import AccessTimeFilledRoundedIcon from '@mui/icons-material/AccessTimeFilledRounded';
import VideocamRoundedIcon from '@mui/icons-material/VideocamRounded';
import InsertDriveFileRoundedIcon from '@mui/icons-material/InsertDriveFileRounded';
import InfoOutlined from '@mui/icons-material/InfoOutlined';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import TabPanel from '@mui/joy/TabPanel';
import {i18n} from "@lingui/core";

import CustomSnackbar from "../../../components/UI/CustomSnackbar";

import {useAuth} from "../../../context/GlobalContext";
import {getRoleDisplayName} from "../../../components/UI/getRoleDisplayName";

function gravatarUrl(email : String) {
    const trimmedEmail = email.trim().toLowerCase();
    const hash = md5(trimmedEmail).toString();
    return `https://www.gravatar.com/avatar/${hash}?d=identicon`;
}


const MyProfilePage = () => {
    const { _ } = useLingui();
    const { user,changeUserInfo } = useAuth();
    const [isOpenSb, setIsOpenSb] = useState(false);
    const [messageSb, setMessageSb] = useState('');

    const [newName, setNewName] = useState(user?.name || '');
    const [oldName, setOldName] = useState(user?.name || '');
    const [isLoadingName, setIsLoadingName] = useState (false);
    const [isNameChanged, setIsNameChanged] = useState(false);

    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [oldPassword, setOldPassword] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [confirmPasswordError, setConfirmPasswordError] = useState('');
    const [oldPasswordError, setOldPasswordError] = useState('');
    const [isLoadingPassword, setIsLoadingPassword] = useState (false);

    // Обработчик изменения текстового поля
    const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setNewName(event.target.value);
        if (event.target.value != oldName) {
            setIsNameChanged(true)
        }
        else {
            setIsNameChanged(false)
        }
    };
    const cnacelName = () => {
      setNewName(oldName);
      setIsNameChanged(false)
    }
    // Обработчик отправки формы
    const handleNameSubmit = async () => {
        try {
            setIsLoadingName(true);
            const token = localStorage.getItem('authToken');
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/myprofile/changename`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({ newName })
            });

            const data = await response.json();
            if (response.ok) {
                // Обновите состояние пользователя в контексте или выполните действия по необходимости
                changeUserInfo(data.user);
                setOldName(newName)
                setIsNameChanged(false)



                setMessageSb(_(msg({ id: "MyProfilePage.Alert.userNameChanged", message: `User Name changed` })))
                setIsOpenSb(true)
            } else {
                console.error(data.error);
            }
            setIsLoadingName(false);
        } catch (error) {
            console.error('Error changing name:', error);
            setIsLoadingName(false);
        }
    };



    const handleNewPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setNewPassword(e.target.value);
        if (passwordError) setPasswordError('');
    };

    const handleConfirmPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setConfirmPassword(e.target.value);
        if (confirmPasswordError) setConfirmPasswordError('');
    };

    const handleOldPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setOldPassword(e.target.value);
        if (oldPasswordError) setOldPasswordError('');
    };


    const handlePasswordSubmit = async () => {

        const passwordRegex = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-zA-Z]).{5,}$/;
        const isPasswordValid = passwordRegex.test(newPassword);
        if (!isPasswordValid) {
            setPasswordError(_(msg({ id: "global.passwordRules", message: `Password must be at least 5 characters long and include at least one digit and one special character` })))
            return;
        }

        if (newPassword !== confirmPassword) {
            setConfirmPasswordError(_(msg({ id: "global.passwordsDoNotMatch", message: `Passwords do not match` })))
            return;
        }

        if (!oldPassword) {
            setOldPasswordError(_(msg({ id: "MyProfilePage.Alert.oldPasswordIsRequired", message: `Old password is required` })))
            return;
        }

        try {
            setIsLoadingPassword(true);
            const token = localStorage.getItem('authToken');
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/myprofile/changepassword`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({ oldPassword, newPassword })
            });

            const data = await response.json();
            if (response.ok) {
                // Обновите состояние пользователя в контексте или выполните действия по необходимости
                console.log(data.message);
                setNewPassword("");
                setConfirmPassword("");
                setOldPassword("");


                setMessageSb(_(msg({ id: "MyProfilePage.Alert.userPasswordChanged", message: `User password changed` })))
                setIsOpenSb(true)
            } else if (response.status === 400) {
                setOldPasswordError(_(msg({ id: "MyProfilePage.Alert.oldPasswordIsIncorrect", message: `Old password is incorrect` })))
            } else {
                console.error(data.error);
            }
            setIsLoadingPassword(false);
        } catch (error) {
            console.error('Error changing password:', error);
            setIsLoadingPassword(false);
        }
    };



    return (
        <Box sx={{ flex: 1, width: '100%' }}>
            <Box
                sx={{
                    position: 'sticky',
                    top: { sm: -100, md: -110 },
                    bgcolor: 'background.body',
                    zIndex: 9995,
                }}
            >
                <Box sx={{ px: { xs: 2, md: 6 } }}>
                    <Typography level="h2" component="h1" sx={{ mt: 1, mb: 2 }}>
                        <Trans id="MyProfilePage.title">My profile</Trans>
                    </Typography>
                </Box>
                <Tabs
                    defaultValue={0}
                    sx={{
                        bgcolor: 'transparent',
                    }}
                >
                    <TabList
                        tabFlex={1}
                        size="sm"
                        sx={{
                            pl: { xs: 0, md: 4 },
                            justifyContent: 'left',
                            [`&& .${tabClasses.root}`]: {
                                fontWeight: '600',
                                flex: 'initial',
                                color: 'text.tertiary',
                                [`&.${tabClasses.selected}`]: {
                                    bgcolor: 'transparent',
                                    color: 'text.primary',
                                    '&::after': {
                                        height: '2px',
                                        bgcolor: 'primary.500',
                                    },
                                },
                            },
                        }}
                    >
                        <Tab sx={{ borderRadius: '6px 6px 0 0' }} indicatorInset value={0}>
                            <Trans id="MyProfilePage.tab.profile.title">Profile</Trans>
                        </Tab>
                        <Tab sx={{ borderRadius: '6px 6px 0 0' }} indicatorInset value={1}>
                            <Trans id="MyProfilePage.tab.password.title">Password</Trans>
                        </Tab>
                    </TabList>

                    <TabPanel value={0}>
                        <Stack
                            spacing={4}
                            sx={{
                                display: 'flex',
                                maxWidth: '800px',
                                mx: 'auto',
                                px: { xs: 2, md: 6 },
                                py: { xs: 2, md: 3 },
                            }}
                        >

                            <Card>
                                <Box sx={{ mb: 1 }}>
                                    <Typography level="title-md"><Trans id="MyProfilePage.tab.profile.card.title">Personal info</Trans></Typography>
                                    <Typography level="body-sm">
                                        <Trans id="MyProfilePage.tab.profile.card.desc">Customize how your profile information will apper to the networks.</Trans>
                                    </Typography>
                                </Box>
                                <Divider />
                                <Stack
                                    direction="row"
                                    spacing={3}
                                    sx={{ display: { xs: 'none', md: 'flex' }, my: 1 }}
                                >
                                    <Stack direction="column" spacing={1}>
                                        <AspectRatio
                                            ratio="1"
                                            maxHeight={200}
                                            sx={{ flex: 1, minWidth: 120, borderRadius: '100%' }}
                                        >
                                            <img
                                                src={gravatarUrl(user?.email || 'noemail')}
                                                srcSet={gravatarUrl(user?.email || 'noemail')}
                                                loading="lazy"
                                                alt=""
                                            />
                                        </AspectRatio>
                                        <IconButton
                                            aria-label="upload new picture"
                                            size="sm"
                                            variant="outlined"
                                            color="neutral"
                                            sx={{
                                                bgcolor: 'background.body',
                                                position: 'absolute',
                                                zIndex: 2,
                                                borderRadius: '50%',
                                                left: 100,
                                                top: 170,
                                                boxShadow: 'sm',
                                            }}
                                        >
                                            <EditRoundedIcon />
                                        </IconButton>
                                    </Stack>
                                    <Stack spacing={2} sx={{ flexGrow: 1 }}>
                                        <Stack spacing={1}>
                                            <FormLabel><Trans id="MyProfilePage.tab.profile.card.name">Name</Trans></FormLabel>
                                            <FormControl
                                                sx={{ display: { sm: 'flex-column', md: 'flex-row' }, gap: 2 }}
                                            >
                                                <Input
                                                    size="sm"
                                                    placeholder="Name"
                                                    value={newName}
                                                    onChange={handleNameChange}
                                                    disabled={isLoadingName}
                                                />
                                            </FormControl>
                                        </Stack>
                                        <Stack spacing={1}>
                                            <FormLabel><Trans id="MyProfilePage.tab.profile.card.email">Email</Trans></FormLabel>
                                            <Input
                                                size="sm"
                                                type="email"
                                                startDecorator={<EmailRoundedIcon />}
                                                placeholder="email"
                                                value={user?.email || 'non'}
                                                sx={{ flexGrow: 1 }}
                                                disabled
                                            />
                                        </Stack>
                                        <Stack direction="row" spacing={2}>
                                            <FormControl>
                                                <FormLabel><Trans id="MyProfilePage.tab.profile.card.login">Login</Trans></FormLabel>
                                                <Input
                                                    size="sm"
                                                    value={user?.login || 'non'}
                                                    disabled
                                                />
                                            </FormControl>
                                            <FormControl sx={{ flexGrow: 1 }}>
                                                <FormLabel><Trans id="MyProfilePage.tab.profile.card.role">Role</Trans></FormLabel>
                                                <Input
                                                    size="sm"
                                                    value={getRoleDisplayName(user?.role || 'user',i18n)}
                                                    disabled
                                                />
                                            </FormControl>
                                        </Stack>
                                    </Stack>
                                </Stack>
                                <Stack
                                    direction="column"
                                    spacing={2}
                                    sx={{ display: { xs: 'flex', md: 'none' }, my: 1 }}
                                >
                                    <Stack direction="row" spacing={2}>
                                        <Stack direction="column" spacing={1}>
                                            <AspectRatio
                                                ratio="1"
                                                maxHeight={108}
                                                sx={{ flex: 1, minWidth: 108, borderRadius: '100%' }}
                                            >
                                                <img
                                                    src={gravatarUrl(user?.email || 'noemail')}
                                                    srcSet={gravatarUrl(user?.email || 'noemail')}
                                                    loading="lazy"
                                                    alt=""
                                                />
                                            </AspectRatio>
                                            <IconButton
                                                aria-label="upload new picture"
                                                size="sm"
                                                variant="outlined"
                                                color="neutral"
                                                sx={{
                                                    bgcolor: 'background.body',
                                                    position: 'absolute',
                                                    zIndex: 2,
                                                    borderRadius: '50%',
                                                    left: 85,
                                                    top: 180,
                                                    boxShadow: 'sm',
                                                }}
                                            >
                                                <EditRoundedIcon />
                                            </IconButton>
                                        </Stack>
                                        <Stack spacing={1} sx={{ flexGrow: 1 }}>
                                            <FormLabel><Trans id="MyProfilePage.tab.profile.card.name">Name</Trans></FormLabel>
                                            <FormControl
                                                sx={{
                                                    display: {
                                                        sm: 'flex-column',
                                                        md: 'flex-row',
                                                    },
                                                    gap: 2,
                                                }}
                                            >
                                                <Input
                                                    size="sm"
                                                    placeholder="Name"
                                                    value={newName}
                                                    onChange={handleNameChange}
                                                    disabled={isLoadingName}
                                                />
                                            </FormControl>
                                        </Stack>
                                    </Stack>
                                    <FormControl>
                                        <FormLabel><Trans id="MyProfilePage.tab.profile.card.login">Login</Trans></FormLabel>
                                        <Input
                                            size="sm"
                                            value={user?.login || ''}
                                            disabled
                                        />
                                    </FormControl>
                                    <FormControl>
                                        <FormLabel><Trans id="MyProfilePage.tab.profile.card.role">Role</Trans></FormLabel>
                                        <Input
                                            size="sm"
                                            value={getRoleDisplayName(user?.role || 'user',i18n )}
                                            disabled
                                        />
                                    </FormControl>
                                    <FormControl sx={{ flexGrow: 1 }}>
                                        <FormLabel><Trans id="MyProfilePage.tab.profile.card.email">Email</Trans></FormLabel>
                                        <Input
                                            size="sm"
                                            type="email"
                                            startDecorator={<EmailRoundedIcon />}
                                            placeholder="email"
                                            value={user?.email || ''}
                                            sx={{ flexGrow: 1 }}
                                            disabled
                                        />
                                    </FormControl>
                                </Stack>
                                <CardOverflow sx={{ borderTop: '1px solid', borderColor: 'divider' }}>
                                    <CardActions sx={{ alignSelf: 'flex-end', pt: 2 }}>
                                        {isNameChanged && !isLoadingName && (
                                            <Button
                                                size="sm"
                                                variant="outlined"
                                                color="neutral"
                                                onClick={cnacelName}
                                            >
                                                <Trans id="global.cancel">Cancel</Trans>
                                            </Button>
                                        )}
                                        <Button
                                            size="sm"
                                            variant="solid"
                                            onClick={handleNameSubmit}
                                            loading={isLoadingName}
                                            disabled={!isNameChanged}
                                        >
                                            <Trans id="global.save">Save</Trans>
                                        </Button>
                                    </CardActions>
                                </CardOverflow>
                            </Card>
                        </Stack>
                    </TabPanel>
                    <TabPanel value={1}>
                        <Stack
                            spacing={4}
                            sx={{
                                display: 'flex',
                                maxWidth: '800px',
                                mx: 'auto',
                                px: { xs: 2, md: 6 },
                                py: { xs: 2, md: 3 },
                            }}
                        >


                            <Card>
                                <Box sx={{ mb: 1 }}>
                                    <Typography level="title-md"><Trans id="MyProfilePage.tab.password.title">Password</Trans></Typography>
                                    <Typography level="body-sm">
                                        <Trans id="MyProfilePage.tab.password.desc">Change password</Trans>
                                    </Typography>
                                </Box>
                                <Divider />
                                <Stack spacing={1}>
                                    <FormControl error={!!passwordError}>
                                        <FormLabel><Trans id="MyProfilePage.tab.password.card.newpassword">New Password</Trans></FormLabel>
                                        <Input
                                            type="password"
                                            value={newPassword}
                                            onChange={handleNewPasswordChange}
                                            disabled={isLoadingPassword}
                                            autoComplete="new-password"
                                        />
                                        {passwordError && <FormHelperText><InfoOutlined /> {passwordError}</FormHelperText>}
                                    </FormControl>
                                </Stack>
                                <Stack spacing={1}>
                                    <FormControl error={!!confirmPasswordError}>
                                        <FormLabel><Trans id="MyProfilePage.tab.password.card.confirmPassword">Confirm Password</Trans></FormLabel>
                                        <Input type="password" value={confirmPassword} onChange={handleConfirmPasswordChange} disabled={isLoadingPassword}/>
                                        {confirmPasswordError && <FormHelperText><InfoOutlined /> {confirmPasswordError}</FormHelperText>}
                                    </FormControl>
                                </Stack>
                                <Stack spacing={1}>
                                    <FormControl error={!!oldPasswordError}>
                                        <FormLabel><Trans id="MyProfilePage.tab.password.card.oldpassword">Old Password</Trans></FormLabel>
                                        <Input type="password" value={oldPassword} onChange={handleOldPasswordChange} disabled={isLoadingPassword}/>
                                        {oldPasswordError && <FormHelperText><InfoOutlined /> {oldPasswordError}</FormHelperText>}
                                    </FormControl>
                                </Stack>
                                <CardOverflow sx={{ borderTop: '1px solid', borderColor: 'divider' }}>
                                    <CardActions sx={{ alignSelf: 'flex-end', pt: 2 }}>
                                        <Button
                                            size="sm"
                                            variant="solid"
                                            onClick={handlePasswordSubmit}
                                            loading={isLoadingPassword}
                                        >
                                            <Trans id="MyProfilePage.tab.password.card.changePassword">Change password</Trans>
                                        </Button>
                                    </CardActions>
                                </CardOverflow>
                            </Card>

                        </Stack>
                    </TabPanel>
                </Tabs>
            </Box>

            <CustomSnackbar
                open={isOpenSb}
                message={messageSb}
                handleClose={() => setIsOpenSb(false)} // Просто закрывает снэкбар
            />

        </Box>
    );
};

export default MyProfilePage;
